import {
	EApiCryptoTransactionDestination,
	EApiCryptoTransactionStatus,
	EApiFiatTransactionStatus,
	EApiPendingDepositsCryptoTransactionStatus,
	EApiPendingDepositsFiatTransactionStatus,
	EApiPendingWithrawalsTransactionStatus,
	EApiPooledDepositTransactionStatus,
	EApiProfitWalletTransactionStatus,
	EApiTransactionType,
} from 'services/api/transactions/types';

export const INIT_FILTER_OPTIONS_BANK = [
	{
		id: 0,
		bank_name: 'All',
		value: undefined,
	},
];
///
export const INIT_FILTER_OPTIONS = [
	{
		id: 0,
		name: 'All',
		value: undefined,
	},
];
export const INIT_FILTER_OPTIONS_BALANCES_CURRENCY = [
	{
		id: 0,
		name: 'Сurrency',
		value: undefined,
	},
];
export const INIT_FILTER_OPTIONS_BALANCES_TOTAL = [
	{
		id: 0,
		name: 'Total EUR',
		value: undefined,
	},
];
export const INIT_DESTINATION_OPTIONS = [
	{
		id: 0,
		name: 'Destination',
		value: undefined,
	},
];

export const STATUS_CRYPTO_FILTER_OPTIONS = [
	{
		id: 1,
		name: 'Completed',
		value: EApiCryptoTransactionStatus.COMPLETED,
	},
	{
		id: 2,
		name: 'Rejected',
		value: EApiCryptoTransactionStatus.REJECTED,
	},
	{
		id: 3,
		name: 'Failed',
		value: EApiCryptoTransactionStatus.FAILED,
	},
	// {
	// 	id: 3,
	// 	name: 'Canceled',
	// 	value: EApiCryptoTransactionStatus.CANCELED,
	// },
	// {
	// 	id: 4,
	// 	name: 'Pending',
	// 	value: EApiCryptoTransactionStatus.PENDING,
	// },
	// {
	// 	id: 5,
	// 	name: 'Broadcasting',
	// 	value: EApiCryptoTransactionStatus.BROADCASTING,
	// },
];
export const STATUS_USER_CRYPTO_FILTER_OPTIONS = [
	{
		id: 1,
		name: 'Pending',
		value: EApiCryptoTransactionStatus.ADMIN_PENDING,
	},
	{
		id: 2,
		name: 'Broadcasting',
		value: EApiCryptoTransactionStatus.BROADCASTING,
	},
	{
		id: 3,
		name: 'Completed',
		value: EApiCryptoTransactionStatus.COMPLETED,
	},
	{
		id: 4,
		name: 'Rejected',
		value: EApiCryptoTransactionStatus.REJECTED,
	},
	{
		id: 5,
		name: 'Failed',
		value: EApiCryptoTransactionStatus.FAILED,
	},
	{
		id: 10,
		name: 'Processing',
		value: EApiCryptoTransactionStatus.PROCESSING,
	},
];
export const STATUS_USER_CRYPTO_WITHDRAWAL_FILTER_OPTIONS = [
	{
		id: 1,
		name: 'Pending',
		value: EApiCryptoTransactionStatus.ADMIN_PENDING,
	},
	{
		id: 2,
		name: 'Broadcasting',
		value: EApiCryptoTransactionStatus.BROADCASTING,
	},
	{
		id: 3,
		name: 'Pending Signing',
		value: EApiCryptoTransactionStatus.PENDING_SIGNING,
	},
	{
		id: 4,
		name: 'Completed',
		value: EApiCryptoTransactionStatus.COMPLETED,
	},
	{
		id: 11,
		name: 'Waiting approval',
		value: EApiFiatTransactionStatus.WAITING_APPROVAL,
	},
	{
		id: 5,
		name: 'Rejected',
		value: EApiCryptoTransactionStatus.REJECTED,
	},
	{
		id: 6,
		name: 'Failed',
		value: EApiCryptoTransactionStatus.FAILED,
	},
	{
		id: 10,
		name: 'Processing',
		value: EApiCryptoTransactionStatus.PROCESSING,
	},
];

export const STATUS_USER_FIAT_FILTER_OPTIONS = [
	{
		id: 1,
		name: 'Pending',
		value: EApiFiatTransactionStatus.PENDING,
	},
	{
		id: 2,
		name: 'Completed',
		value: EApiFiatTransactionStatus.COMPLETED,
	},
	{
		id: 3,
		name: 'Waiting approval',
		value: EApiFiatTransactionStatus.WAITING_APPROVAL,
	},
	{
		id: 3,
		name: 'Rejected',
		value: EApiFiatTransactionStatus.REJECTED,
	},
	{
		id: 4,
		name: 'Cancelled',
		value: EApiFiatTransactionStatus.CANCELED,
	},
	{
		id: 10,
		name: 'Processing',
		value: EApiFiatTransactionStatus.PROCESSING,
	},
];

export const DESTINATION_CRYPTO_FILTER_OPTIONS = [
	{
		id: 1,
		name: 'Destination',
		value: EApiCryptoTransactionDestination.DESTINATION,
	},
	{
		id: 2,
		name: 'Tx Hash',
		value: EApiCryptoTransactionDestination.TX_HASH,
	},
];

export const STATUS_FIAT_FILTER_OPTIONS = [
	{
		id: 1,
		name: 'Completed',
		value: EApiFiatTransactionStatus.COMPLETED,
	},
	{
		id: 2,
		name: 'Failed',
		value: EApiFiatTransactionStatus.FAILED,
	},
	{
		id: 3,
		name: 'Canceled',
		value: EApiFiatTransactionStatus.CANCELED,
	},
	{
		id: 4,
		name: 'Rejected',
		value: EApiFiatTransactionStatus.REJECTED,
	},
	{
		id: 5,
		name: 'Pending',
		value: EApiFiatTransactionStatus.PENDING,
	},
];

export const STATUS_FIAT_FILTER_OPTIONS_V2 = [
	{
		id: 1,
		name: 'Completed',
		value: EApiFiatTransactionStatus.COMPLETED,
	},
	{
		id: 2,
		name: 'Rejected',
		value: EApiFiatTransactionStatus.REJECTED,
	},
	// {
	// 	id: 3,
	// 	name: 'Failed',
	// 	value: EApiFiatTransactionStatus.FAILED,
	// },
	// {
	// 	id: 4,
	// 	name: 'Pending',
	// 	value: EApiFiatTransactionStatus.PENDING,
	// },
	{
		id: 3,
		name: 'Cancelled',
		value: EApiFiatTransactionStatus.CANCELED,
	},
];
export const STATUS_POOLED_DEPOSIT_FILTER_OPTIONS = [
	{
		id: 1,
		name: 'Pending',
		value: EApiPooledDepositTransactionStatus.ADMIN_PENDING,
	},
	{
		id: 2,
		name: 'Broadcasting',
		value: EApiPooledDepositTransactionStatus.BROADCASTING,
	},
	{
		id: 3,
		name: 'Completed',
		value: EApiPooledDepositTransactionStatus.COMPLETED,
	},
	{
		id: 4,
		name: 'Rejected',
		value: EApiPooledDepositTransactionStatus.REJECTED,
	},
	{
		id: 5,
		name: 'Failed',
		value: EApiPooledDepositTransactionStatus.FAILED,
	},
];
export const STATUS_PROFIT_WALLET_FILTER_OPTIONS = [
	{
		id: 1,
		name: 'Pending',
		value: EApiProfitWalletTransactionStatus.ADMIN_PENDING,
	},
	{
		id: 2,
		name: 'Broadcasting',
		value: EApiProfitWalletTransactionStatus.BROADCASTING,
	},
	{
		id: 3,
		name: 'Completed',
		value: EApiProfitWalletTransactionStatus.COMPLETED,
	},
	{
		id: 4,
		name: 'Rejected',
		value: EApiProfitWalletTransactionStatus.REJECTED,
	},
	{
		id: 5,
		name: 'Failed',
		value: EApiProfitWalletTransactionStatus.FAILED,
	},
	{
		id: 6,
		name: 'Cancelled',
		value: EApiProfitWalletTransactionStatus.CANCELED,
	},
];

export const STATUS_PENDING_DEPOSIT_CRYPTO_FILTER_OPTIONS = [
	{
		id: 1,
		name: 'Pending',
		value: EApiPendingDepositsCryptoTransactionStatus.ADMIN_PENDING,
	},
	{
		id: 2,
		name: 'Broadcasting',
		value: EApiPendingDepositsCryptoTransactionStatus.BROADCASTING,
	},
	{
		id: 10,
		name: 'Processing',
		value: EApiPendingDepositsCryptoTransactionStatus.PROCESSING,
	},
];

export const STATUS_PENDING_DEPOSIT_FIAT_FILTER_OPTIONS = [
	{
		id: 1,
		name: 'Pending',
		value: EApiPendingDepositsFiatTransactionStatus.PENDING,
	},
	{
		id: 10,
		name: 'Processing',
		value: EApiPendingDepositsFiatTransactionStatus.PROCESSING,
	},
];

export const STATUS_PENDING_WITHDRAWAL_FILTER_OPTIONS = [
	{
		id: 1,
		name: 'Pending',
		value: EApiPendingWithrawalsTransactionStatus.ADMIN_PENDING,
	},
	{
		id: 2,
		name: 'Broadcasting',
		value: EApiPendingWithrawalsTransactionStatus.BROADCASTING,
	},
	{
		id: 3,
		name: 'Pending Signing',
		value: EApiPendingWithrawalsTransactionStatus.PENDING_SIGNING,
	},
	{
		id: 10,
		name: 'Processing',
		value: EApiPendingWithrawalsTransactionStatus.PROCESSING,
	},
];
export const STATUS_PENDING_WITHDRAWAL_FIAT_FILTER_OPTIONS = [
	{
		id: 1,
		name: 'Pending',
		value: EApiPendingWithrawalsTransactionStatus.PENDING,
	},
	{
		id: 10,
		name: 'Processing',
		value: EApiPendingWithrawalsTransactionStatus.PROCESSING,
	},
];

export const TYPE_FILTER_OPTIONS = [
	{
		id: 1,
		name: 'Deposit',
		value: EApiTransactionType.DEPOSIT,
	},
	{
		id: 2,
		name: 'Withdrawal',
		value: EApiTransactionType.WITHDRAWAL,
	},
];

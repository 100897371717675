import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import Popup from 'reactjs-popup';
import { putFiatTransactionsRequest } from '../../../redux/reducers/transactions/reducer';
import {
	EApiTransactionType,
	IApiPutFiatTransactionsPayload,
} from '../../../services/api/transactions/types';

export interface IPendingDepositFiatRejectConfirm {
	open: boolean;
	closeModal: () => void;
	data?: {
		data: IApiPutFiatTransactionsPayload;
		type: EApiTransactionType;
		id: number;
		statusValue: string;
		prevStatusValue: string;
	};
}

const PendingDepositFiatRejectConfirm: FC<IPendingDepositFiatRejectConfirm> = ({
	open,
	closeModal,
	data,
}) => {
	const dispatch = useDispatch();

	const onConfirmHandler = () => {
		if (data) {
			const payload = {
				data: {
					date: data.data.date,
					fee: data.data.fee,
					indicated_amount: data.data.indicated_amount,
					status: data.data.status,
					note: data.data.note || '',
					admin_requisite_id: data.data.admin_requisite_id,
				},
				type: data.type,
				id: data.id,
				onFinally: () => {
					closeModal();
				},
			};
			dispatch(putFiatTransactionsRequest(payload));
		}
	};

	return (
		<Popup open={open} onClose={closeModal} lockScroll closeOnDocumentClick={false}>
			<div className="popup popup--width-480">
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
				<button type="button" className="popup__close-btn" onClick={closeModal} />
				<div className="popup-header popup-header--mb-20">
					{!!data && (
						<p className="popup-header__title">
							Change TRXID {data.id} status to “{data.statusValue}“
						</p>
					)}
				</div>
				<div className="popup-body">
					<div className="popup-text popup-text--type2 popup-text--center">
						{!!data && (
							<p>
								You’re about to change TRXID {data.id} status from “{data.prevStatusValue}“ to “
								{data.statusValue}“.
								<br />
								Please confirm your action.
							</p>
						)}
					</div>
				</div>
				<div className="popup-footer">
					<div className="popup-submit popup-submit--column">
						<button type="button" className="btn btn-primary btn--full" onClick={onConfirmHandler}>
							Confirm
						</button>
						<button type="button" className="btn btn-danger btn--full" onClick={closeModal}>
							Cancel
						</button>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default PendingDepositFiatRejectConfirm;

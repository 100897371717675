import { IAccountDetailsParams } from 'components/AccountDetails/types';
import React, { useLayoutEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getFiatHistoryRequest } from 'redux/reducers/transactions/reducer';
import { getFiatHistory, getFiatHistoryPageCount } from 'redux/reducers/transactions/selectors';
import { IGetTransactionHistoryRequestPayload } from 'redux/reducers/transactions/types';
import PerPageDropdown from 'services/utils/PaginationPerPageDropdown';
import IconSvg from 'ui/Svg/IconSvg';
import { STATUS_USER_FIAT_FILTER_OPTIONS } from 'redux/reducers/transactionsFilters/constants';
import ActiveReportsFilters from 'ui/ActiveFilters/ActiveReportsFilters';
import { getFiatFilters } from 'redux/reducers/transactionsFilters/selectors';
import { getFiatCurrencyData } from 'redux/reducers/currency/selectors';
import { convertFilterObjectToParamsObject } from 'services/utils/convertFilterObjectToParamsObject';
import { addFilter, deleteFilter, resetFilters } from 'redux/reducers/transactionsFilters/reducer';
import { EFiltersType, ETransactionsFilters } from 'redux/reducers/transactionsFilters/types';
import { ISearchSelectArr } from 'ui/SearchWithSelect/SearchWithSelect';
import FiatHistoryRow from './FiatHistoryRow';
import FiatHistoryHeader from './FiatHistoryHeader';
import TableBodyNoData from '../../../../ui/TableBodyNoData/TableBodyNoData';
import { initSelectedOptions } from '../CryptoHistory/utils';
import {
	IHistoryFiltersSelectedOptions,
	ITransactionsTableSortedParams,
	THistoryFiltersOption,
} from '../CryptoHistory/types';
import CryptoHistoryRow from '../CryptoHistory/CryptoHistoryRow';
import Loading from '../../../../layouts-elements/Loading/Loading';
/* eslint-disable */

const FiltersSearchArray = [{ name: 'Tx ID', value: ETransactionsFilters.TX_ID }];

const FiatHistory = () => {
	const { userId } = useParams<IAccountDetailsParams>();
	const dispatch = useDispatch();
	const fiatHistoryList = useSelector(getFiatHistory);
	const pageCount = useSelector(getFiatHistoryPageCount);
	//debugger;

	const activeFilters = useSelector(getFiatFilters);

	const [currentPage, setCurrentPage] = useState(1);
	const [filters, setFilters] = useState(initSelectedOptions);
	const [firstLoading, setFirstLoading] = useState(true);
	const [itemsPerPage, setItemsPerPage] = useState<number | string>(100);
	const [reset, setReset] = useState(false);
	const [resetSearchValue, setResetSearchValue] = useState(false);

	const [sorted, setSorted] = useState<ITransactionsTableSortedParams | null>(null);

	const handleFiltersChange = (newFilters: IHistoryFiltersSelectedOptions) => {
		setFilters(newFilters);
		setCurrentPage(1);
	};

	const handlePageClick = ({ selected }: { selected: number }) => {
		setCurrentPage(selected + 1);
		window.scrollTo(0, 0);
	};

	useLayoutEffect(() => {
		const filterParams = convertFilterObjectToParamsObject(activeFilters);
		const sortedParamsObj = sorted ? { [`${sorted?.type}_sort`]: sorted?.value } : {};
		const params: IGetTransactionHistoryRequestPayload = {
			apiParams: {
				userId,
				current_page: currentPage,
				type: filters.type.value,
				per_page: Number(itemsPerPage),
				...filterParams,
				...sortedParamsObj,
			},
			onFinally: () => setFirstLoading(false),
		};
		dispatch(getFiatHistoryRequest(params));
	}, [sorted, currentPage, dispatch, activeFilters, filters.type.value, userId, itemsPerPage]);

	const handleChangePerPage = (value: string | number) => {
		setCurrentPage(Math.ceil(fiatHistoryList.from / Number(value)));
		setItemsPerPage(value);
	};
	const handleResetSearchValue = () => {
		setResetSearchValue(!resetSearchValue);
	};
	const handleResetFilters = () => {
		setFilters(initSelectedOptions);
		setReset(true);
		handleResetSearchValue();
		dispatch(resetFilters(EFiltersType.TRANSACTIONS_FIAT));
	};
	useLayoutEffect(() => {
		return () => {
			dispatch(resetFilters(EFiltersType.TRANSACTIONS_FIAT));
		};
	}, [dispatch]);

	const onChangeDate = (startDate: Date, endDate: Date) => {
		setReset(false);
		dispatch(
			addFilter({
				type: ETransactionsFilters.DATE,
				value: { date_from: startDate, date_to: endDate },
				filterType: EFiltersType.TRANSACTIONS_FIAT,
			}),
		);
	};

	const fiatCurrencyData = useSelector(getFiatCurrencyData);
	const fiatCurrencyFilterOptions = fiatCurrencyData.map(({ id, code, img_path }) => ({
		id,
		name: code.toUpperCase(),
		value: id,
		path: img_path,
		code,
	}));

	const handleAssetChange = ({ name, value }: THistoryFiltersOption) => {
		setReset(false);

		dispatch(
			addFilter({
				type: ETransactionsFilters.ASSET_ID,
				value: value as string,
				name,
				filterType: EFiltersType.TRANSACTIONS_FIAT,
			}),
		);
	};

	const handleStatusChange = ({ name, value }: THistoryFiltersOption) => {
		setReset(false);

		dispatch(
			addFilter({
				type: ETransactionsFilters.STATUS,
				value: value as string,
				name,
				filterType: EFiltersType.TRANSACTIONS_FIAT,
			}),
		);
	};
	const handleSearch = (searchField: ISearchSelectArr, currentSearchValue: string) => {
		if (currentSearchValue === '') {
			dispatch(
				deleteFilter({
					type: searchField.value as ETransactionsFilters,
					filterType: EFiltersType.TRANSACTIONS_FIAT,
				}),
			);
		} else {
			dispatch(
				addFilter({
					type: searchField.value as ETransactionsFilters,
					value: currentSearchValue,
					name: `${searchField.name}: ${currentSearchValue}`,
					filterType: EFiltersType.TRANSACTIONS_FIAT,
				}),
			);
		}
	};

	return (
		<div className="transfer-history-wrapper">
			<ActiveReportsFilters
				handleResetFilters={handleResetFilters}
				handleDateChange={onChangeDate}
				handleChangeAsset={handleAssetChange}
				currencyOptions={fiatCurrencyFilterOptions}
				handleStatusChange={handleStatusChange}
				statusOptions={STATUS_USER_FIAT_FILTER_OPTIONS}
				reset={reset}
				searchArray={FiltersSearchArray}
				handleSearch={handleSearch}
				resetValue={resetSearchValue}
				type="user-history"
			/>
			<div className="table-block">
				<div className="table-wrapper table-wrapper--with-y-scroll">
					<div className="table table--type2 table--transaction-history-fiat">
						<div className="table-header">
							<FiatHistoryHeader
								setSorted={setSorted}
								selectedOptions={filters}
								onChange={handleFiltersChange}
							/>
						</div>
						<div className="table-body">
							{!firstLoading && (
								<>
									{' '}
									{fiatHistoryList?.data?.length ? (
										fiatHistoryList?.data?.map((item: any) => (
											<FiatHistoryRow key={item.id} item={item} />
										))
									) : (
										<TableBodyNoData />
									)}
								</>
							)}
							{firstLoading ? <Loading /> : null}

						</div>
					</div>
				</div>
				{!!fiatHistoryList?.data?.length && (
					<div className="table-footer">
						<div className="pagination-block pagination-block--full">
							<PerPageDropdown
								options={[10, 20, 50, 100]}
								setPerPage={handleChangePerPage}
								perPage={Number(itemsPerPage)}
							/>
							{pageCount > 1 && (
								<ReactPaginate
									breakLabel="..."
									pageCount={pageCount}
									onPageChange={handlePageClick}
									forcePage={currentPage - 1}
									className="pagination"
									activeClassName="active"
									previousClassName="pagination__arrow pagination__arrow--prev"
									nextClassName="pagination__arrow pagination__arrow--next"
									previousLabel=""
									nextLabel=""
								/>
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default FiatHistory;


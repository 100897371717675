/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable prettier/prettier */
import  React, { FC, useLayoutEffect, useState } from 'react';
import moment from 'moment';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { useFormatDateTime } from 'hooks/useFormatDateTime';
import { TFiatTransactionsItem } from 'redux/reducers/transactions/types';
import { EApiFiatTransactionStatus, EApiTransactionType } from 'services/api/transactions/types';
import { notificationContainer } from 'services/utils/notificationContainer';
import { roundingNumber } from 'services/utils/roundingNumber';
import { CheckIcon, CloseIcon, PencilIcon } from 'assets/inline-svg';
import TableItemSelect from 'ui/TableItemSelect/TableItemSelect';
import { IHistoryFiltersOptionStringValue } from 'components/AccountDetails/TransactionHistoryTab/CryptoHistory/types';
import { useDispatch } from 'react-redux';
import { putFiatTransactionsRequest } from 'redux/reducers/transactions/reducer';
import IconSvg from 'ui/Svg/IconSvg';
import { ICountry } from 'components/tables/CreateUserTables/UserTable';
import { getNameList } from "country-list";
import { toFixedNumber } from '../../../../services/utils/toFixedNumber';
import { capitalizeWords } from "../../../../services/utils/strings";
import {setDetailedViewData} from "../../../../redux/reducers/detailedView/reducer";

export interface IFiatTransactionsRow extends TFiatTransactionsItem {
	isAllTypes?: boolean;
	permission: boolean;
	admin_id?: number;
	admin?: any;
	account: any;
}

const INIT_VALUE_TO_EDIT = {
	id: 0,
	value: EApiFiatTransactionStatus.PENDING,
	name: 'Pending',
};

const STATUS_TO_EDIT = [
	{
		id: 1,
		name: 'Completed',
		value: EApiFiatTransactionStatus.COMPLETED,
	},
	{
		id: 2,
		name: 'Failed',
		value: EApiFiatTransactionStatus.FAILED,
	},
];

const FiatTransactionsRow: FC<IFiatTransactionsRow> = ({
	bank,
	type,
	id,
	created_at,
	asset,
	user,
																												 account,
	fee,
	status,
	admin_requisite,
	isAllTypes,
	permission,
	note,
	gross_fee,
	net_fee,
	user_id,
	account_id,
	admin_id,
	admin,
}) => {
	const dispatch = useDispatch();
	const { path } = useRouteMatch();
	const [dateNow] = useFormatDateTime(new Date().toISOString());
	const { pathname } = useLocation();
	const [date, time] = useFormatDateTime(created_at);

	const [editedFee, setEditedFee] = useState(String(fee));
	const handleEditFee = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEditedFee(event.target.value);
	};

	const [editedGross, setEditedGross] = useState(gross_fee);
	const handleEditGross = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEditedGross(Number(event.target.value));
	};

	const [editedNote, setEditedNote] = useState(note || '-');
	const handleEditNote = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEditedNote(event.target.value);
	};

	const filterStatusArrayToEdit = (currentStatus: EApiFiatTransactionStatus | string) => {
		return STATUS_TO_EDIT.filter((el) => el.value !== currentStatus);
	};
	const findEditedStatus = (currentStatus: EApiFiatTransactionStatus) => {
		return STATUS_TO_EDIT.find((el) => el.value === currentStatus) || INIT_VALUE_TO_EDIT;
	};

	const [editedStatus, setEditedStatus] =
		useState<IHistoryFiltersOptionStringValue>(INIT_VALUE_TO_EDIT);
	const [statusArrayToEdit, setStatusArrayToEdit] = useState<IHistoryFiltersOptionStringValue[]>(
		filterStatusArrayToEdit(status),
	);
	const handleEditStatus = (activeElement: IHistoryFiltersOptionStringValue) => {
		setEditedStatus(activeElement);
		setStatusArrayToEdit(filterStatusArrayToEdit(activeElement.value));
	};

	const [editMode, setEditMode] = useState(false);
	const enableEditMode = () => setEditMode(true);
	const disableEditMode = () => {
		setEditedFee(String(fee));
		setEditedGross(gross_fee);
		setEditedStatus(findEditedStatus(status));
		setStatusArrayToEdit(filterStatusArrayToEdit(status));
		setEditMode(false);
	};
	const handleEdit = () => {
		dispatch(
			putFiatTransactionsRequest({
				data: {
					date: dateNow,
					fee: Number(editedFee),
					indicated_amount: editedGross,
					status: editedStatus.value,
					note: editedNote || '',
					admin_requisite_id: type === 'deposit' ? admin_requisite.id : id,
				},
				type,
				id,
			}),
		);
		setEditMode(false);
	};
	const handleCopy = () => {
		notificationContainer('Сopied to clipboard.', 'info');
	};
	useLayoutEffect(() => {
		setEditedFee(String(fee));
		setEditedGross(gross_fee);
		setEditedNote(note);
		setEditedStatus(findEditedStatus(status));
	}, [fee, gross_fee, note, status]);

	const [countries, setCountries] = useState<ICountry[]>([]);
	const [beneficiaryCountry, setBeneficiaryCountry] = useState<ICountry | null>(null);
	const [bankCountry, setBankCountry] = useState<ICountry | null>(null);

	useLayoutEffect(() => {
		const result = Object.entries(getNameList()).map(([key, value]) => ({
			label: capitalizeWords(key),
			value,
		}));
		setCountries(result);
	}, []);

	useLayoutEffect(() => {
		if (bank?.beneficiary_country && bank?.bank_country) {
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			// fetch(
			// 	'https://valid.layercode.workers.dev/list/countries?format=select&flags=false&value=code',
			// )
			// 	.then((response) => response.json())
			// 	.then((data) => {
			// 		setBeneficiaryCountry(
			// 			data.countries.filter((item: ICountry) => item.value === bank?.beneficiary_country)[0],
			// 		);
			// 		setBankCountry(
			// 			data.countries.filter((item: ICountry) => item.value === bank?.bank_country)[0],
			// 		);
			// 	});
			setBeneficiaryCountry(
				countries.filter((item: ICountry) => item.value === bank?.beneficiary_country)[0],
			);
			setBankCountry(
				countries.filter((item: ICountry) => item.value === bank?.bank_country)[0],
			);
		}

	}, [bank, countries]);
	return (
		<div className={`tr ${editMode ? 'tr--editable' : ''}`}>
			<div className="td">
				<p className="td-hidden-name">Timestamp</p>
				<p className="td-name--flex">{moment.utc(created_at).format('YYYY-MM-DD')}</p>
				<span className="td-more-info">{moment.utc(created_at).format('HH:mm:ss')}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">{pathname.includes('deposit') ? 'User Email' : 'User ID'}</p>
				{user ? (
					<>
						{pathname.includes('deposit') ? (
							<Link to={`/user-management/${String(user.id)}/account`} className="link">
								{user.email || '-'}
							</Link>
						) : (
							<p>{user.id || ''}</p>
						)}
					</>
				) : (
					<>
						{pathname.includes('deposit') ? (
							<Link to={`/user-management/${String(admin.id)}/account`} className="link">
								{admin.email || '-'}
							</Link>
						) : (
							<p>{admin.id || ''}</p>
						)}
					</>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Account ID</p>
				{account_id && <p>{account_id}</p>}
			</div>
			<div className="td">
				<p className="td-hidden-name">Oneify ID</p>
				{!!account && account?.oneify_id && <p>{account?.oneify_id}</p>}
			</div>
			{isAllTypes && (
				<div className="td">
					<p className="td-hidden-name">Type</p>
					<p className="td-status">{type}</p>
				</div>
			)}
			<div className="td">
				<p className="td-hidden-name">Currency</p>
				<p className="td-name--flex">{asset.code.toUpperCase()}</p>
				<span className="td-more-info">{asset.name}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Gross</p>
				{editMode ? (
					<input
						type="number"
						placeholder="0"
						name="indicated_amount"
						value={editedGross || ''}
						onChange={handleEditGross}
						className="input-item input--table-item input-item---fullWidth input-item--type-td"
					/>
				) : (
					<>
						<p>{roundingNumber(gross_fee, asset.code)}</p>
						<span className="td-more-info">{asset.code.toUpperCase()}</span>
					</>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Fee</p>
				{editMode ? (
					<input
						type="number"
						placeholder="0"
						name="fee"
						value={editedFee || ''}
						onChange={handleEditFee}
						className="input-item input--table-item input-item---fullWidth input-item--type-td"
					/>
				) : (
					<>
						<p>{roundingNumber(fee, asset.code)}</p>
						<span className="td-more-info">{asset.code.toUpperCase()}</span>
					</>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Net</p>
				<p>{toFixedNumber(net_fee, asset.code, true)}</p>
				<span className="td-more-info">{asset.code.toUpperCase()}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">
					{pathname.includes('deposit') ? 'Account Name' : 'Destination'}
				</p>
				<p>
					<span className="tooltip-wrap">
						{bank?.bank_name || '-'}

						{bank?.bank_name && (
							<span className="tooltip-item">
								<span className="tooltip-item__title">
									<IconSvg name="info" />
								</span>
								<span className="tooltip tooltip--width-720 tooltip--bottom">
									<span className="bank-info">
										<span className="bank-info bank-info--title">{bank?.bank_name}</span>
										<span className="bank-info bank-info--list-wrapper">
											<span className="bank-info bank-info--list">
												<span className="bank-info bank-info--item">
													<span>Beneficiary Name</span>
													<p>{bank?.beneficiary_name}</p>
												</span>
												<span className="bank-info bank-info--item">
													<span>Beneficiary Address</span>
													<p>{bank?.beneficiary_address}</p>
												</span>
												<span className="bank-info bank-info--item">
													<span>Beneficiary Country</span>
													<p>{beneficiaryCountry?.label || '-'}</p>
												</span>
												<span className="bank-info bank-info--item">
													<span>Bank Name</span>
													<p>{bank?.bank_name}</p>
												</span>
											</span>
											<span className="bank-info-list bank-info--list">
												<span className="bank-info  bank-info--item">
													<span>Bank Address</span>
													<p>{bank?.bank_address}</p>
												</span>
												<span className="bank-info  bank-info--item">
													<span>Bank Country</span>
													<p>{bankCountry?.label || '-'}</p>
												</span>
												<span className="bank-info  bank-info--item">
													<span>SWIFT / BIC</span>
													<p>{bank?.swift_bic}</p>
												</span>
												<span className="bank-info  bank-info--item">
													<span>IBAN / Account number</span>
													<p>{bank?.account_number}</p>
												</span>
											</span>
											<span className="bank-info  bank-info--text">
												<span>Notes</span>
												<p>{bank?.note}</p>
											</span>
										</span>
									</span>
								</span>
							</span>
						)}
					</span>
					<span className="td-more-info">{bank?.account_number}</span>
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Tx ID</p>
				{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
				{/* @ts-ignore */}
				{type === 'pool_profit' ? (
					<span className="copy-button__text copy-button__text--max-width">{id}</span>
				) : (
					/* <Link
						to={{
							pathname: `${path}/${id}`,
							state: {fromWithdrawalFiatReports: true, txId: id, userId: user_id},
						}}
					>
						{id}
					</Link> */
					<a
						href="/#"
						onClick={(e) => {
							e.preventDefault();
							const currentScrollY = window.scrollY;
							localStorage.setItem('scrollPosition', currentScrollY.toString());
							window.scrollTo(0, 0);
							dispatch(
								setDetailedViewData({
									show: true,
									from: 'fromWithdrawalFiatReports',
									userId: user_id,
									txId: id,
								}),
							);
						}}
					>
						{id}
					</a>
				)}
			</div>
			{/* {(pathname.includes('deposit') || pathname.includes('withdrawals')) && (
				<div className="td">
					<p className="td-hidden-name">Notes</p>
					{editMode ? (
						<input
							type="text"
							placeholder="Note"
							name="note"
							value={editedNote || ''}
							onChange={handleEditNote}
							className="input-item input--table-item input-item---fullWidth input-item--type-td"
						/>
					) : (
						<div className="tooltip-item-note">
							<p className="tooltip-item-text">{note ? `${note?.slice(0, 10)}...` : '-'}</p>
							{note && (
								<div className="tooltip-note">
									<p>{note}</p>
									<i />
								</div>
							)}
						</div>
					)}
				</div>
			)} */}
			<div className="td">
				<p className="td-hidden-name">Status</p>
				{editMode ? (
					<TableItemSelect
						activeElement={editedStatus}
						selectArray={statusArrayToEdit}
						onChange={handleEditStatus}
					/>
				) : (
					<span className={`${status}-status`}>{`${status.charAt(0).toUpperCase()}${status.slice(
						1,
					)}`}</span>
				)}
			</div>
			<div className={`td  td--right ${editMode ? 'td--editable' : ''}`}>
				<p className="td-hidden-name">Action</p>
				{permission && editMode && (
					<div className="table-buttons flex-e">
						<button type="button" className="btn btn--icon btn--check" onClick={handleEdit}/>

						<button type="button" className="btn btn--icon btn--cancel" onClick={disableEditMode}/>
					</div>
				)}
				{permission && !editMode && (
					// eslint-disable-next-line react/self-closing-comp, jsx-a11y/control-has-associated-label
					<button
						type="button"
						className="btn btn--icon btn--edit"
						onClick={enableEditMode}
						disabled={status !== EApiFiatTransactionStatus.PENDING}
					></button>
				)}
			</div>
		</div>
	);
};
export default FiatTransactionsRow;


import React, { FC } from 'react';
import { User } from '../../../../../../services/api/multiAccounts/types';

interface IProps {
	item: User;
}

const TableBody: FC<IProps> = ({ item }) => {
	// Формируем полное имя пользователя
	const fullName = `${item.data.first_name} ${item.data.last_name}`;
	const roles = item.roles
		.map((role) => {
			const formatted = role.name
				.replace(/-/g, '')
				.replace(/_/g, ' ')
				.replace(/^(\w)/, (match) => match.toUpperCase())
				.replace(/(?<=\s)(\w)/g, (match) => match.toLowerCase());
			return formatted;
		})
		.join(', ');
	return (
		<div className="tr">
			<div className="td">
				<div className="td-hidden-name">User ID</div>
				<div className="td__wrap">
					<div className="td__text">{item.id}</div>
				</div>
			</div>
			<div className="td">
				<div className="td-hidden-name">Oneify ID</div>
				<div className="td__wrap">
					<div className="td__text">{item.oneify_id}</div>
				</div>
			</div>
			<div className="td">
				<div className="td-hidden-name">Full Name</div>
				<div className="td__wrap">
					<div className="td__text">{fullName}</div>
				</div>
			</div>
			<div className="td">
				<div className="td-hidden-name">Email</div>
				<div className="td__wrap">
					<div className="td__text">
						<a href={`mailto:${item.email}`}>{item.email}</a>
					</div>
				</div>
			</div>
			<div className="td">
				<div className="td-hidden-name">Company Name</div>
				<div className="td__wrap">
					<div className="td__text">{item.data.company_name || '-'}</div>
				</div>
			</div>
			<div className="td">
				<div className="td-hidden-name">Roles</div>
				<div className="td__wrap">
					<div className="td__text">{roles === 'admin' ? '' : roles}</div>
				</div>
			</div>
		</div>
	);
};

export default TableBody;

import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { getPlainTextFromEditorState } from '../../../../../../utils/getPlainText';
import AccountNotesEditor from '../../AccountNotesEditor';
import { IUserNotesData } from '../../../../../../redux/reducers/users/types';

export interface IAccountNotesTableRow {
	data: IUserNotesData;
	deleteUserNoteHandler: (note_id: number) => void;
	updateUserNoteHandler: (note_id: number, note: string) => void;
	actionLoading: boolean;
	reset?: boolean;
}

const AccountNotesTableRow: FC<IAccountNotesTableRow> = ({
	data,
	deleteUserNoteHandler,
	updateUserNoteHandler,
	actionLoading,
	reset,
}) => {
	const [hasError, setHasError] = useState(false);
	const [errorLong, setErrorLong] = useState(false);
	const [errorEmpty, setErrorEmpty] = useState(false);
	const [noteValue, setNoteValue] = useState('');
	const [initialNoteValue, setInitialNoteValue] = useState('');
	const [editMode, setEditMode] = useState(false);
	const [resetValue, setResetValue] = useState(false);

	const clearErrors = () => {
		setHasError(false);
		setErrorEmpty(false);
		setErrorLong(false);
	};

	const updateHandler = () => {
		if (
			getPlainTextFromEditorState(noteValue) &&
			getPlainTextFromEditorState(noteValue).length > 2000
		) {
			setHasError(true);
			setErrorLong(true);
			return;
		}

		if (!getPlainTextFromEditorState(noteValue)) {
			setHasError(true);
			setErrorEmpty(true);
			return;
		}

		updateUserNoteHandler(data.id, noteValue);
	};

	useEffect(() => {
		if (
			getPlainTextFromEditorState(noteValue) &&
			getPlainTextFromEditorState(noteValue).length > 2000
		) {
			setHasError(true);
			setErrorLong(true);
		}

		if (
			getPlainTextFromEditorState(noteValue) &&
			getPlainTextFromEditorState(noteValue).length <= 2000
		) {
			clearErrors();
		}
	}, [noteValue]);

	useEffect(() => {
		setInitialNoteValue(data.note);
	}, [data.note]);

	useEffect(() => {
		setEditMode(false);
	}, [reset]);

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Timestamp</p>
				<div className="td__wrap td__wrap--column">
					<p>{moment.utc(data.created_at).format('YYYY-MM-DD')}</p>
					<span className="td-more-info">{moment.utc(data.created_at).format('HH:mm:ss')}</span>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Notes</p>
				<div
					className={`td__wrap td__wrap--column gap-10 ${editMode ? 'd-none' : 'd-flex'}`}
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{ __html: data.note }}
				/>
				<div className={`td__wrap td__wrap--column ${editMode ? 'd-block' : 'd-none'}`}>
					<AccountNotesEditor
						hasError={hasError}
						errorEmpty={errorEmpty}
						errorLong={errorLong}
						setValue={setNoteValue}
						value={initialNoteValue}
						resetOldValue={resetValue}
					/>
				</div>
			</div>
			<div className="td td--center">
				<p className="td-hidden-name">Action</p>
				<div className="table-buttons">
					{editMode ? (
						<>
							{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
							<button
								type="button"
								className="btn--icon-danger btn--cancel"
								onClick={() => {
									setEditMode(false);
									setResetValue(!resetValue);
								}}
								disabled={actionLoading}
							/>
							{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
							<button
								type="button"
								className="btn--icon-success btn--check"
								onClick={updateHandler}
								disabled={actionLoading}
							/>
						</>
					) : (
						<>
							{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
							<button
								type="button"
								className="btn btn--icon btn--edit"
								onClick={() => setEditMode(true)}
								disabled={actionLoading}
							/>
							{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
							<button
								type="button"
								className="btn btn--icon-danger btn--delete"
								onClick={() => deleteUserNoteHandler(data.id)}
								disabled={actionLoading}
							/>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default AccountNotesTableRow;

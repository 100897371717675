import { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from 'ui/Pagination/Pagination';
import PerPageSelect from 'ui/PerPageSelect/PerPageSelect';
import { getFeeFiatRequest, getFeeRequest } from 'redux/reducers/fee/reducer';
import { getFeeFiatStoreData, getFeeStoreData } from 'redux/reducers/fee/selectors';
import { setUserData } from 'redux/reducers/users/reducer';
import { getUserCreateData } from 'redux/reducers/users/selectors';
import FeeFiatManagementTradeItem from './FeeFiatManagementTradeItem/FeeFiatManagementTradeItem';

const FeeFiatManagementTrade = () => {
	const dispatch = useDispatch();
	const fee = useSelector(getFeeFiatStoreData);
	const userData = useSelector(getUserCreateData);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState<number | undefined>(10);
	const handleSetPerPage = (value: number | undefined) => {
		setPerPage(value);
		setCurrentPage(1);
	};
	const pageCount = fee?.dataFiat?.last_page || 1;

	const assetFilterData = fee?.dataFiat?.data?.map((el: any) => {
		const currentAsset = userData?.asset_fees?.find(
			(item: any) => item.asset_id === el.fee.asset_id,
		);
		if (currentAsset) {
			return {
				...el,
				fee: {
					...el.fee,
					same_asset_type_trade_fee_percent: currentAsset?.same_asset_type_trade_fee_percent,
					same_asset_type_trade_fee_fixed: currentAsset?.same_asset_type_trade_fee_fixed,
					trade_fee_percent: currentAsset?.trade_fee_percent,
					trade_fee_fixed: currentAsset?.trade_fee_fixed,
					trade_slippage: currentAsset?.trade_slippage,
					debit_percent: currentAsset?.debit_percent,
					debit_fee: currentAsset?.debit_fee,
					credit_percent: currentAsset?.credit_percent,
					credit_fee: currentAsset?.credit_fee,
				},
			};
		}
		return el;
	});

	useLayoutEffect(() => {
		const data = {
			current_page: currentPage,
			per_page: perPage,
			type: 'fiat',
		};
		dispatch(getFeeFiatRequest(data));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, perPage, currentPage]);

	const updateHandler = ({
		asset_id,
		same_asset_type_trade_fee_percent,
		same_asset_type_trade_fee_fixed,
		trade_fee_percent,
		trade_fee_fixed,
		trade_slippage,
		debit_percent,
		debit_fee,
		credit_percent,
		credit_fee,
	}: any) => {
		let newData: any = userData?.asset_fees ? [...userData.asset_fees] : [];
		const asset = userData?.asset_fees?.find((item) => item.asset_id === asset_id);
		if (asset && userData?.asset_fees) {
			newData = userData?.asset_fees?.map((item) => {
				return item.asset_id === asset_id
					? {
							...item,
							same_asset_type_trade_fee_percent,
							same_asset_type_trade_fee_fixed,
							trade_fee_percent,
							trade_fee_fixed,
							trade_slippage,
							debit_percent,
							debit_fee,
							credit_percent,
							credit_fee,
					  }
					: item;
			});
		} else {
			fee?.dataFiat?.data.forEach((item: any) => {
				if (item.fee.asset_id === asset_id) {
					newData.push({
						asset_id: item?.fee.asset_id,
						same_asset_type_trade_fee_percent,
						same_asset_type_trade_fee_fixed,
						deposit_fee_percent: item?.fee?.deposit_fee_percent,
						deposit_fee_fixed: item?.fee?.deposit_fee_fixed,
						withdraw_fee_percent: item?.fee?.withdraw_fee_percent,
						withdraw_fee_fixed: item?.fee?.withdraw_fee_fixed,
						trade_fee_percent: item?.fee?.trade_fee_percent,
						trade_fee_fixed: item?.fee?.trade_fee_fixed,
						trade_slippage: item?.fee?.trade_slippage,
						debit_percent: item?.fee?.debit_percent,
						debit_fee: item?.fee?.debit_fee,
						credit_percent: item?.fee?.credit_percent,
						credit_fee: item?.fee?.credit_fee,
					});
				}
			});
		}

		dispatch(setUserData({ ...userData, asset_fees: newData }));
	};

	return (
		<>
			<div className="table-block">
				<div className="table-wrapper">
					<div className="table table--user-management-fee-management-withdraw-crypto fiat-fiat-td">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<div className="td-name">
										<p>Currency</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Fee %</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Fee Fixed</p>
									</div>
								</div>
								<div className="td td--right">
									<div className="td-name">
										<p>Action</p>
									</div>
								</div>
							</div>
						</div>
						<div className="table-body">
							{assetFilterData.length
								? assetFilterData.map((el: any) => (
										<FeeFiatManagementTradeItem key={el.id} updateHandler={updateHandler} el={el} />
								  ))
								: 'Empty'}
						</div>
						{/* {!!assetFilterData.length && ( */}
						{/*	<div className="table-footer"> */}
						{/*		<div className="pagination-block"> */}
						{/*			<PerPageSelect onChange={handleSetPerPage} /> */}
						{/*			{pageCount > 1 && ( */}
						{/*				<Pagination */}
						{/*					pageCount={pageCount} */}
						{/*					currentPage={currentPage} */}
						{/*					onPageChange={setCurrentPage} */}
						{/*				/> */}
						{/*			)} */}
						{/*		</div> */}
						{/*	</div> */}
						{/* )} */}
					</div>
				</div>
			</div>
			{/* {!!assetFilterData.length && (
				<div className="table-pagination">
					<PerPageSelect onChange={handleSetPerPage} />
					{pageCount > 1 && (
						<div className="pagination-block">
							<Pagination
								pageCount={pageCount}
								currentPage={currentPage}
								onPageChange={setCurrentPage}
							/>
						</div>
					)}
				</div>
			)} */}
		</>
	);
};

export default FeeFiatManagementTrade;

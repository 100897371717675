import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IMultiAccountsStore } from './types';

// Корень для multiAccounts
const getMultiAccountsState = (state: IStoreState): IMultiAccountsStore => state.multiAccounts;

// ========================== GET ACCOUNTS ==========================
export const getAccountsDataParamsSelector = createSelector(
	getMultiAccountsState,
	(multiAccounts) => multiAccounts.getAccounts.params,
);
export const getAccountsDataSelector = createSelector(
	getMultiAccountsState,
	(multiAccounts) => multiAccounts.getAccounts.data,
);

export const getAccountsLoadingSelector = createSelector(
	getMultiAccountsState,
	(multiAccounts) => multiAccounts.getAccounts.isLoading,
);

export const getAccountsErrorSelector = createSelector(
	getMultiAccountsState,
	(multiAccounts) => multiAccounts.getAccounts.error,
);

// ========================== APPROVE INVITE REQUEST ==========================
export const approveInviteRequestDataSelector = createSelector(
	getMultiAccountsState,
	(multiAccounts) => multiAccounts.approveInviteRequest.data,
);

export const approveInviteRequestLoadingSelector = createSelector(
	getMultiAccountsState,
	(multiAccounts) => multiAccounts.approveInviteRequest.isLoading,
);

export const approveInviteRequestErrorSelector = createSelector(
	getMultiAccountsState,
	(multiAccounts) => multiAccounts.approveInviteRequest.error,
);

// ========================== PENDING INVITE REQUEST ==========================
export const pendingInviteRequestDataSelector = createSelector(
	getMultiAccountsState,
	(multiAccounts) => multiAccounts.pendingInviteRequest.data,
);

export const pendingInviteRequestLoadingSelector = createSelector(
	getMultiAccountsState,
	(multiAccounts) => multiAccounts.pendingInviteRequest.isLoading,
);

export const pendingInviteRequestErrorSelector = createSelector(
	getMultiAccountsState,
	(multiAccounts) => multiAccounts.pendingInviteRequest.error,
);

// ========================== REJECT INVITE REQUEST ==========================
export const rejectInviteRequestDataSelector = createSelector(
	getMultiAccountsState,
	(multiAccounts) => multiAccounts.rejectInviteRequest.data,
);

export const rejectInviteRequestLoadingSelector = createSelector(
	getMultiAccountsState,
	(multiAccounts) => multiAccounts.rejectInviteRequest.isLoading,
);

export const rejectInviteRequestErrorSelector = createSelector(
	getMultiAccountsState,
	(multiAccounts) => multiAccounts.rejectInviteRequest.error,
);

// ========================== GET ACCOUNT MEMBERS ==========================
export const getAccountMembersDataSelector = createSelector(
	getMultiAccountsState,
	(multiAccounts) => multiAccounts.getAccountMembers,
);

export const getAccountMembersLoadingSelector = createSelector(
	getMultiAccountsState,
	(multiAccounts) => multiAccounts.getAccountMembers.isLoading,
);

export const getAccountMembersErrorSelector = createSelector(
	getMultiAccountsState,
	(multiAccounts) => multiAccounts.getAccountMembers.error,
);

// ========================== GET ACCOUNT REQUESTS ==========================
export const getAccountRequestsDataSelector = createSelector(
	getMultiAccountsState,
	(multiAccounts) => multiAccounts.getAccountRequests.data,
);

export const getAccountRequestsLoadingSelector = createSelector(
	getMultiAccountsState,
	(multiAccounts) => multiAccounts.getAccountRequests.isLoading,
);

export const getAccountRequestsErrorSelector = createSelector(
	getMultiAccountsState,
	(multiAccounts) => multiAccounts.getAccountRequests.error,
);

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import IconSvg from 'ui/Svg/IconSvg';
import { deleteWalletAddressRequest } from 'redux/reducers/walletAddresses/reducer';

import { setPopUpData } from 'redux/reducers/popUp/reducer';

import { currencyValueRequest, putCurrencyValueRequest } from 'redux/reducers/currency/reducer';
import { useParams } from 'react-router';
import Item from './Item/Item';
import {
	IGetCurrencyUserPayload,
	IPutCurrencyUserParams,
	IPutCurrencyUserPayload,
} from '../../../../redux/reducers/currency/types';
import { IAccountDetailsParams } from '../../types';
import {
	getCryptoCurrency,
	getCryptoCurrencyLoading,
	updateState,
} from '../../../../redux/reducers/currency/selectors';
import TradeHistoryRow from '../../TransactionHistoryTab/TradeHistory/TradeHistoryRow';
import TableBodyNoData from '../../../../ui/TableBodyNoData/TableBodyNoData';
import Loading from '../../../../layouts-elements/Loading/Loading';

const Crypto = ({ permission }: { permission: boolean }) => {
	const { userId } = useParams<IAccountDetailsParams>();
	const dispatch = useDispatch();
	const item = useSelector(getCryptoCurrency);
	const loading = useSelector(getCryptoCurrencyLoading);
	const updateStateRequest = useSelector(updateState);
	const perPage = 9;
	const totalPage = item?.total;
	useLayoutEffect(() => {
		const params: IGetCurrencyUserPayload = {
			apiParams: {
				userId: String(userId),
				value: 'crypto',
				current_page: 1,
				per_page: 9,
			},
			//
			// onFinally: () => setFirstLoading(false),
		};
		dispatch(currencyValueRequest(params));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateStateRequest]);

	const handlePageClick = ({ selected }: { selected: number }) => {
		const params: IGetCurrencyUserPayload = {
			apiParams: {
				userId: String(userId),
				value: 'crypto',
				current_page: selected + 1,
				per_page: 9,
			},
			//
			// onFinally: () => setFirstLoading(false),
		};
		dispatch(currencyValueRequest(params));
	};

	const onDelete = (id: number, nickname: string) => {
		dispatch(
			setPopUpData({
				message: `Your wallet address "${nickname}" was deleted successfully.`,
			}),
		);
		dispatch(deleteWalletAddressRequest({ id }));
	};

	const deleteHandler = (id: number, nickname: string) => {
		dispatch(
			setPopUpData({
				title: 'Delete Wallet Address',
				message: 'Are you sure you what to delete?',
				confirmHandler: () => onDelete(id, nickname),
			}),
		);
	};
	const updateHandler = (obj: IPutCurrencyUserParams, assetName: string) => {
		const action = Object.values(obj)[3] ? 'enabled' : 'disabled';
		dispatch(putCurrencyValueRequest({ apiParams: obj, action, assetName }));
		console.log(action);
	};

	return (
		<>
			<div className="table-block">
				<div className="table-wrapper">
					<div className="table table--fee-management-currencles">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<div className="td-name">
										<p>Currency</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Deposit</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Withdrawal</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Trade</p>
									</div>
								</div>
							</div>
						</div>
						<div className="table-body">
							{!loading && (
								<>
									{' '}
									{item?.data?.length ? (
										item?.data?.map((e: any) => (
											<Item
												key={e.id}
												deleteHandler={deleteHandler}
												updateHandler={updateHandler}
												data={e}
												permission={permission}
											/>
										))
									) : (
										<TableBodyNoData />
									)}
								</>
							)}
							{loading ? <Loading /> : null}
						</div>
						{totalPage > perPage && (
							<div className="table-footer">
								<div className="pagination-block">
									<ReactPaginate
										breakLabel="..."
										pageCount={Math.ceil(totalPage / perPage)}
										onPageChange={handlePageClick}
										className="pagination"
										activeClassName="active"
										previousClassName="arrow arrow--prev"
										nextClassName="arrow arrow--next"
										previousLabel={<IconSvg name="keyboard-arrow-left" w="10" h="16" />}
										nextLabel={<IconSvg name="keyboard-arrow-right" w="10" h="16" />}
									/>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			{/* {totalPage > perPage && (
				<div style={{ marginBottom: '32px' }} className="pagination-block">
					<ReactPaginate
						breakLabel="..."
						pageCount={Math.ceil(totalPage / perPage)}
						onPageChange={handlePageClick}
						className="pagination"
						activeClassName="active"
						previousClassName="arrow"
						nextClassName="arrow"
						previousLabel={<IconSvg name="keyboard-arrow-left" w="10" h="16" />}
						nextLabel={<IconSvg name="keyboard-arrow-right" w="10" h="16" />}
					/>
				</div>
			)} */}
		</>
	);
};

export default Crypto;

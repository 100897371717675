import React, { useEffect, useLayoutEffect, useState } from 'react';
import ActiveLedgerFilters from 'ui/ActiveFilters/ActiveLedgerFilters';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import Loading from 'layouts-elements/Loading/Loading';

import { getLedgerLoading, getLedgerTransactions } from 'redux/reducers/transactions/selectors';
import { convertFilterObjectToParamsObject } from 'services/utils/convertFilterObjectToParamsObject';
import { IApiGetLedgerTransactionParams, ILedger } from 'services/api/transactions/types';
import { getLedgerTransactionsRequest } from 'redux/reducers/transactions/reducer';
import { getLedgerFilters } from 'redux/reducers/transactionsFilters/selectors';
import { addFilter, deleteFilter, resetFilters } from 'redux/reducers/transactionsFilters/reducer';
import { downloadLedgerRequest } from 'redux/reducers/download/reducer';
import { getAdminId } from 'redux/reducers/auth/selectors';

import { v4 as uuidv4 } from 'uuid';
import {
	EFiltersType,
	ETransactionsFilters,
} from '../../../../redux/reducers/transactionsFilters/types';
import { getCurrencyData } from '../../../../redux/reducers/currency/selectors';
import LedgerTableHeader from '../LedgerTableHeader';
import LedgerTableRow from '../LedgerTableRow';
import TableBodyNoData from '../../../../ui/TableBodyNoData/TableBodyNoData';
import PerPageSelect from '../../../../ui/PerPageSelect/PerPageSelect';
import { THistoryFiltersOption } from '../../../AccountDetails/DepositHistoryTab/CryptoHistory/types';

export interface ILedgerTableData {
	id: number;
	date: string;
	user_id: number | string;
	type: string;
	currency: {
		code: string;
		title: string;
	};
	debit: string;
	credit: string;
	fee: string;
	net: string;
	balance: string;
	destination: string;
	reference: string;
	tx_id: string;
}

interface ISearchField {
	name: string;
	value: string;
}

interface ILedgerTableFilters {
	user_id: string;
	currency: string;
	type: string;
	dateFrom: Date | string;
	dateTo: Date | string;
	sort_column?: string;
	sort_direction?: string;
}

const FiltersSearchArray = [
	{ name: 'User ID', value: ETransactionsFilters.USER_ID },
	{ name: 'Oneify ID', value: ETransactionsFilters.ONEIFY_ID },
];

const LedgerTable = () => {
	const dispatch = useDispatch();
	const ledgerTransactions = useSelector(getLedgerTransactions);
	const ledgerLoading = useSelector(getLedgerLoading);

	const adminId = useSelector(getAdminId);
	const [currentPage, setCurrentPage] = useState(ledgerTransactions?.current_page || 1);
	const activeFilters = useSelector(getLedgerFilters);
	const [perPage, setPerPage] = useState<number>(100);

	const [reset, setReset] = useState(false);
	const [resetSearchValue, setResetSearchValue] = useState(false);

	const pageCount = ledgerTransactions?.last_page ? ledgerTransactions?.last_page : 1;

	useLayoutEffect(() => {
		const filterParams = convertFilterObjectToParamsObject(activeFilters);

		const params: IApiGetLedgerTransactionParams = {
			per_page: perPage,
			current_page: currentPage,
			...filterParams,
		};

		dispatch(getLedgerTransactionsRequest(params));
	}, [currentPage, perPage, activeFilters, dispatch]);

	useEffect(() => {
		return () => {
			dispatch(resetFilters(EFiltersType.TRANSACTIONS_LEDGER));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSetPerPage = (value: number | undefined) => {
		if (value) {
			setPerPage(value);
		} else {
			setPerPage(9999);
		}
		setCurrentPage(1);
	};

	const handlePageClick = ({ selected }: { selected: number }) => {
		setCurrentPage(selected + 1);
		window.scrollTo(0, 0);
	};

	const currencyData = useSelector(getCurrencyData);
	const currencyFilterOptions = currencyData.map(({ id, code, img_path }) => ({
		id,
		name: code.toUpperCase(),
		value: id,
		path: img_path,
		code,
	}));

	const typeOptions = [
		{
			id: 1,
			name: 'Deposit',
			value: 'deposit',
		},
		{
			id: 2,
			name: 'Withdrawal',
			value: 'withdrawal',
		},
		{
			id: 3,
			name: 'Trade',
			value: 'trade',
		},
	];

	// Filter by user id
	const handleSearch = (searchField: ISearchField, currentSearchValue: string) => {
		setCurrentPage(1);
		if (currentSearchValue === '') {
			dispatch(
				deleteFilter({
					type: searchField.value as ETransactionsFilters,
					filterType: EFiltersType.TRANSACTIONS_LEDGER,
				}),
			);
		} else {
			dispatch(
				addFilter({
					type: searchField.value as ETransactionsFilters,
					value: currentSearchValue,
					name: `${searchField.name}: ${currentSearchValue}`,
					filterType: EFiltersType.TRANSACTIONS_LEDGER,
				}),
			);
		}
	};

	const handleResetFilters = () => {
		setReset(true);
		setResetSearchValue(!resetSearchValue);
		dispatch(resetFilters(EFiltersType.TRANSACTIONS_LEDGER));
	};

	// Filter by date

	const onChangeDate = (startDate: Date, endDate: Date) => {
		setReset(false);
		setCurrentPage(1);
		dispatch(
			addFilter({
				type: ETransactionsFilters.DATE,
				value: { date_from: startDate, date_to: endDate },
				filterType: EFiltersType.TRANSACTIONS_LEDGER,
			}),
		);
	};

	// Filter by currency
	const handleAssetChange = ({ name, value }: THistoryFiltersOption) => {
		setReset(false);
		setCurrentPage(1);

		if (!value) {
			dispatch(
				deleteFilter({
					type: ETransactionsFilters.CURRENCY,
					filterType: EFiltersType.TRANSACTIONS_LEDGER,
				}),
			);
		} else {
			dispatch(
				addFilter({
					name,
					value: name.toLowerCase(),
					type: ETransactionsFilters.CURRENCY,
					filterType: EFiltersType.TRANSACTIONS_LEDGER,
				}),
			);
		}
	};

	// Filter by type
	const handleTypeChange = ({ name, value }: { name: string; value: string | undefined }) => {
		setReset(false);
		setCurrentPage(1);
		if (!value) {
			dispatch(
				deleteFilter({
					type: ETransactionsFilters.TYPE,
					filterType: EFiltersType.TRANSACTIONS_LEDGER,
				}),
			);
		} else {
			dispatch(
				addFilter({
					name,
					value,
					type: ETransactionsFilters.TYPE,
					filterType: EFiltersType.TRANSACTIONS_LEDGER,
				}),
			);
		}
	};

	const handleDownloadFile = (extension: string) => {
		dispatch(downloadLedgerRequest({ adminId: String(adminId), extension }));
	};

	return (
		<div className="user-management">
			<ActiveLedgerFilters
				handleResetFilters={handleResetFilters}
				handleDateChange={onChangeDate}
				handleChangeAsset={handleAssetChange}
				currencyOptions={currencyFilterOptions}
				handleTypeChange={handleTypeChange}
				typeOptions={typeOptions}
				searchArray={FiltersSearchArray}
				handleSearch={handleSearch}
				resetValue={resetSearchValue}
				reset={reset}
			/>

			<div className="table-block">
				<div className="table-wrapper table-wrapper--with-y-scroll">
					<div className="table table--type2 table--ledger">
						<div className="table-header">
							<LedgerTableHeader />
						</div>
						<div className="table-body">
							{!ledgerLoading && (
								<>
									{ledgerTransactions?.data.length ? (
										ledgerTransactions?.data.map((item: ILedger) => (
											<LedgerTableRow key={uuidv4()} data={item} />
										))
									) : (
										<TableBodyNoData />
									)}
								</>
							)}
							{ledgerLoading ? <Loading /> : null}
						</div>
					</div>
				</div>
				{!!ledgerTransactions?.data.length && (
					<div className="table-footer">
						<div className="pagination-block pagination-block--full">
							{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
							{/* @ts-ignore */}
							<PerPageSelect onChange={handleSetPerPage} defaultPerPage={perPage} />
							<ReactPaginate
								breakLabel="..."
								pageCount={pageCount}
								onPageChange={handlePageClick}
								forcePage={currentPage - 1}
								className="pagination"
								activeClassName="active"
								previousClassName="pagination__arrow pagination__arrow--prev"
								nextClassName="pagination__arrow pagination__arrow--next"
								previousLabel=""
								nextLabel=""
							/>
							<div className="button-wrap">
								{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
								<button
									type="button"
									className="btn btn--csv"
									onClick={() => handleDownloadFile('csv')}
								/>
								{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
								<button
									type="button"
									className="btn btn--xlsx"
									onClick={() => handleDownloadFile('xls')}
								/>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default LedgerTable;

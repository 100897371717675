/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMultiAccountsStore, QueryParams } from './types';
import {
	AccountMember,
	AccountRequest,
	ApiResponse,
	GetAccountMembersResponse,
	InviteRequest,
	User,
} from '../../../services/api/multiAccounts/types';
// Начальное состояние
export const initialState: IMultiAccountsStore = {
	getAccounts: {
		isLoading: false,
		data: null,
		error: null,
		params: null,
	},
	approveInviteRequest: {
		isLoading: false,
		data: null,
		error: null,
	},
	pendingInviteRequest: {
		isLoading: false,
		data: null,
		error: null,
	},
	rejectInviteRequest: {
		isLoading: false,
		data: null,
		error: null,
	},
	getAccountMembers: {
		isLoading: false,
		data: null,
		error: null,
	},
	getAccountRequests: {
		isLoading: false,
		data: null,
		error: null,
	},
};

const multiAccountsSlice = createSlice({
	name: '@@multiAccounts',
	initialState,
	reducers: {
		// Сброс всех стейтов к начальному
		multiAccountsInitState: () => initialState,

		// ---- GET ACCOUNTS ----
		getAccountsTrigger: (
			state,
			_action: PayloadAction<{
				userId: number;
				queryParams: { per_page: number; current_page: number };
			}>,
		) => {
			state.getAccounts.params = _action.payload;
		},
		getAccountsRequest: (state) => {
			state.getAccounts.isLoading = true;
			state.getAccounts.error = null;
		},
		getAccountsSuccess: (state, action: PayloadAction<ApiResponse>) => {
			state.getAccounts.isLoading = false;
			state.getAccounts.data = action.payload;
			state.getAccounts.error = null;
		},
		getAccountsFailure: (state, action: PayloadAction<string>) => {
			state.getAccounts.isLoading = false;
			state.getAccounts.error = action.payload;
		},

		// ---- APPROVE INVITE REQUEST ----
		approveInviteRequestTrigger: (state, _action: PayloadAction<{ requestId: number }>) => {},
		approveInviteRequestRequest: (state) => {
			state.approveInviteRequest.isLoading = true;
			state.approveInviteRequest.error = null;
		},
		approveInviteRequestSuccess: (state, action: PayloadAction<any>) => {
			state.approveInviteRequest.isLoading = false;
			state.approveInviteRequest.data = action.payload;
			state.approveInviteRequest.error = null;
			// if (state.getAccountRequests.data) {
			// 	state.getAccountRequests.data.data = state.getAccountRequests.data?.data.map(
			// 		(request: any) => (request.id === action.payload.id ? action.payload : request),
			// 	);
			// }
		},
		approveInviteRequestFailure: (state, action: PayloadAction<string>) => {
			state.approveInviteRequest.isLoading = false;
			state.approveInviteRequest.error = action.payload;
		},

		// ---- PENDING INVITE REQUEST ----
		pendingInviteRequestTrigger: (state, _action: PayloadAction<{ requestId: number }>) => {},
		pendingInviteRequestRequest: (state) => {
			state.pendingInviteRequest.isLoading = true;
			state.pendingInviteRequest.error = null;
		},
		pendingInviteRequestSuccess: (state, action: PayloadAction<InviteRequest>) => {
			state.pendingInviteRequest.isLoading = false;
			state.pendingInviteRequest.data = action.payload; // Можно хранить, если нужно
			state.pendingInviteRequest.error = null;

			if (state.getAccountRequests.data) {
				debugger;
				state.getAccountRequests.data.data = state.getAccountRequests.data?.data.map(
					(request: any) => (request.id === action.payload.id ? action.payload : request),
				);
			}
		},
		pendingInviteRequestFailure: (state, action: PayloadAction<string>) => {
			state.pendingInviteRequest.isLoading = false;
			state.pendingInviteRequest.error = action.payload;
		},
		// ---- REJECT INVITE REQUEST ----
		rejectInviteRequestTrigger: (state, _action: PayloadAction<{ requestId: number }>) => {},
		rejectInviteRequestRequest: (state) => {
			state.rejectInviteRequest.isLoading = true;
			state.rejectInviteRequest.error = null;
		},
		rejectInviteRequestSuccess: (state, action: PayloadAction<InviteRequest>) => {
			state.rejectInviteRequest.isLoading = false;
			state.rejectInviteRequest.data = action.payload;
			state.rejectInviteRequest.error = null;
			debugger;
			if (state.getAccountRequests.data) {
				state.getAccountRequests.data.data = state.getAccountRequests.data?.data.map(
					(request: any) => (request.id === action.payload.id ? action.payload : request),
				);
			}
		},
		rejectInviteRequestFailure: (state, action: PayloadAction<string>) => {
			state.rejectInviteRequest.isLoading = false;
			state.rejectInviteRequest.error = action.payload;
		},

		// ---- GET ACCOUNT MEMBERS ----
		getAccountMembersTrigger: (
			state,
			_action: PayloadAction<{ accountId: number; queryParams?: QueryParams }>,
		) => {
			// Тут только для саги
		},
		getAccountMembersRequest: (state) => {
			state.getAccountMembers.isLoading = true;
			state.getAccountMembers.error = null;
		},
		getAccountMembersSuccess: (state, action: PayloadAction<User[]>) => {
			state.getAccountMembers.isLoading = false;
			state.getAccountMembers.data = action.payload;
			state.getAccountMembers.error = null;
		},
		getAccountMembersFailure: (state, action: PayloadAction<string>) => {
			state.getAccountMembers.isLoading = false;
			state.getAccountMembers.error = action.payload;
		},

		// ---- GET ACCOUNT REQUESTS ----
		getAccountRequestsTrigger: (
			state,
			_action: PayloadAction<{ accountId: number; queryParams?: QueryParams }>,
		) => {},
		getAccountRequestsRequest: (state) => {
			state.getAccountRequests.isLoading = true;
			state.getAccountRequests.error = null;
		},
		getAccountRequestsSuccess: (state, action: PayloadAction<AccountRequest[]>) => {
			state.getAccountRequests.isLoading = false;
			state.getAccountRequests.data = action.payload;
			state.getAccountRequests.error = null;
		},
		getAccountRequestsFailure: (state, action: PayloadAction<string>) => {
			state.getAccountRequests.isLoading = false;
			state.getAccountRequests.error = action.payload;
		},
	},
});

export const {
	multiAccountsInitState,

	getAccountsTrigger,
	getAccountsRequest,
	getAccountsSuccess,
	getAccountsFailure,

	approveInviteRequestTrigger,
	approveInviteRequestRequest,
	approveInviteRequestSuccess,
	approveInviteRequestFailure,

	pendingInviteRequestTrigger,
	pendingInviteRequestRequest,
	pendingInviteRequestSuccess,
	pendingInviteRequestFailure,

	rejectInviteRequestTrigger,
	rejectInviteRequestRequest,
	rejectInviteRequestSuccess,
	rejectInviteRequestFailure,

	getAccountMembersTrigger,
	getAccountMembersRequest,
	getAccountMembersSuccess,
	getAccountMembersFailure,

	getAccountRequestsTrigger,
	getAccountRequestsRequest,
	getAccountRequestsSuccess,
	getAccountRequestsFailure,
} = multiAccountsSlice.actions;

export default multiAccountsSlice.reducer;

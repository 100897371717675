import { useLayoutEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import DepositCryptoDetails from './DepositCryptoDetails/DepositCryptoDetails';
import { IStateLocation } from './types';
import WithdrawalCryptoDetails from './WithdrawalCryptoDetails/WithrawalCryptoDetails';
import DepositCryptoPendingDetails from './DepositCryptoDetails/DepositCryptoPendingDetails';
import DepositFiatDetails from './DepositFiatDetails/DepositFiatDetails';
import WithdrawalFiatDetails from './WithdrawalFiatDetails/WithdrawalFiatDetails';
import TransfersFiatDetails from './TransfersFiatDetails/TransfersFiatDetails';
import TransfersFiatDetailsUser from './TransfersFiatDetailsUser/TransfersFiatDetailsUser';
import TransfersCryptoDetails from './TransfersCryptoDetails/TransfersCryptoDetails';
import TradeDetails from './TradeDetails/TradeDetails';
import { setDetailedViewData } from '../../redux/reducers/detailedView/reducer';
import { getDetailedViewData } from '../../redux/reducers/detailedView/selectors';

const DetailedView = () => {
	const { state, pathname } = useLocation<IStateLocation>();
	const detailedViewData = useSelector(getDetailedViewData);
	const dispatch = useDispatch();
	const history = useHistory();

	const backHandler = () => {
		dispatch(setDetailedViewData(null));
		const savedPosition = localStorage.getItem('scrollPosition');
		if (savedPosition) {
			const position = parseInt(savedPosition, 10);
			// Push the scroll action to the end of the event loop
			setTimeout(() => {
				window.scrollTo(0, position);
			}, 0);
		}
	};

	useLayoutEffect(() => {
		return () => {
			dispatch(setDetailedViewData(null));
			localStorage.removeItem('scrollPosition');
		};
	}, [dispatch]);

	if (
		detailedViewData?.from === 'fromDepositCryptoReports' ||
		detailedViewData?.from === 'fromDepositCryptoUser' ||
		detailedViewData?.from === 'fromLedgerDepositCrypto'
	) {
		return (
			<>
				<div className="wallet-operations-header-wrap">
					<div className="wallet-operations-header">
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<button
							type="button"
							className="wallet-operations-header__back"
							onClick={backHandler}
						/>
					</div>
				</div>
				<div className="form form--type3">
					<div className="form-body">
						{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
						{/* @ts-ignore */}
						<DepositCryptoDetails txId={detailedViewData.txId} userId={detailedViewData.userId} />
					</div>
				</div>
			</>
		);
	}

	if (detailedViewData?.from === 'fromPendingDepositCrypto') {
		return (
			<>
				<div className="wallet-operations-header-wrap">
					<div className="wallet-operations-header">
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<button
							type="button"
							className="wallet-operations-header__back"
							onClick={backHandler}
						/>
					</div>
				</div>
				<div className="form form--type3">
					<div className="form-body">
						<DepositCryptoPendingDetails
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							txId={detailedViewData.txId}
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							userId={detailedViewData.userId}
						/>
					</div>
				</div>
			</>
		);
	}

	if (
		detailedViewData?.from === 'fromWithdrawalCryptoReports' ||
		detailedViewData?.from === 'fromWithdrawalCryptoUser' ||
		detailedViewData?.from === 'fromPendingWithdrawalCrypto' ||
		detailedViewData?.from === 'fromLedgerWithdrawalCrypto'
	) {
		return (
			<>
				<div className="wallet-operations-header-wrap">
					<div className="wallet-operations-header">
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<button
							type="button"
							className="wallet-operations-header__back"
							onClick={backHandler}
						/>
					</div>
				</div>
				<div className="form form--type3">
					<div className="form-body">
						<WithdrawalCryptoDetails
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							txId={detailedViewData.txId}
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							userId={detailedViewData.userId}
						/>
					</div>
				</div>
			</>
		);
	}

	if (
		detailedViewData?.from === 'fromDepositFiatReports' ||
		detailedViewData?.from === 'fromDepositFiatUser' ||
		detailedViewData?.from === 'fromLedgerDepositFiat'
	) {
		return (
			<>
				<div className="wallet-operations-header-wrap">
					<div className="wallet-operations-header">
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<button
							type="button"
							className="wallet-operations-header__back"
							onClick={backHandler}
						/>
					</div>
				</div>
				<div className="form form--type3">
					<div className="form-body">
						{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
						{/* @ts-ignore */}
						<DepositFiatDetails txId={detailedViewData.txId} />
					</div>
				</div>
			</>
		);
	}

	if (detailedViewData?.from === 'fromPendingDepositFiat') {
		return (
			<>
				<div className="wallet-operations-header-wrap">
					<div className="wallet-operations-header">
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<button
							type="button"
							className="wallet-operations-header__back"
							onClick={backHandler}
						/>
					</div>
				</div>
				<div className="form form--type3">
					<div className="form-body">
						{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
						{/* @ts-ignore */}
						<DepositFiatDetails txId={detailedViewData.txId} />
					</div>
				</div>
			</>
		);
	}

	if (
		detailedViewData?.from === 'fromWithdrawalFiatReports' ||
		detailedViewData?.from === 'fromWithdrawalFiatUser' ||
		detailedViewData?.from === 'fromPendingWithdrawalFiat' ||
		detailedViewData?.from === 'fromLedgerWithdrawalFiat'
	) {
		return (
			<>
				<div className="wallet-operations-header-wrap">
					<div className="wallet-operations-header">
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<button
							type="button"
							className="wallet-operations-header__back"
							onClick={backHandler}
						/>
					</div>
				</div>
				<div className="form form--type3">
					<div className="form-body">
						{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
						{/* @ts-ignore */}
						<WithdrawalFiatDetails txId={detailedViewData.txId} userId={detailedViewData.userId} />
					</div>
				</div>
			</>
		);
	}

	if (detailedViewData?.from === 'fromTransfersFiat') {
		return (
			<>
				<div className="wallet-operations-header-wrap">
					<div className="wallet-operations-header">
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<button
							type="button"
							className="wallet-operations-header__back"
							onClick={backHandler}
						/>
					</div>
				</div>
				<div className="form form--type3">
					<div className="form-body">
						{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
						{/* @ts-ignore */}
						<TransfersFiatDetails txId={detailedViewData.txId} userId={detailedViewData.userId} />
					</div>
				</div>
			</>
		);
	}

	if (
		detailedViewData?.from === 'fromTransfersCrypto' ||
		detailedViewData?.from === 'fromTransfersCryptoUser'
	) {
		return (
			<>
				<div className="wallet-operations-header-wrap">
					<div className="wallet-operations-header">
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<button
							type="button"
							className="wallet-operations-header__back"
							onClick={backHandler}
						/>
					</div>
				</div>
				<div className="form form--type3">
					<div className="form-body">
						{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
						{/* @ts-ignore */}
						<TransfersCryptoDetails txId={detailedViewData.txId} userId={detailedViewData.userId} />
					</div>
				</div>
			</>
		);
	}

	if (detailedViewData?.from === 'fromTransfersFiatUser') {
		return (
			<>
				<div className="wallet-operations-header-wrap">
					<div className="wallet-operations-header">
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<button
							type="button"
							className="wallet-operations-header__back"
							onClick={backHandler}
						/>
					</div>
				</div>
				<div className="form form--type3">
					<div className="form-body">
						<TransfersFiatDetailsUser
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							txId={detailedViewData.txId}
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							userId={detailedViewData.userId}
						/>
					</div>
				</div>
			</>
		);
	}

	if (
		detailedViewData?.from === 'fromTradeReports' ||
		detailedViewData?.from === 'fromTradeUser' ||
		detailedViewData?.from === 'fromLedgerTrade'
	) {
		return (
			<>
				<div className="wallet-operations-header-wrap">
					<div className="wallet-operations-header">
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<button
							type="button"
							className="wallet-operations-header__back"
							onClick={backHandler}
						/>
					</div>
				</div>
				<div className="form form--type3">
					<div className="form-body">
						{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
						{/* @ts-ignore */}
						<TradeDetails txId={detailedViewData.txId} userId={detailedViewData.userId} />
					</div>
				</div>
			</>
		);
	}

	return <div>No Data</div>;
};

export default DetailedView;

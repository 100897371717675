import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import AccountNotesEditor from './AccountNotesEditor';
import { getOneUser } from '../../../../redux/reducers/users/selectors';
import { getPlainTextFromEditorState } from '../../../../utils/getPlainText';
import { ICreateUserNoteRequest } from '../../../../redux/reducers/users/types';
import { createUserNoteRequest } from '../../../../redux/reducers/users/reducer';
import AccountNotesTable from './AccountNotesTable';

const AccountNotes = () => {
	const dispatch = useDispatch();
	const user = useSelector(getOneUser);
	const [hasError, setHasError] = useState(false);
	const [errorLong, setErrorLong] = useState(false);
	const [errorEmpty, setErrorEmpty] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [noteValue, setNoteValue] = useState('');
	const [reset, setReset] = useState(false);

	const clearErrors = () => {
		setHasError(false);
		setErrorEmpty(false);
		setErrorLong(false);
	};

	const addNoteHandler = () => {
		if (
			getPlainTextFromEditorState(noteValue) &&
			getPlainTextFromEditorState(noteValue).length > 2000
		) {
			setHasError(true);
			setErrorLong(true);
			return;
		}

		if (!getPlainTextFromEditorState(noteValue)) {
			setHasError(true);
			setErrorEmpty(true);
			return;
		}

		clearErrors();

		if (user) {
			setIsLoading(true);
			const params: ICreateUserNoteRequest = {
				apiParams: {
					user_id: user?.id,
					note: noteValue,
				},
				onFinally(err) {
					setIsLoading(false);
					setReset(!reset);
				},
			};
			dispatch(createUserNoteRequest(params));
		}
	};

	useEffect(() => {
		if (
			getPlainTextFromEditorState(noteValue) &&
			getPlainTextFromEditorState(noteValue).length > 2000
		) {
			setHasError(true);
			setErrorLong(true);
		}

		if (
			getPlainTextFromEditorState(noteValue) &&
			getPlainTextFromEditorState(noteValue).length <= 2000
		) {
			clearErrors();
		}
	}, [noteValue]);

	return (
		<div className="account-notes">
			<div className="account-notes__container">
				<AccountNotesEditor
					hasError={hasError}
					errorEmpty={errorEmpty}
					errorLong={errorLong}
					setValue={setNoteValue}
					reset={reset}
				/>
				<button
					type="button"
					className="btn btn-primary mt-10"
					onClick={addNoteHandler}
					disabled={isLoading}
				>
					Add note
				</button>
			</div>

			<AccountNotesTable refetch={reset} />
		</div>
	);
};
export default AccountNotes;

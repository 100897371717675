import { http } from 'services/http';
import { endpoint } from 'services/endpoint';
import { IMultiAccountsApi } from './types';

export const multiAccounts: IMultiAccountsApi = {
	getAccounts: (userId: number, queryParams: { per_page: number; current_page: number }) => {
		return http
			.get(endpoint.multiAccounts.GET_ACCOUNTS(userId), { params: queryParams })
			.then(({ data }: any) => data);
	},
	approveInviteRequest: ({ requestId, ...body }) =>
		http
			.post(endpoint.multiAccounts.APPROVE_INVITE_REQUEST(requestId), body)
			.then(({ data }) => data),
	pendingInviteRequest: (payload: any) =>
		http
			.post(endpoint.multiAccounts.PENDING_INVITE_REQUEST(payload.requestId))
			.then(({ data }: any) => data),
	rejectInviteRequest: (payload: any) =>
		http
			.post(endpoint.multiAccounts.REJECT_INVITE_REQUEST(payload.requestId))
			.then(({ data }: any) => data),
	getAccountMembers: (accountId: number, queryParams: any) => {
		return http
			.get(endpoint.multiAccounts.GET_ACCOUNT_MEMBERS(accountId), { params: queryParams })
			.then(({ data }: any) => data);
	},
	getAccountRequests: (accountId: number, queryParams: any) =>
		http
			.get(endpoint.multiAccounts.GET_ACCOUNT_REQUESTS(accountId), { params: queryParams })
			.then(({ data }: any) => data),
};

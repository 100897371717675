// eslint-disable import/namespace
/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import IconSvg from 'ui/Svg/IconSvg';
import {
	getBankAccountsWithSelectFilters,
	getRequestsWithSelectFilters,
} from 'redux/reducers/transactionsFilters/selectors';
import { addFilter, deleteFilter, resetFilters } from 'redux/reducers/transactionsFilters/reducer';
import {
	EFiltersType,
	ERoleType,
	ETransactionsFilters,
} from 'redux/reducers/transactionsFilters/types';
import { convertFilterObjectToParamsObject } from 'services/utils/convertFilterObjectToParamsObject';
import { IApiGetTransactionParams } from 'services/api/transactions/types';
import TableBodyNoData from 'ui/TableBodyNoData/TableBodyNoData';
import PerPageSelect from 'ui/PerPageSelect/PerPageSelect';
import SearchWithSelect, { ISearchSelectArr } from 'ui/SearchWithSelect/SearchWithSelect';
import ActiveFilters from 'ui/ActiveFilters/ActiveFilters';
import { getDepositAccounts, getUserDepositAccounts } from 'redux/reducers/walletBalance/selectors';
import { activateResetActiveBankAccounts } from 'redux/reducers/bankAccounts/reducer';
import {
	getDepositAccountRequest,
	getUserDepositAccountRequest,
} from 'redux/reducers/walletBalance/reducer';
import { currencyDataRequest } from 'redux/reducers/currency/reducer';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { de } from 'date-fns/locale';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import {
	getAccountMembersDataSelector,
	getAccountRequestsDataSelector,
	getAccountRequestsLoadingSelector,
} from '../../../../../redux/reducers/multiAccounts/selectors';
import {
	getAccountMembersTrigger,
	getAccountRequestsTrigger,
} from '../../../../../redux/reducers/multiAccounts/reducer';
import FilterSelect from '../../../../../ui/Formik/Select/FilterSelect';
import { INIT_FILTER_OPTIONS } from '../../../../../redux/reducers/transactionsFilters/constants';
import { THistoryFiltersTypeOption } from '../../../../AccountDetails/TransactionHistoryTab/CryptoHistory/types';
import { THistoryFiltersOption } from '../../../../AccountDetails/DepositHistoryTab/CryptoHistory/types';
import Loading from '../../../../../layouts-elements/Loading/Loading';
import MultiSelect from '../../../../../ui/MultiSelect/MultiSelect';

const FiltersSearchRoleArray = [
	{ id: 1, name: 'All', value: ERoleType.ALL, active: true },
	{ id: 2, name: 'Viewer', value: ERoleType.VIEWER, active: true },
	{ id: 3, name: 'Initiator', value: ERoleType.INITIATOR, active: true },
	{ id: 4, name: 'Trader', value: ERoleType.TRADER, active: true },
	{ id: 5, name: 'Approver', value: ERoleType.APPROVER, active: true },
];
const FiltersSearchArray = [
	{ name: 'Full Name', value: ETransactionsFilters.FULL_NAME },
	{ name: 'User ID', value: ETransactionsFilters.USER_ID },
	{ name: 'Oneify ID', value: ETransactionsFilters.ONEIFY_ID },
	{ name: 'Email', value: ETransactionsFilters.EMAIL },
	{ name: 'Company Name', value: ETransactionsFilters.COMPANY_NAME },
];
export interface IBankAccountsWithSelectProps {
	userId?: number;
	accountId?: number;
	resetStates?: boolean;
	withoutAction?: boolean;
	editMode?: boolean;
}

const NewRequests: FC<IBankAccountsWithSelectProps> = ({
	userId,
	accountId,
	resetStates,
	withoutAction,
	editMode,
}) => {
	const dispatch = useDispatch();
	const bankAccounts = useSelector(userId ? getUserDepositAccounts : getDepositAccounts);
	const activeFilters = useSelector(getRequestsWithSelectFilters);
	const activeFiltersRole = useSelector(getRequestsWithSelectFilters);
	const loading = useSelector(getAccountRequestsLoadingSelector);
	const [currentPage, setCurrentPage] = useState(bankAccounts?.current_page || 1);
	const [resetSearchValue, setResetSearchValue] = useState(false);
	const [perPage, setPerPage] = useState<number | undefined>(10);
	const handleSetPerPage = (value: number | undefined) => {
		setPerPage(value || bankAccounts?.total);
		setCurrentPage(1);
	};

	const handlePageClick = ({ selected }: { selected: number }) => {
		setCurrentPage(selected + 1);
	};
	const [selectedRoles, setSelectedRoles] = useState<ERoleType[]>([]);
	const pageCount = bankAccounts?.last_page ? bankAccounts.last_page : 1;

	useLayoutEffect(() => {
		if (currentPage > pageCount) {
			setCurrentPage(1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bankAccounts?.last_page]);

	useLayoutEffect(() => {
		dispatch(currencyDataRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useLayoutEffect(() => {
		setPerPage(10);
		setCurrentPage(1);
		setResetSearchValue(false);
		dispatch(resetFilters(EFiltersType.REQUEST));
		dispatch(resetFilters(EFiltersType.ROLE));
		dispatch(activateResetActiveBankAccounts());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userId, resetStates]);

	useLayoutEffect(() => {
		const filterParams = convertFilterObjectToParamsObject(activeFilters);

		const params: IApiGetTransactionParams = {
			per_page: perPage,
			current_page: currentPage,
			...filterParams,
		};

		!userId && dispatch(getDepositAccountRequest(params));
		!!userId && dispatch(getUserDepositAccountRequest({ userId, params }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeFilters, perPage, currentPage, userId]);

	const handleResetSearchValue = () => {
		setResetSearchValue(!resetSearchValue);
	};

	const handleDeleteFilter = (filterType: ETransactionsFilters) => {
		handleResetSearchValue();
		dispatch(deleteFilter({ type: filterType, filterType: EFiltersType.REQUEST }));
		dispatch(deleteFilter({ type: filterType, filterType: EFiltersType.ROLE }));
	};

	const handleResetFilters = () => {
		handleResetSearchValue();
		dispatch(resetFilters(EFiltersType.REQUEST));
		dispatch(resetFilters(EFiltersType.ROLE));
	};

	const handleSearch = (searchField: ISearchSelectArr, currentSearchValue: string) => {
		if (currentSearchValue === '') {
			dispatch(
				deleteFilter({
					type: searchField.value as ETransactionsFilters,
					filterType: EFiltersType.REQUEST,
				}),
			);
		} else {
			dispatch(
				addFilter({
					type: searchField.value as ETransactionsFilters,
					value: currentSearchValue,
					name: `${searchField.name}: ${currentSearchValue}`,
					filterType: EFiltersType.REQUEST,
				}),
			);
		}
	};

	const history = useHistory();
	const addPopUp = () => {
		// dispatch(popUpOpen('AddNewMember'));
	};

	const [reset, setReset] = useState(false);
	const accountRequestsData = useSelector(getAccountRequestsDataSelector);
	const [role, setRole] = useState('All');
	const handleRoleChange = ({ name, value }: THistoryFiltersOption) => {
		if (value) {
			setReset(false);
			setRole(value);
		}
	};
	useEffect(() => {
		if (accountId) {
			const filter = activeFilters?.find((f) => f.filterType === 'requestsFilters');

			const getFilteredRoles = (roles: string[]): string[] => {
				const filtered = roles.filter((r) => r !== 'all');
				return filtered.length ? filtered : ['-'];
			};

			const normalizedRoles = Array.isArray(role) ? role : typeof role === 'string' ? [role] : [];
			const queryParams: any = {
				search_field: filter?.type,
				search_value: filter?.value,
				per_page: 999,
				current_page: 1,
				role: getFilteredRoles(normalizedRoles),
			};
			dispatch(getAccountRequestsTrigger({ accountId, queryParams }));
		}
	}, [role, activeFilters, dispatch, accountId, perPage, currentPage]);
	const handleSearchRole = (value: any) => {
		setRole(value);
	};
	const handleRolesChange = (roles: ERoleType[]) => {
		handleSearchRole(roles);
		setSelectedRoles(roles);
	};

	return (
		<>
			<div className="associated-accounts_main newRequests">
				<div className="filters__search-block filters__search-block--bank-accounts-with-select">
					<SearchWithSelect
						resetValue={resetSearchValue}
						selectArr={FiltersSearchArray}
						handleSearch={handleSearch}
						isButton
						isButtonFuc={addPopUp}
						handleReset={handleResetFilters}
					/>
					<MultiSelect onChange={handleRolesChange} />
				</div>
				<div className="table-block">
					<div className="table-wrapper ">
						<div className="table table--associated-accounts_main__newRequests">
							<div className="table-header">
								<TableHeader />
							</div>
							<div className="table-body">
								{loading ? (
									<Loading />
								) : accountRequestsData?.data?.length ? (
									accountRequestsData.data.map((item: any) => (
										<TableBody key={item.id} item={item} />
									))
								) : (
									<TableBodyNoData />
								)}
							</div>
						</div>
					</div>
				</div>

				{/* {!!bankAccounts?.data.length && ( */}
				{/*	<div className="table-footer table-footer--bank-accounts-with-select"> */}
				{/*		<div className="pagination-block pagination-block--full"> */}
				{/*			<PerPageSelect onChange={handleSetPerPage} /> */}
				{/*			{pageCount > 1 && ( */}
				{/*				<ReactPaginate */}
				{/*					breakLabel="..." */}
				{/*					pageCount={1} */}
				{/*					// onPageChange={handlePageClick} */}
				{/*					forcePage={currentPage - 1} */}
				{/*					className="pagination" */}
				{/*					activeClassName="active" */}
				{/*					previousClassName="arrow arrow--prev" */}
				{/*					nextClassName="arrow arrow--next" */}
				{/*					previousLabel={<IconSvg name="keyboard-arrow-left" w="10" h="16" />} */}
				{/*					nextLabel={<IconSvg name="keyboard-arrow-right" w="10" h="16" />} */}
				{/*				/> */}
				{/*			)} */}
				{/*		</div> */}
				{/*	</div> */}
				{/* )} */}
			</div>
		</>
	);
};

export default NewRequests;

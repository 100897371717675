/* eslint-disable @typescript-eslint/no-explicit-any */
import { referrals } from './referrals';
import { users } from './users';
import { IApi } from './types';
import { auth } from './auth';
import { settings } from './settings';
import { twoFa } from './twoFa';
import { transactions } from './transactions';
import { confirmation } from './confirmation';
import { walletAddresses } from './walletAddresses';
import { bankAccounts } from './bankAccounts';
import { fee } from './fee';
import { currency } from './currency';
import { walletBalances } from './walletBalances';
import { dashboard } from './dashboard';
import { admins } from './admins';
import { download } from './download';
import { detailedView } from './detailedView';
import { balances } from './balances';
import { messages } from './messages';
import { multiAccounts } from './multiAccounts';

const api: IApi = {
	auth,
	settings,
	twoFa,
	transactions,
	users,
	confirmation,
	walletAddresses,
	bankAccounts,
	referrals,
	fee,
	currency,
	walletBalances,
	dashboard,
	admins,
	download,
	detailedView,
	balances,
	messages,
	multiAccounts,
};

export { api };

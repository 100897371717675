// eslint-disable import/namespace
/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMembersWithSelectFilters } from 'redux/reducers/transactionsFilters/selectors';
import { addFilter, deleteFilter, resetFilters } from 'redux/reducers/transactionsFilters/reducer';
import {
	EFiltersType,
	ERoleType,
	ETransactionsFilters,
} from 'redux/reducers/transactionsFilters/types';
import { convertFilterObjectToParamsObject } from 'services/utils/convertFilterObjectToParamsObject';
import { IApiGetTransactionParams } from 'services/api/transactions/types';
import TableBodyNoData from 'ui/TableBodyNoData/TableBodyNoData';
import PerPageSelect from 'ui/PerPageSelect/PerPageSelect';
import SearchWithSelect, { ISearchSelectArr } from 'ui/SearchWithSelect/SearchWithSelect';
import ActiveFilters from 'ui/ActiveFilters/ActiveFilters';
import { getDepositAccounts, getUserDepositAccounts } from 'redux/reducers/walletBalance/selectors';
import { activateResetActiveBankAccounts } from 'redux/reducers/bankAccounts/reducer';
import {
	getDepositAccountRequest,
	getUserDepositAccountRequest,
} from 'redux/reducers/walletBalance/reducer';
import { currencyDataRequest } from 'redux/reducers/currency/reducer';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import {
	getAccountMembersTrigger,
	getAccountsTrigger,
} from '../../../../../redux/reducers/multiAccounts/reducer';
import {
	getAccountMembersDataSelector,
	getAccountMembersLoadingSelector,
} from '../../../../../redux/reducers/multiAccounts/selectors';
import {
	AccountMember,
	GetAccountMembersResponse,
	User,
} from '../../../../../services/api/multiAccounts/types';
import { QueryParams } from '../../../../../redux/reducers/multiAccounts/types';
import Loading from '../../../../../layouts-elements/Loading/Loading';
import MultiSelect from '../../../../../ui/MultiSelect/MultiSelect';

const FiltersSearchArray = [
	{ name: 'Full Name', value: ETransactionsFilters.FULL_NAME },
	{ name: 'User ID', value: ETransactionsFilters.USER_ID },
	{ name: 'Oneify ID', value: ETransactionsFilters.ONEIFY_ID },
	{ name: 'Email', value: ETransactionsFilters.EMAIL },
	{ name: 'Company Name', value: ETransactionsFilters.COMPANY_NAME },
];

export interface IBankAccountsWithSelectProps {
	userId?: number;
	accountId?: number;
	resetStates?: boolean;
	withoutAction?: boolean;
	editMode?: boolean;
}

const Members: FC<IBankAccountsWithSelectProps> = ({
	userId,
	accountId,
	resetStates,
	withoutAction,
	editMode,
}) => {
	const dispatch = useDispatch();
	const bankAccounts = useSelector(userId ? getUserDepositAccounts : getDepositAccounts);
	const activeFilters = useSelector(getMembersWithSelectFilters);
	const [currentPage, setCurrentPage] = useState(bankAccounts?.current_page || 1);
	const [resetSearchValue, setResetSearchValue] = useState(false);
	const [perPage, setPerPage] = useState<number | undefined>(10);
	const handleSetPerPage = (value: number | undefined) => {
		setPerPage(value || bankAccounts?.total);
		setCurrentPage(1);
	};
	const [role, setRole] = useState('All');
	const handleSearchRole = (value: any) => {
		setRole(value);
	};

	const handlePageClick = ({ selected }: { selected: number }) => {
		setCurrentPage(selected + 1);
	};
	const loading = useSelector(getAccountMembersLoadingSelector);
	const pageCount = bankAccounts?.last_page ? bankAccounts.last_page : 1;

	useLayoutEffect(() => {
		if (currentPage > pageCount) {
			setCurrentPage(1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bankAccounts?.last_page]);

	useLayoutEffect(() => {
		dispatch(currencyDataRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useLayoutEffect(() => {
		setPerPage(10);
		setCurrentPage(1);
		setResetSearchValue(false);
		dispatch(resetFilters(EFiltersType.MEMBERS));
		dispatch(activateResetActiveBankAccounts());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userId, resetStates]);
	useLayoutEffect(() => {
		const filterParams = convertFilterObjectToParamsObject(activeFilters);

		const params: IApiGetTransactionParams = {
			per_page: perPage,
			current_page: currentPage,
			...filterParams,
		};

		!userId && dispatch(getDepositAccountRequest(params));
		!!userId && dispatch(getUserDepositAccountRequest({ userId, params }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeFilters, perPage, currentPage, userId]);

	const handleResetSearchValue = () => {
		setResetSearchValue(!resetSearchValue);
	};

	const handleDeleteFilter = (filterType: ETransactionsFilters) => {
		handleResetSearchValue();
		dispatch(deleteFilter({ type: filterType, filterType: EFiltersType.MEMBERS }));
	};

	const handleResetFilters = () => {
		handleResetSearchValue();
		dispatch(resetFilters(EFiltersType.MEMBERS));
	};
	const [currentFilterType, setCurrentFilterType] = useState<ETransactionsFilters>(
		ETransactionsFilters.FULL_NAME, // например, дефолтный
	);
	const handleSearch = (searchField: ISearchSelectArr, currentSearchValue: string) => {
		const newFilterType = searchField.value as ETransactionsFilters;

		// Удаляем предыдущее поле, только если это другое поле
		if (newFilterType !== currentFilterType) {
			dispatch(
				deleteFilter({
					type: currentFilterType,
					filterType: EFiltersType.MEMBERS,
				}),
			);
			setCurrentFilterType(newFilterType);
		}

		// Если поле ввода пустое — просто удаляем новый фильтр
		if (currentSearchValue === '') {
			dispatch(
				deleteFilter({
					type: newFilterType,
					filterType: EFiltersType.MEMBERS,
				}),
			);
			return;
		}

		// Добавляем (или обновляем) новый фильтр
		dispatch(
			addFilter({
				type: newFilterType,
				value: currentSearchValue,
				name: `${searchField.name}: ${currentSearchValue}`,
				filterType: EFiltersType.MEMBERS,
			}),
		);
	};
	const accountMembersData = useSelector(getAccountMembersDataSelector);

	useEffect(() => {
		if (accountId) {
			const filter = activeFilters?.find((f) => f.filterType === 'membersFilters');
			const getFilteredRoles = (roles: string[]): string[] => {
				const filtered = roles.filter((r) => r !== 'all');
				return filtered.length ? filtered : ['-'];
			};
			const normalizedRoles = Array.isArray(role) ? role : typeof role === 'string' ? [role] : [];

			const queryParams: any = {
				search_field: filter?.type,
				search_value: filter?.value,
				role:
					normalizedRoles.includes('All') || normalizedRoles.includes('all')
						? null
						: getFilteredRoles(normalizedRoles),
			};
			dispatch(getAccountMembersTrigger({ accountId, queryParams }));
		}
	}, [activeFilters, dispatch, accountId, perPage, currentPage, role]);

	const history = useHistory();
	const addPopUp = () => {
		// dispatch(popUpOpen('AddNewMember'));
	};
	const [selectedRoles, setSelectedRoles] = useState<ERoleType[]>([]);

	const handleRolesChange = (roles: ERoleType[]) => {
		handleSearchRole(roles);
		setSelectedRoles(roles);
	};
	return (
		<>
			<div className="associated-accounts_main">
				<div className="filters__search-block filters__search-block--bank-accounts-with-select">
					<SearchWithSelect
						resetValue={resetSearchValue}
						selectArr={FiltersSearchArray}
						handleSearch={handleSearch}
						isButton
						isButtonFuc={addPopUp}
						handleReset={handleResetFilters}
					/>
					<MultiSelect onChange={handleRolesChange} />
				</div>
				<div className="table-block">
					<div className="table-wrapper ">
						<div className="table table--associated-accounts_main">
							<div className="table-header">
								<TableHeader />
							</div>
							<div className="table-body">
								{loading ? (
									<Loading />
								) : accountMembersData?.data?.data?.length ? (
									accountMembersData.data.data.map((item: User) => <TableBody item={item} />)
								) : (
									<TableBodyNoData />
								)}
							</div>
						</div>
					</div>
				</div>
				{/* {!!bankAccounts?.data.length && ( */}
				{/*	<div className="table-footer table-footer--bank-accounts-with-select"> */}
				{/*		<div className="pagination-block pagination-block--full"> */}
				{/*			<PerPageSelect onChange={handleSetPerPage} /> */}
				{/*			{pageCount > 1 && ( */}
				{/*				<ReactPaginate */}
				{/*					breakLabel="..." */}
				{/*					pageCount={1} */}
				{/*					// onPageChange={handlePageClick} */}
				{/*					forcePage={currentPage - 1} */}
				{/*					className="pagination" */}
				{/*					activeClassName="active" */}
				{/*					previousClassName="arrow arrow--prev" */}
				{/*					nextClassName="arrow arrow--next" */}
				{/*					previousLabel={<IconSvg name="keyboard-arrow-left" w="10" h="16" />} */}
				{/*					nextLabel={<IconSvg name="keyboard-arrow-right" w="10" h="16" />} */}
				{/*				/> */}
				{/*			)} */}
				{/*		</div> */}
				{/*	</div> */}
				{/* )} */}
			</div>
		</>
	);
};

export default Members;

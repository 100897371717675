import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import AssociatedAccountsMain from '../../AssociatedAccountsMain';
import { Account } from '../../../../../services/api/multiAccounts/types';
import { formatRoles } from '../../../../../utils/formatRole';

interface IProps {
	item: Account;
	// eslint-disable-next-line react/require-default-props
	openedAccountId?: number | null; // ДОбавляем проп
	usersCount: number | null; // ДОбавляем проп
	// eslint-disable-next-line react/require-default-props
	setOpenedAccountId?: (id: number | null) => void; // ДОбавляем проп
}

const TableBody: FC<IProps> = ({ item, usersCount, ...props }) => {
	const history = useHistory();
	const [showAssociatedAccounts, setShowAssociatedAccounts] = useState(false);
	const isAdmin = item?.pivot?.is_account_admin === 1;

	useEffect(() => {
		const params = new URLSearchParams(history.location.search);

		if (showAssociatedAccounts) {
			params.set('members', 'true');
			params.set('id', String(item.id));
		} else {
			params.delete('members');
			params.delete('id');
		}

		history.replace({ search: params.toString() });
	}, [showAssociatedAccounts, history, item]);

	// 1. Следим, если родительский openedAccountId сменился
	//    И это НЕ наш id, то закрываем локальное состояние
	useEffect(() => {
		if (props.openedAccountId !== item.id) {
			setShowAssociatedAccounts(false);
		}
	}, [props.openedAccountId, item.id]);
	return (
		<>
			<div className={`tr ${showAssociatedAccounts ? 'no-border' : ''}`}>
				<div className="td">
					<div className="td-hidden-name">Account name</div>
					<div className="td__wrap">
						<div className="td__text">
							{item?.account_name}
							{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
							{/* @ts-ignore */}
							{isAdmin ? <span className="badge">{item.invite_requests_count}</span> : null}
						</div>
						<div className="td__more">{isAdmin ? 'Main' : null}</div>
					</div>
				</div>

				<div className="td">
					<div className="td-hidden-name">Roles</div>
					<div className="td__wrap">
						<div className="td__text">
							{item?.roles
								?.map((role) => {
									const parts = role.name.split('_');
									return parts
										.map((word, index) => {
											const lower = word.toLowerCase();
											return index === 0 ? lower.charAt(0).toUpperCase() + lower.slice(1) : lower;
										})
										.join(' ');
								})
								.join(', ')}
						</div>
					</div>
				</div>

				<div className="td td-right">
					<div className="td-hidden-name">Action</div>
					<div className="td__wrap">
						{true && (
							<div
								onClick={() => {
									setShowAssociatedAccounts((prev) => {
										const newVal = !prev;
										if (newVal) {
											props.setOpenedAccountId?.(item.id);
										} else {
											props.setOpenedAccountId?.(null);
										}
										return newVal;
									});
								}}
								className="btn--icon"
							>
								{!showAssociatedAccounts ? (
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
									>
										<path
											d="M15.5819 12.0019C15.5819 13.9819 13.9819 15.5819 12.0019 15.5819C10.0219 15.5819 8.42188 13.9819 8.42188 12.0019C8.42188 10.0219 10.0219 8.42188 12.0019 8.42188C13.9819 8.42188 15.5819 10.0219 15.5819 12.0019Z"
											stroke="#0071AB"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M11.9998 20.2688C15.5298 20.2688 18.8198 18.1887 21.1098 14.5887C22.0098 13.1787 22.0098 10.8087 21.1098 9.39875C18.8198 5.79875 15.5298 3.71875 11.9998 3.71875C8.46984 3.71875 5.17984 5.79875 2.88984 9.39875C1.98984 10.8087 1.98984 13.1787 2.88984 14.5887C5.17984 18.1887 8.46984 20.2688 11.9998 20.2688Z"
											stroke="#0071AB"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								) : (
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
									>
										<path
											d="M14.5319 9.47188L9.47188 14.5319C8.82188 13.8819 8.42188 12.9919 8.42188 12.0019C8.42188 10.0219 10.0219 8.42188 12.0019 8.42188C12.9919 8.42188 13.8819 8.82188 14.5319 9.47188Z"
											stroke="#0071AB"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M17.8198 5.77437C16.0698 4.45437 14.0698 3.73438 11.9998 3.73438C8.46984 3.73438 5.17984 5.81437 2.88984 9.41437C1.98984 10.8244 1.98984 13.1944 2.88984 14.6044C3.67984 15.8444 4.59984 16.9144 5.59984 17.7744"
											stroke="#0071AB"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M8.42188 19.5297C9.56187 20.0097 10.7719 20.2697 12.0019 20.2697C15.5319 20.2697 18.8219 18.1897 21.1119 14.5897C22.0119 13.1797 22.0119 10.8097 21.1119 9.39969C20.7819 8.87969 20.4219 8.38969 20.0519 7.92969"
											stroke="#0071AB"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M15.5114 12.7031C15.2514 14.1131 14.1014 15.2631 12.6914 15.5231"
											stroke="#0071AB"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M9.47 14.5312L2 22.0013"
											stroke="#0071AB"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M22.0013 2L14.5312 9.47"
											stroke="#0071AB"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								)}
							</div>
						)}
					</div>
				</div>
			</div>

			{showAssociatedAccounts && <AssociatedAccountsMain accountId={item.id} />}
		</>
	);
};

export default TableBody;

/* eslint-disable @typescript-eslint/naming-convention */
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getAdminRequiredEmailsAccountRequest,
	getAdminRequiredEmailsUserRequest,
	setUserData,
} from 'redux/reducers/users/reducer';
import {
	getUserCreateData,
	getRequiredEmailsUser,
	getRequiredEmailsAccount,
} from 'redux/reducers/users/selectors';

const EmailsTab = () => {
	const dispatch = useDispatch();
	const requiredEmailsUser = useSelector(getRequiredEmailsUser);
	const requiredEmailsAccount = useSelector(getRequiredEmailsAccount);
	const userData = useSelector(getUserCreateData);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		// dispatch(getAdminRequiredEmailsRequest({}));
		dispatch(getAdminRequiredEmailsUserRequest({}));
		dispatch(getAdminRequiredEmailsAccountRequest({}));
	}, [dispatch]);

	useEffect(() => {
		/* if (
			userData?.email_management &&
			Object.values(userData?.email_management).find((item) => item)
		) {
			setLoading(false);
			return;
		} */
		const newFee = {
			...userData?.email_management,
			user_registration_pending: requiredEmailsUser?.user_registration_pending,
			user_registration_verified: requiredEmailsUser?.user_registration_verified,
			user_login_notification: requiredEmailsUser?.user_login_notification,
			user_google2fa_connected: requiredEmailsUser?.user_google2fa_connected,
			user_google2fa_disconnected: requiredEmailsUser?.user_google2fa_disconnected,
			user_reset_password: requiredEmailsUser?.user_reset_password,
			user_reset_google2fa: requiredEmailsUser?.user_reset_google2fa,
			user_withdraw_pending: requiredEmailsAccount?.user_withdraw_pending,
			user_withdraw_completed: requiredEmailsAccount?.user_withdraw_completed,
			user_withdraw_rejected: requiredEmailsAccount?.user_withdraw_rejected,
			user_withdraw_cancelled: requiredEmailsAccount?.user_withdraw_cancelled,
			user_withdraw_failed: requiredEmailsAccount?.user_withdraw_failed,
			user_withdraw_processing: requiredEmailsAccount?.user_withdraw_processing,
			user_deposit_pending: requiredEmailsAccount?.user_deposit_pending,
			user_deposit_completed: requiredEmailsAccount?.user_deposit_completed,
			user_deposit_rejected: requiredEmailsAccount?.user_deposit_rejected,
			user_deposit_cancelled: requiredEmailsAccount?.user_deposit_cancelled,
			user_deposit_failed: requiredEmailsAccount?.user_deposit_failed,
			user_deposit_processing: requiredEmailsAccount?.user_deposit_processing,
			user_trade_confirmation: requiredEmailsAccount?.user_trade_confirmation,
			admin_registration_pending: requiredEmailsUser?.admin_registration_pending,
			admin_registration_verified: requiredEmailsUser?.admin_registration_verified,
			admin_login_notification: requiredEmailsUser?.admin_login_notification,
			admin_google2fa_connected: requiredEmailsUser?.admin_google2fa_connected,
			admin_google2fa_disconnected: requiredEmailsUser?.admin_google2fa_disconnected,
			admin_reset_password: requiredEmailsUser?.admin_reset_password,
			admin_reset_google2fa: requiredEmailsUser?.admin_reset_google2fa,
			admin_withdraw_pending: requiredEmailsAccount?.admin_withdraw_pending,
			admin_withdraw_completed: requiredEmailsAccount?.admin_withdraw_completed,
			admin_withdraw_rejected: requiredEmailsAccount?.admin_withdraw_rejected,
			admin_withdraw_cancelled: requiredEmailsAccount?.admin_withdraw_cancelled,
			admin_withdraw_failed: requiredEmailsAccount?.admin_withdraw_failed,
			admin_withdraw_processing: requiredEmailsAccount?.admin_withdraw_processing,
			admin_deposit_pending: requiredEmailsAccount?.admin_deposit_pending,
			admin_deposit_completed: requiredEmailsAccount?.admin_deposit_completed,
			admin_deposit_rejected: requiredEmailsAccount?.admin_deposit_rejected,
			admin_deposit_cancelled: requiredEmailsAccount?.admin_deposit_cancelled,
			admin_deposit_failed: requiredEmailsAccount?.admin_deposit_failed,
			admin_deposit_processing: requiredEmailsAccount?.admin_deposit_processing,
			admin_trade_confirmation: requiredEmailsAccount?.admin_trade_confirmation,
		};
		dispatch(setUserData({ ...userData, email_management: newFee }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, requiredEmailsUser, requiredEmailsAccount]);

	const handleTogglerChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, checked } = e.target;

		dispatch(
			setUserData({
				...userData,
				email_management: { ...userData?.email_management, [name]: checked },
			}),
		);
	};

	return (
		<>
			{!loading && (
				<div className="table-block">
					<div className="table-wrapper">
						<div className="table table--mob-header table--required-emails">
							<div className="table-header">
								<div className="tr">
									<div className="td">
										<div className="td-name">
											<p>Name</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p>Status</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p>User</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p>Admin</p>
										</div>
									</div>
								</div>
							</div>
							<div className="table-body" onChange={handleTogglerChange}>
								<div className="tr">
									<div className="td">
										<p className="td-hidden-name">Name</p>
										<div className="td__wrap td__wrap--column">
											<p className="font-weight-medium">Registration</p>
										</div>
									</div>
									<div className="td">
										<p className="td-hidden-name">Status</p>
										<div className="td__wrap td__wrap--column td__wrap--gap-4">
											<p>Pending</p>
											<p>Verified</p>
										</div>
									</div>

									<div className="td">
										<p className="td-hidden-name">User Name</p>
										<div className="flex flex--column flex--gap-4">
											<div className="switch">
												<label className="switch__label">
													<input
														type="checkbox"
														className="hidden"
														name="user_registration_pending"
														checked={!!userData?.email_management?.user_registration_pending}
													/>
													<span className="switch__toggler" />
												</label>
											</div>
											<div className="switch">
												<label className="switch__label">
													<input
														type="checkbox"
														className="hidden"
														name="user_registration_verified"
														checked={!!userData?.email_management?.user_registration_verified}
													/>
													<span className="switch__toggler" />
												</label>
											</div>
										</div>
									</div>

									<div className="td">
										<p className="td-hidden-name">Admin</p>
										<div className="flex flex--column flex--gap-4">
											<div className="switch">
												<label className="switch__label">
													<input
														type="checkbox"
														className="hidden"
														name="admin_registration_pending"
														checked={!!userData?.email_management?.admin_registration_pending}
													/>
													<span className="switch__toggler" />
												</label>
											</div>
											<div className="switch">
												<label className="switch__label">
													<input
														type="checkbox"
														className="hidden"
														name="admin_registration_verified"
														checked={!!userData?.email_management?.admin_registration_verified}
													/>
													<span className="switch__toggler" />
												</label>
											</div>
										</div>
									</div>
								</div>

								<div className="tr">
									<div className="td">
										<p className="td-hidden-name">Name</p>
										<div className="td__wrap td__wrap--column">
											<p className="font-weight-medium">Login Notification</p>
										</div>
									</div>
									<div className="td  td--mob-hidden">
										<p className="td-hidden-name">Status</p>
									</div>

									<div className="td">
										<p className="td-hidden-name">User Name</p>
										<div className="flex flex--column flex--gap-4">
											<div className="switch">
												<label className="switch__label">
													<input
														type="checkbox"
														className="hidden"
														name="user_login_notification"
														checked={!!userData?.email_management?.user_login_notification}
													/>
													<span className="switch__toggler" />
												</label>
											</div>
										</div>
									</div>

									<div className="td">
										<p className="td-hidden-name">Admin</p>
										<div className="flex flex--column flex--gap-4">
											<div className="switch">
												<label className="switch__label">
													<input
														type="checkbox"
														className="hidden"
														name="admin_login_notification"
														checked={!!userData?.email_management?.admin_login_notification}
													/>
													<span className="switch__toggler" />
												</label>
											</div>
										</div>
									</div>
								</div>

								<div className="tr">
									<div className="td">
										<p className="td-hidden-name">Name</p>
										<div className="td__wrap td__wrap--column">
											<p className="font-weight-medium">2FA</p>
										</div>
									</div>
									<div className="td">
										<p className="td-hidden-name">Status</p>
										<div className="td__wrap td__wrap--column td__wrap--gap-4">
											<p>Connected</p>
											<p>Disconnected</p>
										</div>
									</div>

									<div className="td">
										<p className="td-hidden-name">User Name</p>
										<div className="flex flex--column flex--gap-4">
											<div className="switch">
												<label className="switch__label">
													<input
														type="checkbox"
														className="hidden"
														name="user_google2fa_connected"
														checked={!!userData?.email_management?.user_google2fa_connected}
													/>
													<span className="switch__toggler" />
												</label>
											</div>
											<div className="switch">
												<label className="switch__label">
													<input
														type="checkbox"
														className="hidden"
														name="user_google2fa_disconnected"
														checked={!!userData?.email_management?.user_google2fa_disconnected}
													/>
													<span className="switch__toggler" />
												</label>
											</div>
										</div>
									</div>

									<div className="td">
										<p className="td-hidden-name">Admin</p>
										<div className="flex flex--column flex--gap-4">
											<div className="switch">
												<label className="switch__label">
													<input
														type="checkbox"
														className="hidden"
														name="admin_google2fa_connected"
														checked={!!userData?.email_management?.admin_google2fa_connected}
													/>
													<span className="switch__toggler" />
												</label>
											</div>
											<div className="switch">
												<label className="switch__label">
													<input
														type="checkbox"
														className="hidden"
														name="admin_google2fa_disconnected"
														checked={!!userData?.email_management?.admin_google2fa_disconnected}
													/>
													<span className="switch__toggler" />
												</label>
											</div>
										</div>
									</div>
								</div>

								<div className="tr">
									<div className="td">
										<p className="td-hidden-name">Name</p>
										<div className="td__wrap td__wrap--column">
											<p className="font-weight-medium">Reset</p>
										</div>
									</div>
									<div className="td">
										<p className="td-hidden-name">Status</p>
										<div className="td__wrap td__wrap--column td__wrap--gap-4">
											<p>Password Reset</p>
											<p>2FA Reset</p>
										</div>
									</div>

									<div className="td">
										<p className="td-hidden-name">User Name</p>
										<div className="flex flex--column flex--gap-4">
											<div className="switch">
												<label className="switch__label">
													<input
														type="checkbox"
														className="hidden"
														name="user_reset_password"
														checked={!!userData?.email_management?.user_reset_password}
													/>
													<span className="switch__toggler" />
												</label>
											</div>
											<div className="switch">
												<label className="switch__label">
													<input
														type="checkbox"
														className="hidden"
														name="user_reset_google2fa"
														checked={!!userData?.email_management?.user_reset_google2fa}
													/>
													<span className="switch__toggler" />
												</label>
											</div>
										</div>
									</div>

									<div className="td">
										<p className="td-hidden-name">Admin</p>
										<div className="flex flex--column flex--gap-4">
											<div className="switch">
												<label className="switch__label">
													<input
														type="checkbox"
														className="hidden"
														name="admin_reset_password"
														checked={!!userData?.email_management?.admin_reset_password}
													/>
													<span className="switch__toggler" />
												</label>
											</div>
											<div className="switch">
												<label className="switch__label">
													<input
														type="checkbox"
														className="hidden"
														name="admin_reset_google2fa"
														checked={!!userData?.email_management?.admin_reset_google2fa}
													/>
													<span className="switch__toggler" />
												</label>
											</div>
										</div>
									</div>
								</div>

								<div className="tr">
									<div className="td">
										<p className="td-hidden-name">Name</p>
										<div className="td__wrap td__wrap--column">
											<p className="font-weight-medium">Withdrawal (Fiat &amp; Crypto)</p>
										</div>
									</div>
									<div className="td">
										<p className="td-hidden-name">Status</p>
										<div className="td__wrap td__wrap--column td__wrap--gap-4">
											<p>Pending</p>
											<p>Completed</p>
											<p>Rejected</p>
											<p>Cancelled</p>
											<p>Failed</p>
											<p>Processing</p>
										</div>
									</div>

									<div className="td">
										<p className="td-hidden-name">User Name</p>
										<div className="flex flex--column flex--gap-4">
											<div className="switch">
												<label className="switch__label">
													<input
														type="checkbox"
														className="hidden"
														name="user_withdraw_pending"
														checked={!!userData?.email_management?.user_withdraw_pending}
													/>
													<span className="switch__toggler" />
												</label>
											</div>
											<div className="switch">
												<label className="switch__label">
													<input
														type="checkbox"
														className="hidden"
														name="user_withdraw_completed"
														checked={!!userData?.email_management?.user_withdraw_completed}
													/>
													<span className="switch__toggler" />
												</label>
											</div>
											<div className="switch">
												<label className="switch__label">
													<input
														type="checkbox"
														className="hidden"
														name="user_withdraw_rejected"
														checked={!!userData?.email_management?.user_withdraw_rejected}
													/>
													<span className="switch__toggler" />
												</label>
											</div>
											<div className="switch">
												<label className="switch__label">
													<input
														type="checkbox"
														className="hidden"
														name="user_withdraw_cancelled"
														checked={!!userData?.email_management?.user_withdraw_cancelled}
													/>
													<span className="switch__toggler" />
												</label>
											</div>
											<div className="switch">
												<label className="switch__label">
													<input
														type="checkbox"
														className="hidden"
														name="user_withdraw_failed"
														checked={!!userData?.email_management?.user_withdraw_failed}
													/>
													<span className="switch__toggler" />
												</label>
											</div>
											<div className="switch">
												<label className="switch__label">
													<input
														type="checkbox"
														className="hidden"
														name="user_withdraw_processing"
														checked={!!userData?.email_management?.user_withdraw_processing}
													/>
													<span className="switch__toggler" />
												</label>
											</div>
										</div>
									</div>

									<div className="td">
										<p className="td-hidden-name">Admin</p>
										<div className="flex flex--column flex--gap-4">
											<div className="switch">
												<label className="switch__label">
													<input
														type="checkbox"
														className="hidden"
														name="admin_withdraw_pending"
														checked={!!userData?.email_management?.admin_withdraw_pending}
													/>
													<span className="switch__toggler" />
												</label>
											</div>
											<div className="switch">
												<label className="switch__label">
													<input
														type="checkbox"
														className="hidden"
														name="admin_withdraw_completed"
														checked={!!userData?.email_management?.admin_withdraw_completed}
													/>
													<span className="switch__toggler" />
												</label>
											</div>
											<div className="switch">
												<label className="switch__label">
													<input
														type="checkbox"
														className="hidden"
														name="admin_withdraw_rejected"
														checked={!!userData?.email_management?.admin_withdraw_rejected}
													/>
													<span className="switch__toggler" />
												</label>
											</div>
											<div className="switch">
												<label className="switch__label">
													<input
														type="checkbox"
														className="hidden"
														name="admin_withdraw_cancelled"
														checked={!!userData?.email_management?.admin_withdraw_cancelled}
													/>
													<span className="switch__toggler" />
												</label>
											</div>
											<div className="switch">
												<label className="switch__label">
													<input
														type="checkbox"
														className="hidden"
														name="admin_withdraw_failed"
														checked={!!userData?.email_management?.admin_withdraw_failed}
													/>
													<span className="switch__toggler" />
												</label>
											</div>
											<div className="switch">
												<label className="switch__label">
													<input
														type="checkbox"
														className="hidden"
														name="admin_withdraw_processing"
														checked={!!userData?.email_management?.admin_withdraw_processing}
													/>
													<span className="switch__toggler" />
												</label>
											</div>
										</div>
									</div>
								</div>

								<div className="tr">
									<div className="td">
										<p className="td-hidden-name">Name</p>
										<div className="td__wrap td__wrap--column">
											<p className="font-weight-medium">Deposit (Fiat &amp; Crypto)</p>
										</div>
									</div>
									<div className="td">
										<p className="td-hidden-name">Status</p>
										<div className="td__wrap td__wrap--column td__wrap--gap-4">
											<p>Pending</p>
											<p>Completed</p>
											<p>Rejected</p>
											<p>Cancelled</p>
											<p>Failed</p>
											<p>Processing</p>
										</div>
									</div>

									<div className="td">
										<p className="td-hidden-name">User Name</p>
										<div className="flex flex--column flex--gap-4">
											<div className="switch">
												<label className="switch__label">
													<input
														type="checkbox"
														className="hidden"
														name="user_deposit_pending"
														checked={!!userData?.email_management?.user_deposit_pending}
													/>
													<span className="switch__toggler" />
												</label>
											</div>
											<div className="switch">
												<label className="switch__label">
													<input
														type="checkbox"
														className="hidden"
														name="user_deposit_completed"
														checked={!!userData?.email_management?.user_deposit_completed}
													/>
													<span className="switch__toggler" />
												</label>
											</div>
											<div className="switch">
												<label className="switch__label">
													<input
														type="checkbox"
														className="hidden"
														name="user_deposit_rejected"
														checked={!!userData?.email_management?.user_deposit_rejected}
													/>
													<span className="switch__toggler" />
												</label>
											</div>
											<div className="switch">
												<label className="switch__label">
													<input
														type="checkbox"
														className="hidden"
														name="user_deposit_cancelled"
														checked={!!userData?.email_management?.user_deposit_cancelled}
													/>
													<span className="switch__toggler" />
												</label>
											</div>
											<div className="switch">
												<label className="switch__label">
													<input
														type="checkbox"
														className="hidden"
														name="user_deposit_failed"
														checked={!!userData?.email_management?.user_deposit_failed}
													/>
													<span className="switch__toggler" />
												</label>
											</div>
											<div className="switch">
												<label className="switch__label">
													<input
														type="checkbox"
														className="hidden"
														name="user_deposit_processing"
														checked={!!userData?.email_management?.user_deposit_processing}
													/>
													<span className="switch__toggler" />
												</label>
											</div>
										</div>
									</div>

									<div className="td">
										<p className="td-hidden-name">Admin</p>
										<div className="flex flex--column flex--gap-4">
											<div className="switch">
												<label className="switch__label">
													<input
														type="checkbox"
														className="hidden"
														name="admin_deposit_pending"
														checked={!!userData?.email_management?.admin_deposit_pending}
													/>
													<span className="switch__toggler" />
												</label>
											</div>
											<div className="switch">
												<label className="switch__label">
													<input
														type="checkbox"
														className="hidden"
														name="admin_deposit_completed"
														checked={!!userData?.email_management?.admin_deposit_completed}
													/>
													<span className="switch__toggler" />
												</label>
											</div>
											<div className="switch">
												<label className="switch__label">
													<input
														type="checkbox"
														className="hidden"
														name="admin_deposit_rejected"
														checked={!!userData?.email_management?.admin_deposit_rejected}
													/>
													<span className="switch__toggler" />
												</label>
											</div>
											<div className="switch">
												<label className="switch__label">
													<input
														type="checkbox"
														className="hidden"
														name="admin_deposit_cancelled"
														checked={!!userData?.email_management?.admin_deposit_cancelled}
													/>
													<span className="switch__toggler" />
												</label>
											</div>
											<div className="switch">
												<label className="switch__label">
													<input
														type="checkbox"
														className="hidden"
														name="admin_deposit_failed"
														checked={!!userData?.email_management?.admin_deposit_failed}
													/>
													<span className="switch__toggler" />
												</label>
											</div>
											<div className="switch">
												<label className="switch__label">
													<input
														type="checkbox"
														className="hidden"
														name="admin_deposit_processing"
														checked={!!userData?.email_management?.admin_deposit_processing}
													/>
													<span className="switch__toggler" />
												</label>
											</div>
										</div>
									</div>
								</div>

								<div className="tr">
									<div className="td">
										<p className="td-hidden-name">Name</p>
										<div className="td__wrap td__wrap--column">
											<p className="font-weight-medium">Trade Confirmation</p>
										</div>
									</div>
									<div className="td td--mob-hidden">
										<p className="td-hidden-name">Status</p>
									</div>

									<div className="td">
										<p className="td-hidden-name">User Name</p>
										<div className="flex flex--column flex--gap-4">
											<div className="switch">
												<label className="switch__label">
													<input
														type="checkbox"
														className="hidden"
														name="user_trade_confirmation"
														checked={!!userData?.email_management?.user_trade_confirmation}
													/>
													<span className="switch__toggler" />
												</label>
											</div>
										</div>
									</div>

									<div className="td">
										<p className="td-hidden-name">Admin</p>
										<div className="flex flex--column flex--gap-4">
											<div className="switch">
												<label className="switch__label">
													<input
														type="checkbox"
														className="hidden"
														name="admin_trade_confirmation"
														checked={!!userData?.email_management?.admin_trade_confirmation}
													/>
													<span className="switch__toggler" />
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default EmailsTab;

import React, { FC, useEffect, useRef, useState } from 'react';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';

export interface IAccountNotesEditor {
	hasError: boolean;
	errorLong: boolean;
	errorEmpty: boolean;
	value?: string;
	setValue: (value: string) => void;
	reset?: boolean;
	resetOldValue?: boolean;
}

const AccountNotesEditor: FC<IAccountNotesEditor> = ({
	hasError,
	errorLong,
	errorEmpty,
	value,
	setValue,
	reset,
	resetOldValue,
}) => {
	const [editorState, setEditorState] = useState(EditorState.createEmpty());
	const [height, setHeight] = useState(136); // Initial height
	const isResizing = useRef(false);
	const lastY = useRef(0);

	const handleMouseDown = (e: React.MouseEvent) => {
		isResizing.current = true;
		lastY.current = e.clientY; // Store initial Y position
		e.preventDefault();
	};

	const handleMouseMove = (e: MouseEvent) => {
		if (isResizing.current) {
			const delta = (e.clientY - lastY.current) * 0.5; // Reduce sensitivity
			if (Math.abs(delta) > 1) {
				setHeight((prevHeight) => Math.max(136, prevHeight + delta)); // Apply change
				lastY.current = e.clientY; // Update last position
			}
		}
	};

	const handleMouseUp = () => {
		isResizing.current = false;
	};

	useEffect(() => {
		window.addEventListener('mousemove', handleMouseMove);
		window.addEventListener('mouseup', handleMouseUp);
		return () => {
			window.removeEventListener('mousemove', handleMouseMove);
			window.removeEventListener('mouseup', handleMouseUp);
		};
	}, []);

	const prepareDraft = (text: string) => {
		const draft = htmlToDraft(text);
		const contentStateValue = ContentState.createFromBlockArray(draft.contentBlocks);
		const editorStateValue = EditorState.createWithContent(contentStateValue);
		return editorStateValue;
	};

	const onEditorStateChange = (state: EditorState) => {
		const forFormik = draftToHtml(convertToRaw(state.getCurrentContent()));
		setEditorState(state);
		setValue(forFormik);
	};

	useEffect(() => {
		if (value) {
			setEditorState(prepareDraft(value));
		}
	}, [value, resetOldValue]);

	useEffect(() => {
		setEditorState(prepareDraft(''));
	}, [reset]);

	const toolbarOptions = {
		options: ['inline', 'list', 'textAlign', 'link', 'emoji', 'remove', 'history'],
		inline: {
			options: ['bold', 'italic', 'underline'],
		},
	};

	return (
		<div className="account-notes-editor">
			<div
				className="account-notes-editor__container"
				style={{ height, resize: 'none', overflow: 'auto' }}
			>
				<Editor
					editorState={editorState}
					wrapperClassName="editor__container"
					editorClassName="editor__wrapper"
					toolbarClassName="editor__toolbar"
					onEditorStateChange={onEditorStateChange}
					toolbar={toolbarOptions}
					placeholder="Add note"
				/>
			</div>
			<div className="account-notes-editor__resize" onMouseDown={handleMouseDown} />
			{hasError && (
				<div className="error-red mt-10">
					<div className="input-notify">
						{errorEmpty && <span className="input-notify__text">Please enter Note text</span>}
						{errorLong && (
							<span className="input-notify__text">
								Note text is too long. Maximum 2000 characters
							</span>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default AccountNotesEditor;

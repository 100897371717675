import React, { FC, useEffect, useRef, useState } from 'react';
import useClickOutside from 'hooks/useClickOutside';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
import IconSvg from '../Svg/IconSvg';

interface IPerPageSelect {
	onChange: (value: number | undefined) => void;
	defaultPerPage?: number;
}

const PerPageOptions = ['5', '10', '20', '50', '100'];

const PerPageSelect: FC<IPerPageSelect> = ({ onChange, defaultPerPage }) => {
	const [opened, setOpened] = useState(false);
	const [selectValue, setSelectValue] = useState(PerPageOptions[0]);

	const dropdownElement = useRef<HTMLDivElement | null>(null);
	useClickOutside(dropdownElement, () => setOpened(false));

	const handleSelect = (value: string) => {
		setOpened(false);
		setSelectValue(value);
		if (value === 'All') {
			onChange(undefined);
		} else {
			onChange(Number(value));
		}
	};

	const toggleSelect = () => {
		setOpened(!opened);
	};

	useEffect(() => {
		if (defaultPerPage) {
			const foundPerPage = PerPageOptions.find((item) => item === defaultPerPage.toString());
			if (foundPerPage) setSelectValue(foundPerPage);
		}
	}, [defaultPerPage]);

	return (
		<div className="select--per-page-wrapper">
			<div className={`select select--type1 ${opened ? 'active' : ''}`} ref={dropdownElement}>
				<button type="button" className="select__current" onClick={toggleSelect}>
					{selectValue}
					<span className="select__current-arrow">
						<svg
							width={9}
							height={5}
							viewBox="0 0 9 5"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path d="M1.5 1L4.5 4L7.5 1" stroke="#173B58" strokeLinecap="square" />
						</svg>
					</span>
				</button>
				<div>
					<AnimatePresence>
						{opened && (
							<motion.div
								initial={{ opacity: 0 }}
								animate={{ opacity: 1, transition: { duration: 0.3 } }}
								exit={{ opacity: 0, transition: { duration: 0.3 } }}
							>
								<div className="select__drop">
									<div className="select__drop-scroll">
										<div className="select__drop-item">
											<ul>
												{PerPageOptions.map((el) => (
													<li key={el}>
														<button type="button" onClick={() => handleSelect(el)}>
															{el}
														</button>
													</li>
												))}
											</ul>
										</div>
									</div>
								</div>
							</motion.div>
						)}
					</AnimatePresence>
				</div>
			</div>
			Rows Per Page
		</div>
	);
};

PerPageSelect.defaultProps = {
	defaultPerPage: 10,
};

export default PerPageSelect;

/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable prettier/prettier */
import React, { FC, useState, ChangeEvent } from 'react';
import { useHistory } from 'react-router';
import moment from 'moment/moment';
import { useDispatch } from 'react-redux';

import {
	EApiPooledDepositTransactionStatus,
	EApiTransactionStatus,
	EPendingWithdrawalType,
} from 'services/api/transactions/types';

import TableItemSelect from 'ui/TableItemSelect/TableItemSelect';
import Tippy from "@tippyjs/react"; // путь подправьте под свой проект
import {
	IHistoryFiltersOptionStringValue
} from "../../../../../AccountDetails/TransactionHistoryTab/CryptoHistory/types";
import {
	approveInviteRequestTrigger,
	pendingInviteRequestTrigger,
	rejectInviteRequestTrigger
} from "../../../../../../redux/reducers/multiAccounts/reducer";
import {popUpOpen, setPopUpData} from "../../../../../../redux/reducers/popUp/reducer";
import TableItemSelectPortal
	from "../../../../../../ui/TableItemSelectPortal/TableItemSelectPortal";
import {formatRoles} from "../../../../../../utils/formatRole";

// Пример массива статусов:
const STATUS_OPTIONS: IHistoryFiltersOptionStringValue[] = [
	{ id: 1, name: 'Pending', value: 'pending' },
	{ id: 2, name: 'Approved', value: 'approved' },
	{ id: 3, name: 'Rejected', value: 'rejected' },
	// { id: 3, name: 'Requested', value: 'requested' },
];

const convertStatusValue = (status: string): string => {
	switch (status.toLowerCase()) {
		case 'approved':
			return 'Approved';
		case 'rejected':
			return 'Rejected';
		case 'pending':
			return 'Pending';
		case 'requested':
			return 'Requested';
		default:
			return status.charAt(0).toUpperCase() + status.slice(1);
	}
};

// Ищем совпадение по value, если нет, создаём «заглушку»
const findStatusOption = (currentValue: string): IHistoryFiltersOptionStringValue => {
	const found = STATUS_OPTIONS.find((opt) => opt.value === currentValue);
	if (found) return found;
	// Если в массиве нету нужного, возвращаем объект-заглушку
	return {
		id: 999,
		value: currentValue,
		name: convertStatusValue(currentValue),
	};
};

// Фильтруем, чтобы не повторять текущий статус
const filterStatusArrayToEdit = (currentValue: string): IHistoryFiltersOptionStringValue[] => {
	return STATUS_OPTIONS.filter((option) => option.value !== currentValue);
};

interface AccountRequest {
	id: number;
	fname: string;
	lname: string;
	email: string;
	phone_number: string;
	company_name: string | null;
	roles: string[];
	message: string; // наша "заметка"
	status: string;  // e.g. "pending", "approved", etc.
	account_id: number;
	created_at: string;
	updated_at: string;
}

interface IProps {
	item: AccountRequest;
}

const TableBody: FC<IProps> = ({ item }) => {
	const history = useHistory();
	const dispatch = useDispatch();

	// ===== Пример, если нужно (CRYPTO, FIAT, и т.д.) =====
	const assetType = EPendingWithdrawalType.CRYPTO;

	// Локальное состояние editMode
	const [editMode, setEditMode] = useState(false);

	// Ищем соответствующий объект статуса
	const defaultStatus = findStatusOption(item.status);
	const [editedStatus, setEditedStatus] = useState<IHistoryFiltersOptionStringValue>(defaultStatus);

	// Заполняем массив для селекта (исключая текущий)
	const [statusArrayToEdit, setStatusArrayToEdit] = useState<IHistoryFiltersOptionStringValue[]>(
		filterStatusArrayToEdit(item.status),
	);

	// Message / Notes
	const [clientNotes, setClientNotes] = useState(item.message);
	const [editedNote, setEditedNote] = useState(item.message);

	// При включении режима редактирования
	const enableEditMode = () => {
		setEditMode(true);
		setEditedStatus(findStatusOption(item.status));
		setStatusArrayToEdit(filterStatusArrayToEdit(item.status));
		setEditedNote(clientNotes);
	};

	// При отмене изменений
	const handleDisableEditMode = () => {
		setEditMode(false);
		// Возвращаем всё как было
		setEditedStatus(findStatusOption(item.status));
		setStatusArrayToEdit(filterStatusArrayToEdit(item.status));
		setEditedNote(clientNotes);
	};

	// Сохранение (пример)
	const handleEdit = () => {
		const payload = {
			requestId: item.id,
			user_email:item.email,
			roles:[...item.roles],
			account_id:item.account_id,
		}

		if(editedStatus.value==='approved') {
			dispatch(popUpOpen('newUserRequests'));
			dispatch(setPopUpData(item))
		}
		if(editedStatus.value==='pending') {
			dispatch(pendingInviteRequestTrigger(payload))
		}
		if(editedStatus.value==='rejected') {
			dispatch(rejectInviteRequestTrigger(payload))
		}
		setClientNotes(editedNote);
		// Выходим из режима редактирования
		setEditMode(false);
	};

	// Выбор другого статуса из селекта
	const handleEditStatus = (selected: IHistoryFiltersOptionStringValue) => {
		setEditedStatus(selected);
		setStatusArrayToEdit(filterStatusArrayToEdit(selected.value));
	};

	// Редактирование заметки
	const handleEditNote = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setEditedNote(e.target.value);
	};
	debugger
	return (
		<div className="tr">
			<div className="td">
				<div className="td-hidden-name">Requested at</div>
				<p className="td-name--flex">{moment.utc(item.created_at).format('YYYY-MM-DD')}</p>
				<span className="td-more-info">{moment.utc(item.created_at).format('HH:mm:ss')}</span>
			</div>

			<div className="td">
				<div className="td-hidden-name">Full name</div>
				<div className="td__wrap">
					<div className="td__text td__text--truncate">
						<Tippy content={`${item.fname} ${item.lname}`} animation="scale"
									 theme="light">
							<div>
								{`${item.fname} ${item.lname}`}
							</div>
						</Tippy>
					</div>
				</div>
			</div>

			<div className="td">
				<div className="td-hidden-name">Email</div>
				<div className="td__wrap">
					<div className="td__text td__text--truncate">
						<Tippy content={item.email} animation="scale"
									 theme="light">
							<a href={`mailto:${item.email}`}>{item.email}</a>
						</Tippy>
					</div>
				</div>
			</div>

			<div className="td">
				<div className="td-hidden-name">Phone number</div>
				<div className="td__wrap">
					<div className="td__text">{item.phone_number}</div>
				</div>
			</div>

			<div className="td">
				<div className="td-hidden-name">Company name</div>
				<div className="td__wrap">
					<div className="td__text td__text--truncate">
						<Tippy content={item.company_name ? item.company_name : '-'} animation="scale"
									 theme="light">
							<div>
								{item.company_name ? item.company_name : '-'}
							</div>
						</Tippy>
					</div>
				</div>
			</div>

			<div className="td">
				<div className="td-hidden-name">Roles</div>
				<div className="td__wrap">
					<Tippy
						content={
							<div className="td__text td__text--truncate">
								{formatRoles(item?.roles)}
							</div>
						}
						animation="scale"
						theme="light"
					>
						<div className="td__text td__text--truncate">
							{formatRoles(item?.roles)}
						</div>
					</Tippy>
				</div>
			</div>

			<div className="td">
				<div className="td-hidden-name">Message</div>
				<div className="td__wrap">
					<Tippy content={<>
						<div className="td__text td__text--truncate">{item.message}</div>
					</>} animation="scale"
								 theme="light">
						<div className="td__text td__text--truncate">{item.message}</div>
					</Tippy>
				</div>
			</div>

			<div className="td">
				<div className="td-hidden-name">Status</div>
				<div className="td__wrap">
					{editMode ? (
						<TableItemSelectPortal
							activeElement={editedStatus}
							selectArray={statusArrayToEdit}
							onChange={handleEditStatus}
						/>
					) : (
						<span className={`${item.status}-status`}>
              {convertStatusValue(item.status)}
            </span>
					)}
				</div>
			</div>

			<div className="td td--right">
				<p className="td-hidden-name">Action</p>

				{editMode ? (
					<div className="table-buttons flex-e">
						<button
							type="button"
							className="btn--icon-danger btn--cancel"
							onClick={handleDisableEditMode}
						/>
						<button
							type="button"
							className="btn--icon-success btn--check"
							onClick={handleEdit}
						/>
					</div>
				) : (
					<button
						type="button"
						className="btn btn--icon btn--edit"
						onClick={enableEditMode}
					/>
				)}
			</div>
		</div>
	);
};

export default TableBody;

import { useDispatch, useSelector } from 'react-redux';
import React, { FC, useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import {
	deleteUserNoteRequest,
	getUserNotesRequest,
	updateUserNoteRequest,
} from '../../../../../redux/reducers/users/reducer';
import { getOneUser } from '../../../../../redux/reducers/users/selectors';
import {
	IDeleteUserNoteRequest,
	IUpdateUserNoteRequest,
	IUserNotesData,
} from '../../../../../redux/reducers/users/types';
import AccountNotesTableRow from './AccountNotesTableRow';
import PerPageSelect from '../../../../../ui/PerPageSelect/PerPageSelect';
import DeleteAccountNotePopup from '../../../../../layouts-elements/PopUp/DeleteAccountNotePopup/DeleteAccountNotePopup';

export interface IAccountNotesTable {
	refetch: boolean;
}

const AccountNotesTable: FC<IAccountNotesTable> = ({ refetch }) => {
	const dispatch = useDispatch();
	const user = useSelector(getOneUser);
	const [notes, setNotes] = useState<any>(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState<number | undefined>(5);
	const [actionLoading, setActionLoading] = useState(false);
	const [localRefetch, setLocalRefetch] = useState(false);
	const [resetTable, setResetTable] = useState(false);
	const [popupData, setPopupData] = useState<{ open: boolean; note_id: number | null }>({
		open: false,
		note_id: null,
	});

	const handleSetPerPage = (value: number | undefined) => {
		setPerPage(value || notes?.total);
		setCurrentPage(1);
	};

	const handlePageClick = ({ selected }: { selected: number }) => {
		setCurrentPage(selected + 1);
	};

	const pageCount = notes?.last_page ? notes.last_page : 1;

	const openPopupHandler = (note_id: number) => {
		setPopupData({
			open: true,
			note_id,
		});
	};

	const deleteUserNoteHandler = (note_id: number) => {
		setActionLoading(true);
		const params: IDeleteUserNoteRequest = {
			note_id,
			onFinally: () => {
				setLocalRefetch(!localRefetch);
				setActionLoading(false);
				setPopupData((prevState) => ({ ...prevState, open: false }));
			},
		};
		dispatch(deleteUserNoteRequest(params));
	};

	const updateUserNoteHandler = (note_id: number, note: string) => {
		setActionLoading(true);
		const params: IUpdateUserNoteRequest = {
			apiParams: {
				note_id,
				note,
			},
			onFinally: () => {
				setLocalRefetch(!localRefetch);
				setActionLoading(false);
			},
		};
		dispatch(updateUserNoteRequest(params));
	};

	useEffect(() => {
		if (user) {
			const payload = {
				apiParams: {
					user_id: user.id,
					pageParams: {
						per_page: perPage,
						page: currentPage,
					},
				},
				onFinally: (data: any) => {
					setNotes(data);
					setResetTable(!resetTable);
				},
			};
			dispatch(getUserNotesRequest(payload));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, user, currentPage, perPage, refetch, localRefetch]);

	return (
		<>
			{notes && notes.data.length ? (
				<>
					<div className="table table--account-notes">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<div className="td-name">
										<p>Timestamp</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Notes</p>
									</div>
								</div>
								<div className="td td--center">
									<div className="td-name">
										<p>Action</p>
									</div>
								</div>
							</div>
						</div>
						<div className="table-body">
							{notes.data.map((item: IUserNotesData) => (
								<AccountNotesTableRow
									data={item}
									actionLoading={actionLoading}
									deleteUserNoteHandler={openPopupHandler}
									updateUserNoteHandler={updateUserNoteHandler}
									reset={resetTable}
								/>
							))}
						</div>
					</div>
					<div className="table-footer">
						<div className="pagination-block pagination-block--full">
							<PerPageSelect onChange={handleSetPerPage} defaultPerPage={perPage} />
							{pageCount > 1 && (
								<ReactPaginate
									breakLabel="..."
									pageCount={pageCount}
									onPageChange={handlePageClick}
									forcePage={currentPage - 1}
									className="pagination"
									activeClassName="active"
									previousClassName="pagination__arrow pagination__arrow--prev"
									nextClassName="pagination__arrow pagination__arrow--next"
									previousLabel=""
									nextLabel=""
								/>
							)}
						</div>
					</div>
					<DeleteAccountNotePopup
						open={popupData.open}
						confirmHandler={deleteUserNoteHandler}
						note_id={popupData.note_id}
						closeModal={() => setPopupData((prevState) => ({ ...prevState, open: false }))}
						loading={actionLoading}
					/>
				</>
			) : null}
		</>
	);
};

export default AccountNotesTable;

import React, { FC } from 'react';

const ProgressBar: FC<{ progress: number }> = ({ progress }) => (
	<div className="progress-wrapper">
		<div className="progress-bar-wrapper">
			<span className="progress-text">{progress}%</span>
			<div className="progress-bar-container">
				<div className="progress-bar" style={{ width: `${progress}%` }} />
			</div>
		</div>
	</div>
);

export default ProgressBar;

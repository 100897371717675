import React, { FC, useState } from 'react';
import Tippy from '@tippyjs/react';
import { IHistoryFiltersOptionStringValue } from 'components/AccountDetails/TransactionHistoryTab/CryptoHistory/types';
import { EApiTransactionStatus } from 'services/api/transactions/types';
import IconSvg from '../Svg/IconSvg';

export interface ITableItemSelect {
	selectArray: IHistoryFiltersOptionStringValue[];
	activeElement: IHistoryFiltersOptionStringValue;
	onChange: (activeElement: IHistoryFiltersOptionStringValue) => void;
}

const TableItemSelectPortal: FC<ITableItemSelect> = ({ selectArray, activeElement, onChange }) => {
	const [opened, setOpened] = useState(false);

	const handleToggleDropdown = () => {
		setOpened((prev) => !prev);
	};

	const handleCloseDropdown = () => {
		setOpened(false);
	};

	const handleChooseDropdownItem = (newActiveElement: IHistoryFiltersOptionStringValue) => {
		onChange(newActiveElement);
		handleCloseDropdown();
	};

	return (
		<Tippy
			className="tippy-content_portal"
			interactive
			visible={opened}
			placement="bottom"
			arrow={false}
			onClickOutside={handleCloseDropdown}
			appendTo={document.body}
			content={
				<div
					className="select__drop__portal"
					style={{ display: 'block', opacity: 1, transition: 'opacity 0.3s' }}
				>
					<div className="select__drop-scroll">
						<div className="select__drop-item">
							<ul>
								{!!selectArray?.length &&
									selectArray.map((item) => (
										<li key={item.id}>
											<button
												type="button"
												className="button-portal"
												// className={getButtonClassName(item.value)}
												onClick={() => handleChooseDropdownItem(item)}
											>
												{item.name}
											</button>
										</li>
									))}
							</ul>
						</div>
					</div>
				</div>
			}
		>
			<button
				type="button"
				// className={`select__current ${getButtonClassName(activeElement?.value)}`}
				className="select__current"
				onClick={handleToggleDropdown}
			>
				{activeElement?.name || ''}
				<span className="select__current-arrow">
					<IconSvg name="keyboard-arrow-down" w="9" h="6" />
				</span>
			</button>
		</Tippy>
	);
};

export default TableItemSelectPortal;

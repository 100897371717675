import {
	THistoryFiltersOption,
	THistoryFiltersTypeOption,
} from 'components/AccountDetails/TransactionHistoryTab/CryptoHistory/types';

import { FC, useEffect, useState } from 'react';

import { INIT_FILTER_OPTIONS } from 'redux/reducers/transactionsFilters/constants';
import {
	ETransactionsFilters,
	ITransactionsFilter,
} from 'redux/reducers/transactionsFilters/types';

import FilterDateSelect from 'ui/Formik/Select/FilterDateSelect';
import FilterSelect from 'ui/Formik/Select/FilterSelect';
import SearchWithSelect, { ISearchSelectArr } from 'ui/SearchWithSelect/SearchWithSelect';
import { convertFilterObjectToParamsObject } from '../../services/utils/convertFilterObjectToParamsObject';

export interface ITransactionsFilters {
	classNames?: string;
	activeFilters?: ITransactionsFilter[];
	handleResetFilters: () => void;
	handleDeleteFilter?: (type: ETransactionsFilters) => void;
	handleDateChange: (startDate: Date, endDate: Date) => void;
	handleChangeCurrencySell?: ({ name, value }: THistoryFiltersOption) => void;
	handleChangeCurrencyBuy?: ({ name, value }: THistoryFiltersOption) => void;
	currencyOptions?: {
		id: number;
		name: string;
		value: number;
	}[];
	searchArray?: {
		name: string;
		value: ETransactionsFilters;
	}[];
	handleSearch?: (searchField: ISearchSelectArr, currentSearchValue: string) => void;
	resetValue?: boolean;
	type?: string;
	reset?: boolean;
}

interface ISelect {
	id: number;
	name: string;
	value: number | undefined;
}

const ActiveTradeFilters: FC<ITransactionsFilters> = ({
	handleResetFilters,
	handleDateChange,
	handleChangeCurrencySell,
	handleChangeCurrencyBuy,
	currencyOptions,
	reset,
	searchArray,
	handleSearch,
	resetValue,
	activeFilters,
}) => {
	const [selectedSell, setSelectedSell] = useState<ISelect>(INIT_FILTER_OPTIONS[0]);
	const [selectedBuy, setSelectedBuy] = useState<ISelect>(INIT_FILTER_OPTIONS[0]);
	const [selectedSearchWithSelect, setSelectedSearchWithSelect] = useState('');
	const [inputValueSearchWithSelect, setInputValueSearchWithSelect] = useState('');
	const [selectedDate, setSelectedDate] = useState<any>(null);

	useEffect(() => {
		if (activeFilters && activeFilters?.length > 0) {
			const filterParams = convertFilterObjectToParamsObject(activeFilters);
			if (Object.keys(filterParams).length > 0) {
				const selectedSellParams = currencyOptions?.filter(
					(item) => item.name === filterParams?.sell,
				);
				const selectedBuyParams = currencyOptions?.filter(
					(item) => item.name === filterParams?.buy,
				);
				if (selectedSellParams && selectedSellParams.length > 0) {
					setSelectedSell(selectedSellParams[0]);
				}
				if (selectedBuyParams && selectedBuyParams.length > 0) {
					setSelectedBuy(selectedBuyParams[0]);
				}
				if ('oneify_id' in filterParams) {
					setSelectedSearchWithSelect('oneify_id');
					setInputValueSearchWithSelect(filterParams.oneify_id.toString());
				}
				if ('id' in filterParams) {
					setSelectedSearchWithSelect('id');
					setInputValueSearchWithSelect(filterParams.id.toString());
				}
				if ('date_from' in filterParams) {
					setSelectedDate((prevState: any) => ({
						...prevState,
						date_from: filterParams.date_from,
					}));
				}
				if ('date_to' in filterParams) {
					setSelectedDate((prevState: any) => ({
						...prevState,
						date_to: filterParams.date_to,
					}));
				}
			}
		} else {
			setSelectedSell(INIT_FILTER_OPTIONS[0]);
			setSelectedBuy(INIT_FILTER_OPTIONS[0]);
			setSelectedSearchWithSelect('');
			setInputValueSearchWithSelect('');
			setSelectedDate(null);
		}
	}, [activeFilters, currencyOptions]);

	return (
		<>
			<div className="active-trade_filters">
				<div className="active-trade_filters--left">
					<div className="active-trade_filters--date">
						<FilterDateSelect
							title="Date"
							onChange={handleDateChange}
							reset={reset}
							selectedDate={selectedDate}
						/>
					</div>
					{currencyOptions && handleChangeCurrencySell && (
						<div className="active-trade_filters--currency">
							<p>Sell</p>
							<FilterSelect
								options={
									[...INIT_FILTER_OPTIONS, ...currencyOptions] as THistoryFiltersTypeOption[]
								}
								selected={selectedSell}
								onChange={handleChangeCurrencySell}
								icons
								reset={reset}
							/>
						</div>
					)}
					{currencyOptions && handleChangeCurrencyBuy && (
						<div className="active-trade_filters--currency">
							<p>Buy</p>
							<FilterSelect
								options={
									[...INIT_FILTER_OPTIONS, ...currencyOptions] as THistoryFiltersTypeOption[]
								}
								selected={selectedBuy}
								onChange={handleChangeCurrencyBuy}
								icons
								reset={reset}
							/>
						</div>
					)}
					{searchArray && handleSearch && (
						<div className="active-trade_filters--search">
							<SearchWithSelect
								handleSearch={handleSearch}
								selectArr={searchArray}
								resetValue={resetValue}
								// trade
							/>
						</div>
					)}
				</div>
				<button type="button" className="reset_trade-filters" onClick={handleResetFilters}>
					Reset
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
					>
						<path
							d="M9.24738 16.5678C7.87879 16.3787 6.7311 15.7666 5.80429 14.7313C4.87747 13.6961 4.41406 12.4664 4.41406 11.0422C4.41406 10.2645 4.56043 9.54304 4.85317 8.87798C5.1459 8.21292 5.53799 7.62826 6.02944 7.12398L6.79544 7.89C6.3948 8.28743 6.0783 8.75992 5.84594 9.30746C5.61356 9.855 5.49737 10.4333 5.49737 11.0422C5.49737 12.1672 5.85635 13.1368 6.57429 13.9509C7.29225 14.765 8.18328 15.2693 9.24738 15.4637V16.5678ZM10.7474 16.5839V15.4797C11.8115 15.2778 12.7025 14.7676 13.4205 13.9493C14.1384 13.1309 14.4974 12.1619 14.4974 11.0422C14.4974 9.79223 14.0599 8.72973 13.1849 7.85473C12.3099 6.97973 11.2474 6.54223 9.99738 6.54223H9.61915L10.7858 7.70892L10.0118 8.4621L7.55029 6.00058L10.0118 3.53906L10.7858 4.31308L9.63998 5.45892H9.99738C11.5465 5.45892 12.8643 6.00219 13.9509 7.08873C15.0374 8.17526 15.5807 9.49309 15.5807 11.0422C15.5807 12.4717 15.1159 13.7049 14.1865 14.7417C13.257 15.7786 12.1106 16.3926 10.7474 16.5839Z"
							fill="#0071AB"
						/>
					</svg>
				</button>
			</div>
		</>
	);
};
export default ActiveTradeFilters;

import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createUserGroupRequest, getUserGroupsRequest } from '../../redux/reducers/users/reducer';
import { IGetUserGroupsResponse } from '../../services/api/users/types';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

export type TUserGroupFieldProps = {
	userGroupId?: number | null;
	userGroupName?: string;
	isDisabled?: boolean;
	applyGroupHandler?: (group: IGetUserGroupsResponse | null) => void;
	clearOnEmptyHandler?: () => void;
};

export type TUserGroupFieldRef = {
	performAction: () => void;
};

const UserGroupField = forwardRef<TUserGroupFieldRef, TUserGroupFieldProps>(
	({ isDisabled, applyGroupHandler, userGroupId, userGroupName, clearOnEmptyHandler }, ref) => {
		const dispatch = useDispatch();
		const [isFocused, setIsFocused] = useState(false);
		const [isLoading, setIsLoading] = useState(false);
		const [enteredValue, setEnteredValue] = useState('');
		const [debouncedValue, setDebouncedValue] = useState('');
		const [groupsSearchResults, setGroupsSearchResults] = useState<IGetUserGroupsResponse[] | []>(
			[],
		);
		const [searchTrigger, setSearchTrigger] = useState(false);
		const isInitialLoad = useRef(true); // Track if value comes from props

		// Debounce effect (skip debounce if value comes from props)
		useEffect(() => {
			if (isInitialLoad.current) {
				isInitialLoad.current = false; // Reset flag after initial load
				return;
			}

			const debounceHandler = setTimeout(() => {
				setDebouncedValue(enteredValue); // Update state after delay
			}, 500); // 500ms debounce time

			// eslint-disable-next-line consistent-return
			return () => clearTimeout(debounceHandler); // Cleanup timeout on re-render
		}, [enteredValue]);

		useEffect(() => {
			if (userGroupName) {
				isInitialLoad.current = true; // Set flag to true
				setEnteredValue(userGroupName);
			}
		}, [userGroupName]);

		useEffect(() => {
			if (debouncedValue) {
				setIsLoading(true);
				dispatch(
					getUserGroupsRequest({
						apiParams: {
							name: debouncedValue,
						},
						onFinally: (result) => {
							setGroupsSearchResults(result);
							setIsLoading(false);
						},
					}),
				);
			} else {
				setGroupsSearchResults([]);
			}
		}, [debouncedValue, dispatch, searchTrigger]);

		useEffect(() => {
			if (clearOnEmptyHandler) {
				if (!isFocused && !enteredValue) {
					clearOnEmptyHandler();
				}
			}
		}, [clearOnEmptyHandler, enteredValue, isFocused]);

		const addGroupHandler = () => {
			if (debouncedValue) {
				setIsLoading(true);
				dispatch(
					createUserGroupRequest({
						apiParams: {
							group_name: debouncedValue,
						},
						onFinally: () => {
							setSearchTrigger(!searchTrigger);
						},
					}),
				);
			}
		};

		const clickGroupHandler = (group: IGetUserGroupsResponse) => {
			isInitialLoad.current = true;
			setEnteredValue(group.group_name);
			setGroupsSearchResults([]);
			setDebouncedValue('');
			if (applyGroupHandler) {
				applyGroupHandler(group);
			}
		};

		useImperativeHandle(ref, () => ({
			performAction: () => {
				isInitialLoad.current = true;
				setEnteredValue(userGroupName || '');
				setGroupsSearchResults([]);
				setDebouncedValue('');
			},
		}));

		return (
			<div className="user-group-input account-list-item__info">
				<div className="user-group-input__input">
					<input
						type="text"
						onFocus={() => setIsFocused(true)}
						onBlur={() => setIsFocused(false)}
						className="input-item"
						value={enteredValue}
						onChange={(e) => setEnteredValue(e.currentTarget.value)}
						disabled={isDisabled}
					/>
				</div>

				{debouncedValue && (
					<div className="user-group-input__dropdown">
						{isLoading ? (
							<div className="user-group-input__loading">
								<LoadingSpinner />
							</div>
						) : (
							<>
								{groupsSearchResults.length
									? groupsSearchResults.map((group) => (
											<div
												className="user-group-input__dropdown-item"
												onClick={() => clickGroupHandler(group)}
											>
												{group.group_name}
											</div>
									  ))
									: null}
								{groupsSearchResults.filter((item) => item.group_name === debouncedValue)
									.length ? null : (
									<div
										className="user-group-input__dropdown-item user-group-input__dropdown-item--add"
										onClick={addGroupHandler}
									>
										Add group “{debouncedValue}”
									</div>
								)}
							</>
						)}
					</div>
				)}
			</div>
		);
	},
);

export default UserGroupField;

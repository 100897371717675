import React, { useMemo, useState } from 'react';
import { NavLink, Redirect, Route, Switch, matchPath, useLocation } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { EApiTransactionType } from 'services/api/transactions/types';
import AddFiatDepositTransaction from 'layouts-elements/PopUp/AddFiatDepositTransaction/AddFiatDepositTransaction';
import { useSelector } from 'react-redux';
import { getPermissions } from 'redux/reducers/auth/selectors';
import { EPermissionNames } from 'redux/reducers/auth/types';
import DetailedView from 'components/DetailedView/DetailedView';
import CryptoTransactionsTable from '../CryptoTransactionsTable';
import FiatTransactionsTable from '../FiatTransactionsTable';
import PooledDepositTable from '../PooledDepositTable';
import ProfitWalletTable from '../ProfitWalletTable';
import { ITransactionsTab } from '../types';
import CryptoTransfersTable from '../CryptoTransfersTable';
import FiatTransfersTable from '../FiatTransfersTable';
import CryptoTransactionsWithdrawalTable from '../CryptoTransactionsWithdrawalTable';
import FiatTransactionsWithdrawalTable from '../FiatTransactionsWithdrawalTable';
import AddCryptoDepositTransaction from '../../../layouts-elements/PopUp/AddCryptoDepositTransaction/AddCryptoDepositTransaction';
import AddWithdrawalFiatTransaction from '../../../layouts-elements/PopUp/AddWithdrawalFiatTransaction/AddWithdrawalFiatTransaction';
import AddWithdrawalCryptoTransaction from '../../../layouts-elements/PopUp/AddWithdrawalCryptoTransaction/AddWithdrawalCryptoTransaction';

const DepositsWithdrawalsTab = ({ type }: ITransactionsTab) => {
	const { state } = useLocation();
	const path: { [key: string]: string } = useMemo(() => {
		switch (type) {
			case EApiTransactionType.DEPOSIT: {
				return ROUTES.transfersAndTradeReports.deposit;
			}
			case EApiTransactionType.WITHDRAWAL: {
				return ROUTES.transfersAndTradeReports.withdrawals;
			}
			case EApiTransactionType.TRANSFER: {
				return ROUTES.transfersAndTradeReports.transfers;
			}
			default: {
				return ROUTES.transfersAndTradeReports.deposit;
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type]);

	const [addNewTransactionPopup, setAddNewTransactionPopup] = useState(false);
	const [addNewTransactionCryptoPopup, setAddNewTransactionCryptoPopup] = useState(false);
	const [addWithdrawalFiatTransactionPopup, setAddWithdrawalFiatTransactionPopup] = useState(false);
	const [addWithdrawalCryptoTransactionPopup, setAddWithdrawalCryptoTransactionPopup] =
		useState(false);
	const handleOpenAddNewTransactionPopup = () => {
		setAddNewTransactionPopup(true);
	};
	const handleCloseAddNewTransactionPopup = () => {
		setAddNewTransactionPopup(false);
	};

	const handleOpenAddWithdrawalFiatTransactionPopup = () => {
		setAddWithdrawalFiatTransactionPopup(true);
	};
	const handleCloseAddWithdrawalFiatTransactionPopup = () => {
		setAddWithdrawalFiatTransactionPopup(false);
	};

	const handleOpenAddNewTransactionCryptoPopup = () => {
		setAddNewTransactionCryptoPopup(true);
	};
	const handleCloseAddNewTransactionCryptoPopup = () => {
		setAddNewTransactionCryptoPopup(false);
	};

	const handleOpenAddWithdrawalCryptoTransactionPopup = () => {
		setAddWithdrawalCryptoTransactionPopup(true);
	};
	const handleCloseAddWithdrawalCryptoTransactionPopup = () => {
		setAddWithdrawalCryptoTransactionPopup(false);
	};

	const permission = useSelector(getPermissions)?.[EPermissionNames.TRANSFER_TRADE_REPORT].editable;

	const { pathname } = useLocation();

	return (
		<div className="user-management">
			<div className="user-management-body user-management-body--type2">
				<div
					className={
						type === EApiTransactionType.DEPOSIT || EApiTransactionType.WITHDRAWAL
							? 'tabs-buttons-wrapper'
							: ''
					}
				>
					{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
					{/* @ts-ignore */}
					{state && state.txId ? null : (
						<div
							className={`tabs-buttons tabs-buttons-${
								type === EApiTransactionType.TRANSFER ? 'grid' : 'flex'
							}`}
						>
							<NavLink to={path?.crypto || ''} className="button button--size4 button--type3">
								Crypto
							</NavLink>
							<NavLink to={path?.fiat || ''} className="button button--size4 button--type3">
								Fiat
							</NavLink>
							{type === EApiTransactionType.TRANSFER && (
								<NavLink
									to={path?.pooledDeposit || ''}
									className="button button--size4 button--type3"
								>
									Pooled Wallet
								</NavLink>
							)}
							{type === EApiTransactionType.TRANSFER && (
								<NavLink
									to={path?.profitWallet || ''}
									className="button button--size4 button--type3"
								>
									Profit Wallet
								</NavLink>
							)}
						</div>
					)}
					{!!permission && type === EApiTransactionType.DEPOSIT && pathname === path.fiat && (
						<button
							type="button"
							onClick={handleOpenAddNewTransactionPopup}
							className="btn btn-primary"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="21"
								height="20"
								viewBox="0 0 21 20"
								fill="none"
							>
								<path
									d="M9.87435 15.8307V10.6224H4.66602V9.3724H9.87435V4.16406H11.1243V9.3724H16.3327V10.6224H11.1243V15.8307H9.87435Z"
									fill="white"
								/>
							</svg>
							Add New Transaction
						</button>
					)}

					{!!permission && type === EApiTransactionType.WITHDRAWAL && pathname === path.fiat && (
						<button
							type="button"
							onClick={handleOpenAddWithdrawalFiatTransactionPopup}
							className="btn btn-primary"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="21"
								height="20"
								viewBox="0 0 21 20"
								fill="none"
							>
								<path
									d="M9.87435 15.8307V10.6224H4.66602V9.3724H9.87435V4.16406H11.1243V9.3724H16.3327V10.6224H11.1243V15.8307H9.87435Z"
									fill="white"
								/>
							</svg>
							Add New Transaction
						</button>
					)}

					{!!permission && type === EApiTransactionType.DEPOSIT && pathname === path.crypto && (
						<button
							type="button"
							onClick={handleOpenAddNewTransactionCryptoPopup}
							className="btn btn-primary"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="21"
								height="20"
								viewBox="0 0 21 20"
								fill="none"
							>
								<path
									d="M9.87435 15.8307V10.6224H4.66602V9.3724H9.87435V4.16406H11.1243V9.3724H16.3327V10.6224H11.1243V15.8307H9.87435Z"
									fill="white"
								/>
							</svg>
							Add New Transaction
						</button>
					)}

					{!!permission && type === EApiTransactionType.WITHDRAWAL && pathname === path.crypto && (
						<button
							type="button"
							onClick={handleOpenAddWithdrawalCryptoTransactionPopup}
							className="btn btn-primary"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="21"
								height="20"
								viewBox="0 0 21 20"
								fill="none"
							>
								<path
									d="M9.87435 15.8307V10.6224H4.66602V9.3724H9.87435V4.16406H11.1243V9.3724H16.3327V10.6224H11.1243V15.8307H9.87435Z"
									fill="white"
								/>
							</svg>
							Add New Transaction
						</button>
					)}
				</div>

				<Switch>
					<Route exact path={path?.crypto || ''}>
						{type === EApiTransactionType.TRANSFER ? (
							<CryptoTransfersTable type={type} />
						) : (
							<>
								{type === 'withdrawal' ? (
									<CryptoTransactionsWithdrawalTable type={type} />
								) : (
									<CryptoTransactionsTable type={type} />
								)}
							</>
						)}
					</Route>
					<Route exact path={path?.fiat || ''}>
						{type === EApiTransactionType.TRANSFER ? (
							<FiatTransfersTable type={type} />
						) : (
							<>
								{type === 'withdrawal' ? (
									<FiatTransactionsWithdrawalTable type={type} />
								) : (
									<FiatTransactionsTable type={type} />
								)}
							</>
						)}
					</Route>
					{type === EApiTransactionType.TRANSFER && (
						<Route exact path={path?.pooledDeposit || ''}>
							<PooledDepositTable />
						</Route>
					)}
					{type === EApiTransactionType.TRANSFER && (
						<Route exact path={path?.profitWallet || ''}>
							<ProfitWalletTable />
						</Route>
					)}
					{/* <Route path={[`${path?.crypto}/:id`, `${path?.fiat}/:id`]} component={DetailedView} /> */}

					<Redirect to={path?.crypto || ''} />
				</Switch>
			</div>
			<AddFiatDepositTransaction
				closeModal={handleCloseAddNewTransactionPopup}
				open={addNewTransactionPopup}
			/>
			<AddWithdrawalFiatTransaction
				closeModal={handleCloseAddWithdrawalFiatTransactionPopup}
				open={addWithdrawalFiatTransactionPopup}
			/>
			<AddCryptoDepositTransaction
				closeModal={handleCloseAddNewTransactionCryptoPopup}
				open={addNewTransactionCryptoPopup}
			/>
			<AddWithdrawalCryptoTransaction
				closeModal={handleCloseAddWithdrawalCryptoTransactionPopup}
				open={addWithdrawalCryptoTransactionPopup}
			/>
		</div>
	);
};

export default DepositsWithdrawalsTab;

/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { FC, useRef, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { Formik, Form, Field, FormikProps } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { put } from 'redux-saga/effects';
import Input from '../../../ui/Formik/Input';
import { IPopUp } from '../types';
import { getPopUp } from '../../../redux/reducers/popUp/selectors';
import { AccountRequest } from '../../../services/api/multiAccounts/types';
import { approveInviteRequestTrigger } from '../../../redux/reducers/multiAccounts/reducer';
import { getErrorMsg } from '../../../redux/reducers/errors/selectors';
import { setErrorMsg } from '../../../redux/reducers/errors/reducer';
import { getAccountsDataParamsSelector } from '../../../redux/reducers/multiAccounts/selectors';

const validationSchema = yup.object().shape({
	two_factor_auth: yup
		.string()
		.required('Two-Factor Authentication is required')
		.matches(/^\d{6}$/, 'Please enter a 6 digit 2FA code'),
});

const NewUserRequests: FC<IPopUp> = ({ open, closeModal }) => {
	const dispatch = useDispatch();
	const getAccountsDataParams = useSelector(getAccountsDataParamsSelector);

	const popUpData = useSelector(getPopUp);
	const item: any | null = popUpData?.data ?? null;
	const errorMessage = useSelector(getErrorMsg);

	const formikRef = useRef<FormikProps<any>>(null);

	useEffect(() => {
		if (errorMessage === 'the_user_email_must_be_a_valid_email_address') {
			formikRef.current?.setFieldError('user_email', 'The provided email address is invalid');
		} else if (errorMessage === 'invalid_totp_code') {
			formikRef.current?.setFieldError('two_factor_auth', 'Your 2FA code is incorrect');
		} else {
			formikRef.current?.setFieldError('user_email', '');
			formikRef.current?.setFieldError('two_factor_auth', '');
		}
	}, [errorMessage]);

	if (!item) {
		return null;
	}

	const initialValues = {
		account_name: `${item.fname} ${item.lname}` || '',
		account_id: item.account_id || '',
		user_email: item.email || '',
		user_id: item.id ? String(item.id) : '',
		roles: item.roles || [],
		message: item.message || '',
		two_factor_auth: '',
	};

	const handleCloseModal = () => {
		closeModal();
	};

	return (
		<Popup open={open} nested onClose={handleCloseModal}>
			<div className="popup popup--width-480">
				<button
					type="button"
					className="popup__close-btn"
					onClick={handleCloseModal}
					aria-label="Close"
				/>

				<div className="popup-header popup-header--mb-20" style={{ textAlign: 'center' }}>
					<p style={{ width: 290, margin: '0 auto' }} className="popup-header__title">
						You’re about to add new User to the Account
					</p>
				</div>

				<Formik
					innerRef={formikRef}
					initialValues={initialValues}
					validationSchema={validationSchema}
					enableReinitialize
					onSubmit={(values) => {
						const payload = {
							requestId: item.id,
							user_email: values.user_email,
							user_id: values.user_id,
							roles: [...values.roles],
							account_id: values.account_id,
							account_name: values.account_name,
							message: values.message,
							totp: values.two_factor_auth,
						};

						dispatch(approveInviteRequestTrigger(payload));
					}}
				>
					{({ isSubmitting, values, isValid }) => (
						<Form>
							<div className="popup-body">
								<Field
									name="account_name"
									component={Input}
									title="Account name"
									placeholder="Enter Account Name"
									disable
								/>
								<Field
									name="account_id"
									component={Input}
									title="Account ID"
									placeholder="Enter Account ID"
									disable
								/>
								<Field
									name="user_email"
									component={Input}
									title="User email"
									placeholder="Enter User Email"
									onChange={(e: any) => {
										formikRef.current?.handleChange(e);
										if (errorMessage === 'the_user_email_must_be_a_valid_email_address') {
											dispatch(setErrorMsg(''));
											formikRef.current?.setSubmitting(false);
											formikRef.current?.setFieldError('user_email', '');
										}
									}}
									disable
								/>
								{/* <Field */}
								{/*	name="user_id" */}
								{/*	component={Input} */}
								{/*	title="User ID" */}
								{/*	placeholder="Enter User ID" */}
								{/*	disable */}
								{/* /> */}
								<div className="checkbox-role" role="group" aria-labelledby="checkbox-group">
									<p className="input__name">Roles</p>
									<div className="checkbox">
										<label>
											<Field type="checkbox" name="roles" value="Viewer" disabled />
											<span className="custom-checkbox" />
											Viewer
										</label>
										<label>
											<Field type="checkbox" name="roles" value="Trader" disabled />
											<span className="custom-checkbox" />
											Trader
										</label>
										<label>
											<Field type="checkbox" name="roles" value="Initiator" disabled />
											<span className="custom-checkbox" />
											Initiator
										</label>
										<label>
											<Field type="checkbox" name="roles" value="Approver" disabled />
											<span className="custom-checkbox" />
											Approver
										</label>
										<label>
											<Field type="checkbox" name="roles" value="beneficiary_creator" disabled />
											<span className="custom-checkbox" />
											Beneficiary creator
										</label>
									</div>
								</div>

								<Field
									name="message"
									component={Input}
									title="Message"
									placeholder="Enter a message"
									disable
								/>
								<Field
									name="two_factor_auth"
									component={Input}
									title="Two-Factor Authentication"
									placeholder="Enter 2FA code"
									required
									onChange={(e: any) => {
										formikRef.current?.handleChange(e);
										if (errorMessage === 'invalid_totp_code') {
											dispatch(setErrorMsg(''));
											formikRef.current?.setSubmitting(false);
											formikRef.current?.setFieldError('two_factor_auth', '');
										}
									}}
								/>
							</div>

							<div className="popup-footer">
								<div className="popup-submit popup-submit--column">
									<button
										type="submit"
										className="btn btn-primary btn--full"
										disabled={!values.two_factor_auth || isSubmitting || !isValid}
									>
										Add user
									</button>
									<button
										type="button"
										onClick={handleCloseModal}
										className="btn btn-danger btn--full"
									>
										Cancel
									</button>
								</div>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</Popup>
	);
};

export default NewUserRequests;

/* eslint-disable @typescript-eslint/no-explicit-any */
import fileDefault from './img/file-blank-solid-240.png';
import fileCSS from './img/file-css-solid-240.png';
import filePdf from './img/vscode-icons_file-type-pdf2.svg';
import filePng from './img/file-png-solid-240.png';
import fileJpg from './img/file-jpg-solid-240.png';

const handleUploadIcon = (type: string | undefined) => {
	switch (type) {
		case 'pdf':
			return filePdf;
		case 'png':
			return filePng;
		case 'jpeg':
			return fileJpg;
		case 'jpg':
			return fileJpg;
		default:
			return fileDefault;
	}
};
export default handleUploadIcon;

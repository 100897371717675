export function formatRoles(roles: string[]): string {
	return roles
		.map((role) => {
			if (typeof role !== 'string') return '';

			const parts = role.split('_');
			if (parts.length === 0) return '';

			const [first, ...rest] = parts;

			const capitalizedFirst = first.charAt(0).toUpperCase() + first.slice(1).toLowerCase();
			const restJoined = rest.map((word) => word.toLowerCase()).join(' ');

			return restJoined ? `${capitalizedFirst} ${restJoined}` : capitalizedFirst;
		})
		.join(', ');
}

import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAccountRequestsDataSelector } from '../../../../../redux/reducers/multiAccounts/selectors';

interface TabsProps {
	activeTab: 'members' | 'new_requests';
	setActiveTab: React.Dispatch<React.SetStateAction<'members' | 'new_requests'>>;
}

const Tabs: React.FC<TabsProps> = ({ activeTab, setActiveTab }) => {
	const [newRequestsCount, setNewRequestsCount] = useState<number>(4);
	const history = useHistory();
	const location = useLocation();
	const accountRequestsDataLength = useSelector(getAccountRequestsDataSelector)?.count_new_requests;
	useEffect(() => {
		// Берем уже существующие параметры из URL
		const searchParams = new URLSearchParams(location.search);

		// Удаляем параметры, относящиеся к табам (если есть)
		searchParams.delete('members');
		searchParams.delete('new_requests');

		// Устанавливаем параметр для текущего активного таба
		searchParams.set(activeTab, 'true');

		// Обновляем URL с сохранением остальных параметров
		history.replace({ search: searchParams.toString() });
	}, [activeTab, history, location.search]);

	return (
		<div className="currencyButtonFile">
			<div style={{ width: '100%' }} className="currencyButton">
				<button
					type="button"
					style={{ width: '50%' }}
					className={activeTab === 'members' ? 'active' : ''}
					onClick={() => setActiveTab('members')}
				>
					<span>Members</span>
				</button>
				<button
					type="button"
					style={{ width: '50%' }}
					className={activeTab === 'new_requests' ? 'active' : ''}
					onClick={() => setActiveTab('new_requests')}
				>
					<span>New requests</span>
					{accountRequestsDataLength > 0 && (
						<span className="badge">{accountRequestsDataLength}</span>
					)}
				</button>
			</div>
		</div>
	);
};

export default Tabs;

import React, { FC } from 'react';
import Popup from 'reactjs-popup';

export interface IDeleteAccountNotePopup {
	open: boolean;
	closeModal: () => void;
	confirmHandler: (note_id: number) => void;
	note_id: number | null;
	loading: boolean;
}

const DeleteAccountNotePopup: FC<IDeleteAccountNotePopup> = ({
	open,
	closeModal,
	confirmHandler,
	note_id,
	loading,
}) => {
	return (
		<Popup open={open} closeOnDocumentClick onClose={closeModal}>
			<div className="popup">
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
				<button type="button" className="popup__close-btn" onClick={closeModal} />
				<div className="popup-header popup-header--mb-20">
					<p className="popup-header__title">Delete Note</p>
				</div>
				<div className="popup-icon popup-icon--type2">
					<svg
						width="58"
						height="58"
						viewBox="0 0 58 58"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M29 54C42.7591 54 54 42.7591 54 29C54 15.2409 42.7591 4 29 4C15.2409 4 4 15.2409 4 29C4 42.7591 15.2409 54 29 54Z"
							fill="#FE0000"
							stroke="#FEECEB"
							strokeWidth="8"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M23.2122 22.375V22.35H23.1872H22.358V21.15H26.2497H26.2747V21.125V20.525H31.7247V21.125V21.15H31.7497H35.6413V22.35H34.8122H34.7872V22.375V34.25C34.7872 34.5763 34.6651 34.8617 34.4195 35.1073C34.1739 35.3529 33.8884 35.475 33.5622 35.475H24.4372C24.1001 35.475 23.8122 35.3553 23.572 35.1151C23.3319 34.875 23.2122 34.5871 23.2122 34.25V22.375ZM33.5872 22.375V22.35H33.5622H24.4372H24.4122V22.375V34.25V34.275H24.4372H33.5622H33.5872V34.25V22.375ZM27.8705 24.1708V32.4333H26.6705V24.1708H27.8705ZM31.3288 24.1708V32.4333H30.1288V24.1708H31.3288Z"
							fill="white"
							stroke="white"
							strokeWidth="0.05"
						/>
					</svg>
				</div>
				<div className="popup-text">
					<p>Are you sure you want to delete the note?</p>
				</div>
				<div className="popup-footer">
					<div className="popup-submit popup-submit--column">
						{!!note_id && (
							<button
								onClick={() => confirmHandler(note_id)}
								type="button"
								className="btn btn-primary btn--full"
								disabled={loading}
							>
								Delete
							</button>
						)}
						<button onClick={closeModal} type="button" className="btn btn-danger btn--full">
							Cancel
						</button>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default DeleteAccountNotePopup;

import React, { FC } from 'react';
import Popup from 'reactjs-popup';
import { useDispatch, useSelector } from 'react-redux';
import { IPopUp } from '../types';
import { getPopUp } from '../../../redux/reducers/popUp/selectors';

const NewUserRequestsInfo: FC<IPopUp> = ({ open, closeModal }) => {
	const handleCloseModal = () => {
		closeModal();
	};
	const popUpState = useSelector(getPopUp);
	return (
		<Popup open={open} nested onClose={handleCloseModal}>
			<div className="popup popup--width-480 NewUserRequestsInfo">
				<button
					type="button"
					className="popup__close-btn"
					onClick={handleCloseModal}
					aria-label="Close"
				/>
				<div className="popup-header popup-header--mb-20" style={{ textAlign: 'center' }}>
					<p
						style={{
							width: 300,
							margin: '0 auto',
							wordBreak: 'break-word',
							whiteSpace: 'normal',
							textAlign: 'center',
						}}
						className="popup-header__title"
					>
						User {(popUpState?.data as any)?.user_email} not found
					</p>
					<p style={{ width: 330, margin: '0 auto' }} className="popup-header__body">
						Please manually create an new account for this user at{' '}
						<a href="/user-management/">User management</a> page.
					</p>
				</div>

				<div className="popup-footer">
					<div className="popup-submit popup-submit--column">
						<button type="button" onClick={handleCloseModal} className="btn btn-primary btn--full">
							Close
						</button>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default NewUserRequestsInfo;

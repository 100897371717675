import { useDispatch, useSelector } from 'react-redux';
import React, { ChangeEvent, useEffect, useState } from 'react';
import IconSvg from '../../../../ui/Svg/IconSvg';
import ProgressBar from '../../../ProgressBar/ProgressBar';
import { getOneUser, getUsers } from '../../../../redux/reducers/users/selectors';
import {
	deleteUsersAdminFileRequest,
	downloadAdminFileRequest,
	getUsersAdminFilesRequest,
	uploadUsersAdminFilesRequest,
} from '../../../../redux/reducers/users/reducer';
import { IUsersAdminFiles } from '../../../../redux/reducers/users/types';
import handleUploadIcon from '../../../../ui/Formik/InputFile/config';

const AccountAdminFiles = () => {
	const dispatch = useDispatch();
	const { uploadProgress, uploadUsersAdminFilesLoader } = useSelector(getUsers);
	const user = useSelector(getOneUser);
	const [files, setFiles] = useState<IUsersAdminFiles | null>(null);

	const getFileNameWithExtension = (url: string): string => {
		const parts = url.split('/');
		return parts[parts.length - 1];
	};

	const getFileType = (url: string): string => {
		return url.split('.').pop() || '';
	};

	const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (user && user.id) {
			const userId = user.id.toString();
			const fileList = e.target.files;
			if (!fileList || fileList.length === 0) {
				console.error('At least one file must be selected');
				return;
			}
			const formData = new FormData();
			formData.append('user_id', userId);
			Array.from(fileList).forEach((file) => {
				formData.append('files[]', file);
			});
			const params = {
				files: formData,
				userId,
				onFinally: (response: IUsersAdminFiles | null) => {
					// if (response) {
					// 	setFiles((prevFiles) =>
					// 		prevFiles && prevFiles.length > 0 ? [...prevFiles, ...response] : response,
					// 	);
					// }
					if (response) setFiles(response);
				},
			};
			dispatch(uploadUsersAdminFilesRequest(params));
		}
	};

	const deleteHandler = (id: number) => {
		if (user && user.id) {
			const fileId = id.toString();
			const userId = user.id.toString();
			const params = {
				fileId,
				userId,
				onFinally: (response: IUsersAdminFiles | null) => {
					if (response) setFiles(response);
				},
			};
			dispatch(deleteUsersAdminFileRequest(params));
		}
	};

	const handleDownloadFile = (file: string) => {
		dispatch(downloadAdminFileRequest({ file: file.split('/')[0] }));
	};

	useEffect(() => {
		if (user && user.id) {
			const userId = user.id.toString();
			const params = {
				userId,
				onFinally: (response: IUsersAdminFiles | null) => {
					if (response) setFiles(response);
				},
			};
			dispatch(getUsersAdminFilesRequest(params));
		}
	}, [dispatch, user]);

	return (
		<>
			{!!files && files.admin_documents.length
				? files.admin_documents.map((file: any) => (
						<div key={file.id} className="account-admin-files__item">
							{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
							<button
								type="button"
								className="account-admin-files__delete"
								onClick={() => deleteHandler(file.id)}
							/>
							<div
								onClick={() => handleDownloadFile(file.url)}
								className="account-admin-files__link"
							>
								<img src={handleUploadIcon(getFileType(file.url))} alt="" />
								<span
									className="account-admin-files__name"
									title={getFileNameWithExtension(file.url)}
								>
									{getFileNameWithExtension(file.url)}
								</span>
							</div>
						</div>
				  ))
				: null}
			<div className="account-admin-files__upload">
				{uploadUsersAdminFilesLoader ? (
					<ProgressBar progress={uploadProgress} />
				) : (
					<label className="account-admin-files__upload-label">
						<input className="hidden" type="file" multiple value="" onChange={handleFileChange} />
						<IconSvg name="upload_2" />
					</label>
				)}
			</div>
		</>
	);
};
export default AccountAdminFiles;

import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPopUp } from 'redux/reducers/popUp/selectors';
import { getPopUpClose } from 'redux/reducers/popUp/reducer';
import VerificationPopUp from './Settings/VerificationPopUp';
import ResetPasswordPopUp from './Settings/ResetPasswordPopUp';
import StatusUpdatedConfirm from './Settings/StatusUpdatedConfirm';
import SuccessPopUp from './SuccessPopUp/SuccessPopUp';
import ErrorPopUp from './ErrorPopUp/ErrorPopUp';
import WarningPopUp from './WarningPopUp/WarningPopUp';
import SuccessUpdatePopUp from './SuccessUpdatePopUp/SuccessUpdatePopUp';
import CreateDepositAccountPopUp from './CreateDepositAccount/CreateDepositAccount';
import DeleteAdminPopUp from './DeleteAdminPopUp/DeleteAdminPopUp';
import AdminDeleted from './AdminDeleted';
import WalletBalancesTwoFaCodePopUp from './WalletBalancesTwoFaCodePopUp/WalletBalancesTwoFaCodePopUp';
import SecurityVerificationPopUp from './SecurityVerification/SecurityVerification';
import ResetTwoFaPopUp from './Settings/ResetTwoFaPopUp';
import CreatePassSuccessPopUp from './Settings/CreatePassSuccessPopUp';
import TwoFaSetupSuccessPopUp from './Settings/TwoFaSetupSuccessPopUp';
import SupportPopUp from './Settings/SupportPopUp';
import SuccessUpdateTwoFaPopUp from './Settings/SuccessUpdateTwoFaPopUp';
import SuccessUpdatePassword from './Settings/SuccessUpdatePassword';
import AddNewMember from './AddNewMember/AddNewMember';
import SuccessNewMemberPopUp from './SuccessNewMemberPopUp/SuccessNewMemberPopUp';
import NewUserRequests from './NewUserRequests/NewUserRequests';
import NewUserRequestsInfo from './NewUserRequests/NewUserRequestsInfo';

const PopUp = () => {
	const dispatch = useDispatch();
	const popUpState = useSelector(getPopUp);
	const [open, setOpen] = useState(false);
	const closeModal = () => {
		setOpen(false);
		dispatch(getPopUpClose());
	};
	useLayoutEffect(() => {
		setOpen(true);
	}, [popUpState.popUpText]);
	useLayoutEffect(() => {
		setOpen(false);
	}, []);
	const popUpContent = () => {
		switch (popUpState.popUpText) {
			case 'supportSuccess':
				return <SupportPopUp open={open} closeModal={closeModal} />;
			case 'verificationSuccess':
				return <VerificationPopUp open={open} closeModal={closeModal} />;
			case 'resetPasswordSuccess':
				return <ResetPasswordPopUp open={open} closeModal={closeModal} />;
			case 'StatusUpdated':
				return <StatusUpdatedConfirm open={open} closeModal={closeModal} {...popUpState.data} />;
			case 'successPopUp':
				return <SuccessPopUp open={open} closeModal={closeModal} {...popUpState.data} />;
			case 'successUpdatePopUp':
				return <SuccessUpdatePopUp open={open} closeModal={closeModal} {...popUpState.data} />;
			case 'SuccessNewMemberPopUp':
				return <SuccessNewMemberPopUp open={open} closeModal={closeModal} {...popUpState.data} />;
			case 'warningPopUp':
				return <WarningPopUp open={open} closeModal={closeModal} {...popUpState.data} />;
			case 'errorPopUp':
				return <ErrorPopUp open={open} closeModal={closeModal} {...popUpState.data} />;
			case 'deleteAdminPopUp':
				return <DeleteAdminPopUp open={open} closeModal={closeModal} {...popUpState.data} />;
			case 'AdminDeleted':
				return <AdminDeleted open={open} closeModal={closeModal} />;
			case 'walletBalancesTwoFaCodePopUp':
				return (
					<WalletBalancesTwoFaCodePopUp open={open} closeModal={closeModal} {...popUpState.data} />
				);
			case 'newUserRequests':
				return <NewUserRequests open={open} closeModal={closeModal} {...popUpState.data} />;
			case 'newUserRequestsInfo':
				return <NewUserRequestsInfo open={open} closeModal={closeModal} {...popUpState.data} />;
			case 'createDepositAccount':
				return (
					<CreateDepositAccountPopUp open={open} closeModal={closeModal} {...popUpState.data} />
				);
			case 'securityVerification':
				return (
					<SecurityVerificationPopUp open={open} closeModal={closeModal} {...popUpState.data} />
				);
			case 'resetTwoFaSuccess':
				return <ResetTwoFaPopUp open={open} closeModal={closeModal} />;
			case 'CreatePasswordSuccess':
				return <CreatePassSuccessPopUp open={open} closeModal={closeModal} />;
			case 'TwoFaSetupSuccess':
				return <TwoFaSetupSuccessPopUp open={open} closeModal={closeModal} />;
			case 'SuccessUpdateTwoFaPopUp':
				return <SuccessUpdateTwoFaPopUp open={open} closeModal={closeModal} />;
			case 'SuccessUpdatePassword':
				return <SuccessUpdatePassword open={open} closeModal={closeModal} />;
			case 'AddNewMember':
				return <AddNewMember open={open} closeModal={closeModal} />;
			default:
				null;
		}

		return null;
	};
	return <div>{popUpContent()}</div>;
};

export default PopUp;

import React, { useState, useEffect, useRef } from 'react';

// Пример enum
export enum ERoleType {
	ALL = 'all',
	ADMIN = 'admin',
	VIEWER = 'viewer',
	INITIATOR = 'initiator',
	TRADER = 'trader',
	APPROVER = 'approver',
	BENEFICIARY_CREATOR = 'beneficiary_creator',
}

interface MultiSelectProps {
	onChange: (selected: ERoleType[]) => void;
}

const FiltersSearchRoleArray = [
	{ id: 1, name: 'All', value: ERoleType.ALL, active: true },
	{ id: 2, name: 'Viewer', value: ERoleType.VIEWER, active: true },
	{ id: 3, name: 'Initiator', value: ERoleType.INITIATOR, active: true },
	{ id: 4, name: 'Trader', value: ERoleType.TRADER, active: true },
	{ id: 5, name: 'Approver', value: ERoleType.APPROVER, active: true },
	{ id: 6, name: 'Beneficiary creator', value: ERoleType.BENEFICIARY_CREATOR, active: true },
];

const MultiSelect: React.FC<MultiSelectProps> = ({ onChange }) => {
	const activeItems = FiltersSearchRoleArray.filter((item) => item.active);
	const allActiveValues = activeItems.map((item) => item.value);

	const [selectedValues, setSelectedValues] = useState<ERoleType[]>(allActiveValues);
	const [isOpen, setIsOpen] = useState(false);
	const containerRef = useRef<HTMLDivElement>(null);

	// Уведомим родителя о первоначальном состоянии
	useEffect(() => {
		onChange?.(allActiveValues);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
				setIsOpen(false);
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const handleCheckboxChange = (clickedValue: ERoleType) => {
		const clickedItem = activeItems.find((item) => item.value === clickedValue);
		const isAllClicked = clickedItem?.id === 1;

		let newSelectedValues: ERoleType[] = [...selectedValues];
		const allItemValues = activeItems.map((item) => item.value);
		const wasAllSelected = allItemValues.every((val) => newSelectedValues.includes(val));

		if (!isAllClicked && newSelectedValues.includes(ERoleType.ALL)) {
			newSelectedValues = newSelectedValues.filter((val) => val !== ERoleType.ALL);
		}

		if (isAllClicked) {
			if (newSelectedValues.includes(ERoleType.ALL)) {
				newSelectedValues = [];
			} else {
				newSelectedValues = activeItems.map((item) => item.value);
			}
		} else if (newSelectedValues.includes(clickedValue)) {
			newSelectedValues = newSelectedValues.filter((val) => val !== clickedValue);
		} else {
			newSelectedValues.push(clickedValue);
		}

		const allValuesExceptAll = activeItems
			.filter((item) => item.id !== 1)
			.map((item) => item.value);
		const allExceptAllSelected = allValuesExceptAll.every((val) => newSelectedValues.includes(val));

		if (allExceptAllSelected && !newSelectedValues.includes(ERoleType.ALL)) {
			newSelectedValues.push(ERoleType.ALL);
		} else if (!allExceptAllSelected) {
			newSelectedValues = newSelectedValues.filter((val) => val !== ERoleType.ALL);
		}

		if (wasAllSelected && newSelectedValues.length < allItemValues.length) {
			newSelectedValues = newSelectedValues.filter((val) => val !== ERoleType.ALL);
		}

		setSelectedValues(newSelectedValues);
		onChange?.(newSelectedValues);
	};

	const selectedNames = selectedValues
		.map((val) => activeItems.find((item) => item.value === val)?.name)
		.filter(Boolean);

	return (
		<div className="MultiSelect" ref={containerRef}>
			<div className="MultiSelect__label">Role</div>

			<div
				className={`MultiSelect__selected ${isOpen ? 'MultiSelect__selected--open' : ''}`}
				onClick={() => setIsOpen(!isOpen)}
			>
				{selectedNames.length > 0 ? selectedNames.join(', ') : 'Select roles...'}
				<span className="select__current-arrow">
					<svg
						width="9"
						height="5"
						viewBox="0 0 9 5"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M1.5 1L4.5 4L7.5 1" stroke="#173B58" strokeLinecap="square" />
					</svg>
				</span>
			</div>

			{isOpen && (
				<div className="MultiSelect__dropdown checkbox-role">
					<div className="checkbox">
						{activeItems.map((item) => (
							<label key={item.id}>
								<input
									type="checkbox"
									checked={selectedValues.includes(item.value)}
									onChange={() => handleCheckboxChange(item.value)}
								/>
								<span className="custom-checkbox" />
								{item.name}
							</label>
						))}
					</div>
				</div>
			)}
		</div>
	);
};

export default MultiSelect;

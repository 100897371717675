import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import DetailedView from 'components/DetailedView/DetailedView';
import { useSelector } from 'react-redux';
import TradesTable from './TradeTable';
import { getDetailedViewData } from '../../../redux/reducers/detailedView/selectors';

const TradesTab = () => {
	const detailedViewData = useSelector(getDetailedViewData);

	return (
		<>
			{detailedViewData?.show && <DetailedView />}
			<div className="user-management" style={{ display: detailedViewData?.show ? 'none' : '' }}>
				<Switch>
					<Route exact path={ROUTES.transfersAndTradeReports.trades.index}>
						<TradesTable />
					</Route>
					<Route
						path={`${ROUTES.transfersAndTradeReports.trades.index}/:id`}
						component={DetailedView}
					/>
					<Redirect to={ROUTES.transfersAndTradeReports.trades.index} />
				</Switch>
			</div>
		</>
	);
};
export default TradesTab;

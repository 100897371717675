import React, { FC, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	INIT_FILTER_OPTIONS,
	INIT_FILTER_OPTIONS_BANK,
	STATUS_FIAT_FILTER_OPTIONS_V2,
	TYPE_FILTER_OPTIONS,
} from 'redux/reducers/transactionsFilters/constants';
import { EFiltersType, ETransactionsFilters } from 'redux/reducers/transactionsFilters/types';
import { addFilter } from 'redux/reducers/transactionsFilters/reducer';
import { useLocation } from 'react-router';
import { getFiatCurrencyData } from 'redux/reducers/currency/selectors';
import FilterSelect from 'ui/Formik/Select/FilterSelect';
import FilterDateSelect from 'ui/Formik/Select/FilterDateSelect';
import SortedButton, { TSortedDirection } from 'ui/SortedButton';
import RangeSelect from 'ui/RangeSelect';
import {
	THistoryFiltersOption,
	THistoryFiltersTypeOption,
} from 'components/AccountDetails/TransactionHistoryTab/CryptoHistory/types';
import { EApiTransactionType, EFiatSortedFields } from 'services/api/transactions/types';
import { getDepositAccounts } from 'redux/reducers/walletBalance/selectors';
import { getDepositAccountRequest } from 'redux/reducers/walletBalance/reducer';
import { getAllBankAccountRequest } from 'redux/reducers/transactions/reducer';
import { ITransactionsTab } from '../../types';
import { getAllBankAccount } from '../../../../redux/reducers/transactions/selectors';

const FiatTransactionsHeader: FC<ITransactionsTab> = ({ type, setSorted }) => {
	const dispatch = useDispatch();
	// new
	//
	useLayoutEffect(() => {
		dispatch(getAllBankAccountRequest());
		dispatch(getDepositAccountRequest({}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const allBankAccount = useSelector(getAllBankAccount);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const allBankDepositAccount = useSelector(getDepositAccounts);
	const [backArrayDepositWithdrawals, setBackArrayDepositWithdrawals] = useState([]);
	const { pathname } = useLocation();

	useLayoutEffect(() => {
		if (type === 'withdrawal') {
			setBackArrayDepositWithdrawals([...allBankAccount]);
		}
		if (type === 'deposit') {
			if (allBankDepositAccount !== null) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				// eslint-disable-next-line no-unsafe-optional-chaining
				setBackArrayDepositWithdrawals([...allBankDepositAccount.data]);
			}
		}
		if (type === 'transfer') {
			if (allBankDepositAccount !== null) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				// eslint-disable-next-line no-unsafe-optional-chaining
				setBackArrayDepositWithdrawals([...allBankAccount, ...allBankDepositAccount.data]);
			}
		}
	}, [type, allBankAccount, allBankDepositAccount]);

	const [sortedGross, setSortedGross] = useState<TSortedDirection>('');
	const [sortedFee, setSortedFee] = useState<TSortedDirection>('');
	const [sortedNet, setSortedNet] = useState<TSortedDirection>('');

	const onSortedGross = (direction: TSortedDirection) => {
		if (direction) {
			!!setSorted && setSorted({ type: EFiatSortedFields.GROSS, value: direction });
		} else {
			!!setSorted && setSorted(null);
		}
		setSortedGross(direction);
		setSortedFee('');
		setSortedNet('');
	};

	const onSortedFee = (direction: TSortedDirection) => {
		if (direction) {
			!!setSorted && setSorted({ type: EFiatSortedFields.FEE, value: direction });
		} else {
			!!setSorted && setSorted(null);
		}
		setSortedFee(direction);
		setSortedNet('');
		setSortedGross('');
	};

	const onSortedNet = (direction: TSortedDirection) => {
		if (direction) {
			!!setSorted && setSorted({ type: EFiatSortedFields.NET, value: direction });
		} else {
			!!setSorted && setSorted(null);
		}
		setSortedNet(direction);
		setSortedFee('');
		setSortedGross('');
	};
	const handleAssetChange = ({ name, value }: THistoryFiltersOption) => {
		dispatch(
			addFilter({
				type: ETransactionsFilters.ASSET_ID,
				value: value as string,
				name,
				filterType: EFiltersType.TRANSACTIONS_FIAT,
			}),
		);
	};
	const handleTypeChange = ({ name, value }: THistoryFiltersOption) => {
		dispatch(
			addFilter({
				type: ETransactionsFilters.TYPE,
				value: value as string,
				name,
				filterType: EFiltersType.TRANSACTIONS_FIAT,
			}),
		);
	};

	const handleStatusChange = ({ name, value }: THistoryFiltersOption) => {
		dispatch(
			addFilter({
				type: ETransactionsFilters.STATUS,
				value: value as string,
				name,
				filterType: EFiltersType.TRANSACTIONS_FIAT,
			}),
		);
	};

	const onChangeDate = (startDate: Date, endDate: Date) => {
		dispatch(
			addFilter({
				type: ETransactionsFilters.DATE,
				value: { date_from: startDate, date_to: endDate },
				filterType: EFiltersType.TRANSACTIONS_FIAT,
			}),
		);
	};

	const onChangeGross = (from: string, to: string) => {
		dispatch(
			addFilter({
				name: 'Gross',
				value: { from, to },
				type: ETransactionsFilters.GROSS,
				filterType: EFiltersType.TRANSACTIONS_FIAT,
			}),
		);
	};
	const handleBankNameChange = (value: any) => {
		const typeFilter = () => {
			if (type === 'deposit') {
				return ETransactionsFilters.REQUISITE_NAME;
			}
			if (type === 'withdrawal') {
				return ETransactionsFilters.BANK_NAME;
			}

			return ETransactionsFilters.BANK;
		};
		dispatch(
			addFilter({
				type: typeFilter(),
				value: value?.bank_name === 'All' ? undefined : value?.bank_name,
				name: `Bank name:`,
				filterType: EFiltersType.TRANSACTIONS_FIAT,
			}),
		);
	};
	// const handleBankNameChangeNew = ({ option, value }: any) => {
	// 	// eslint-disable-next-line no-debugger
	// 	debugger;
	// 	dispatch(
	// 		addFilter({
	// 			type: ETransactionsFilters.BANK_NAME,
	// 			value: value as string,
	// 			name: `Bank name: ${name}`,
	// 			filterType: EFiltersType.TRANSACTIONS_FIAT,
	// 		}),
	// 	);
	// };

	const fiatCurrencyData = useSelector(getFiatCurrencyData);
	const fiatCurrencyFilterOptions = fiatCurrencyData.map(({ id, code }) => ({
		id,
		name: code.toUpperCase(),
		value: id,
	}));
	const depositAccounts = useSelector(getDepositAccounts);
	// const depositAccountsFilterOptions =
	// 	depositAccounts?.data.map(({ id, bank_name }) => ({
	// 		id,
	// 		name: bank_name,
	// 		value: bank_name,
	// 	})) || [];

	return (
		<div className="tr">
			<div className="td">
				<div className="td-name">
					{/* <FilterDateSelect title="Timestamp" onChange={onChangeDate} /> */}
					<p>Timestamp</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					{/* <p>{pathname.includes('deposit') ? 'User Email' : 'User ID'}</p> */}
					<p>User ID</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Account ID</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Oneify ID</p>
				</div>
			</div>
			{type === EApiTransactionType.TRANSFER && (
				<div className="td">
					<div className="td-name">
						<FilterSelect
							title="Type"
							options={
								[...INIT_FILTER_OPTIONS, ...TYPE_FILTER_OPTIONS] as THistoryFiltersTypeOption[]
							}
							selected={INIT_FILTER_OPTIONS[0]}
							onChange={handleTypeChange}
						/>
					</div>
				</div>
			)}
			<div className="td">
				<div className="td-name">
					{/* <FilterSelect
						title="Currency"
						options={
							[...INIT_FILTER_OPTIONS, ...fiatCurrencyFilterOptions] as THistoryFiltersTypeOption[]
						}
						selected={INIT_FILTER_OPTIONS[0]}
						onChange={handleAssetChange}
					/> */}
					<p>Currency</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name td-name--flex">
					<RangeSelect title="Gross" onChange={onChangeGross} />
					<SortedButton direction={sortedGross} handleSort={onSortedGross} />
				</div>
			</div>
			<div className="td">
				<SortedButton direction={sortedFee} title="Fee" handleSort={onSortedFee} />
			</div>
			<div className="td">
				<SortedButton direction={sortedNet} title="Net" handleSort={onSortedNet} />
			</div>
			<div className="td">
				<div className="td-name">
					{/* <FilterSelect
						title="Bank Name"
						outputValue="bank_name"
						options={[...INIT_FILTER_OPTIONS_BANK, ...backArrayDepositWithdrawals] as any[]}
						selected={INIT_FILTER_OPTIONS[0]}
						onChange={handleBankNameChange}
					/> */}
					<p>{pathname.includes('deposit') ? 'Destination' : 'Beneficiary'}</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Tx ID</p>
				</div>
			</div>
			{/* {(pathname.includes('deposit') || pathname.includes('withdrawals')) && (
				<div className="td">
					<div className="td-name">
						<p>Notes</p>
					</div>
				</div>
			)} */}
			<div className="td">
				<div className="td-name">
					{/* <FilterSelect
						title="Status"
						options={
							[
								...INIT_FILTER_OPTIONS,
								...STATUS_FIAT_FILTER_OPTIONS_V2,
							] as THistoryFiltersTypeOption[]
						}
						selected={INIT_FILTER_OPTIONS[0]}
						onChange={handleStatusChange}
					/> */}
					<p>Status</p>
				</div>
			</div>
			<div className="td td--center">
				<div className="td-name">
					<p>Action</p>
				</div>
			</div>
		</div>
	);
};

export default FiatTransactionsHeader;

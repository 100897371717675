import React, { useEffect, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import Input from 'ui/Formik/Input';
import InputFile from 'ui/Formik/InputFile/InputFile';
import { CloseFileIcon } from 'assets/inline-svg';
import { useDispatch, useSelector } from 'react-redux';
import { getNameList } from 'country-list';
import handleUploadIcon from 'ui/Formik/InputFile/config';

import { getOneUser } from '../../../redux/reducers/users/selectors';
import DefaultFields from '../DefaultFields/DefaultFields';
import { roundingNumber } from '../../../services/utils/roundingNumber';
import { EApiCryptoTransactionStatus } from '../../../services/api/transactions/types';
import { getCurrencyData } from '../../../redux/reducers/currency/selectors';
import { ICountry } from '../../tables/CreateUserTables/UserTable';
import CountriesSelect from '../../../ui/Formik/Select/CountriesSelect';
import CurrencySelect from '../../../ui/Formik/Select/CurrencySelect';
import {
	createFiatDetailsRequest,
	deleteFiatDetailsFilesRequest,
	downloadFiatFileRequest,
} from '../../../redux/reducers/detailedView/reducer';
import { capitalizeWords } from '../../../services/utils/strings';

interface IDetailedViewFiatForm {
	item: any;
}

interface ICustomInput {
	label: string;
	value: string | number | undefined;
	type: string;
	disabled: boolean;
}

const CustomInput: React.FC<ICustomInput> = ({ label, value, type, disabled }) => {
	return (
		<div className="input">
			<label>
				<p className="input__name">{label}</p>
				<div className="input-wrapper">
					<input type={type} className="input-item" value={value} disabled={disabled} />
				</div>
			</label>
		</div>
	);
};

const DetailedViewFiatForm: React.FC<IDetailedViewFiatForm> = ({ item }) => {
	const dispatch = useDispatch();
	const userData = useSelector(getOneUser);
	const currencies = useSelector(getCurrencyData);
	const [fileList, setFileList] = useState<any>([]);
	const [countries, setCountries] = useState<ICountry[]>([]);
	const [selectedBeneficiaryCountry, setSelectedBeneficiaryCountry] = useState<ICountry>({
		value: '',
		label: '',
	});
	const [selectedBankCountry, setSelectedBankCountry] = useState<ICountry>({
		value: '',
		label: '',
	});
	const [selectedCurrency, setSelectedCurrency] = useState<any>();
	// const [initVals, setInitVals] = useState<any>();
	const [resetCustomSelect, setResetCustomSelect] = useState(false);

	const description =
		item.bank_account && item.bank_account.bank_name && item.bank_account.account_number
			? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			  `${item.bank_account.bank_name} ${item.bank_account.account_number}`
			: '-';

	const initialValues = {
		description: item.description || description || '-',
		reference: item.reference || '-',
		bank_nickname: item?.bank_account?.bank_nickname || '-',
		beneficiary_name: item?.bank_account?.beneficiary_name || '-',
		beneficiary_address: item?.bank_account?.beneficiary_address || '-',
		beneficiary_country: '',
		bank_name: item?.bank_account?.bank_name || '-',
		swift_bic: item?.bank_account?.swift_bic || '-',
		bank_address: item?.bank_account?.bank_address || '-',
		bank_country: item?.bank_account?.bank_country || '-',
		bank_currency: item?.bank_account?.bank_currency || '-',
		account_number: item?.bank_account?.account_number || '-',
		beneficiary_notes: item?.bank_account?.note || '',
		client_notes: item.client_notes || '',
		admin_notes: item.admin_notes || '',
	};

	const validationSchema = yup.object().shape({
		admin_notes: yup.string().max(255, 'Maximum length is 255 characters'),
		client_notes: yup.string().max(255, 'Maximum length is 255 characters'),
		reference: yup.string().max(255, 'Maximum length is 255 characters'),
		description: yup.string().max(255, 'Maximum length is 255 characters'),
	});

	const deleteFilesHandler = (
		event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
		file: string,
	) => {
		event.stopPropagation();
		const payload = {
			id: item.id,
			files: [file],
		};
		dispatch(deleteFiatDetailsFilesRequest(payload));
	};

	useEffect(() => {
		/* fetch('https://valid.layercode.workers.dev/list/countries?format=select&flags=false&value=code')
			.then((response) => response.json())
			.then((data) => {
				setCountries(data.countries);
			})
			.catch((error) => console.log(error)); */
		const result = Object.entries(getNameList()).map(([key, value]) => ({
			label: capitalizeWords(key),
			value,
		}));
		setCountries(result);
	}, []);

	useEffect(() => {
		const findBeneficiaryCountry = countries.filter(
			(el: any) => el.value === item?.bank_account?.beneficiary_country,
		);
		const findBankCountry = countries.filter(
			(el: any) => el.value === item?.bank_account?.bank_country,
		);
		const findCurrency = currencies.filter(
			(el: any) => el.id === item?.bank_account?.bank_currency,
		);
		setSelectedBeneficiaryCountry(findBeneficiaryCountry[0]);
		setSelectedBankCountry(findBankCountry[0]);
		setSelectedCurrency(findCurrency[0]);
	}, [countries, currencies, item]);

	const handleDownloadFile = (file: string) => {
		dispatch(downloadFiatFileRequest({ file: file.split('/')[0] }));
	};

	return (
		<div className="detailed-view-form">
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={(value, { resetForm, setSubmitting }) => {
					setSubmitting(true);
					const formData = new FormData();
					fileList &&
						fileList.length &&
						fileList.forEach((file: File) => formData.append(`files[]`, file));
					const requestBody = {
						id: item.id,
						client_notes: value.client_notes,
						admin_notes: value.admin_notes,
						reference: value.reference,
						description: value.description,
					};
					Object.keys(requestBody).forEach((key) => {
						const valueRequest = requestBody[key as keyof typeof requestBody];
						if (valueRequest !== undefined) {
							formData.append(key, valueRequest.toString());
						}
					});
					dispatch(
						createFiatDetailsRequest({
							id: item.id,
							body: formData,
						}),
					);
					setFileList([]);
					resetForm();
					setSubmitting(false);
				}}
				enableReinitialize
				validateOnBlur
			>
				{({
					setFieldTouched,
					touched,
					values,
					errors,
					isValid,
					dirty,
					setFieldValue,
					isSubmitting,
					setTouched,
				}) => (
					<>
						<Form>
							<div className="input-wrap--col-2">
								{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
								{/* @ts-ignore */}
								<DefaultFields userData={userData} item={item} />
								<CustomInput
									label="Currency"
									// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
									value={`${item.asset?.code.toUpperCase()} ${item.asset?.name}`}
									type="text"
									disabled
								/>
								<CustomInput
									label="Gross"
									value={`${roundingNumber(
										item.gross_fee,
										item?.asset?.code,
										// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
									)} ${item?.asset?.code.toUpperCase()}`}
									type="text"
									disabled
								/>
								<CustomInput
									label="Fee"
									value={`${roundingNumber(
										item.fee,
										item?.asset?.code,
										// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
									)} ${item?.asset?.code.toUpperCase()}`}
									type="text"
									disabled
								/>
								<CustomInput
									label="Net"
									value={`${roundingNumber(
										item.net_fee,
										item?.asset?.code,
										// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
									)} ${item?.asset?.code.toUpperCase()}`}
									type="text"
									disabled
								/>
								<CustomInput
									label="Destination"
									value={`${
										// eslint-disable-next-line @typescript-eslint/restrict-template-expressions, no-nested-ternary
										item.bank_account && item.bank_account.bank_name
											? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
											  `${item.bank_account.bank_name} ${item.bank_account.account_number}`
											: '-'
									}`}
									type="text"
									disabled
								/>
								{!item.status.includes('pending') && (
									<>
										<CustomInput label="Tx ID" value={item.id} type="text" disabled />
										<CustomInput
											label="Status"
											value={
												// eslint-disable-next-line @typescript-eslint/ban-ts-comment
												// @ts-ignore
												item.status === EApiCryptoTransactionStatus.ADMIN_PENDING
													? `Pending`
													: // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
													  `${item.status.charAt(0).toUpperCase()}${item.status.slice(1)}`
											}
											type="text"
											disabled
										/>
									</>
								)}
								{item.status.includes('pending') && (
									<>
										<CustomInput label="Tx ID" value={item.id} type="text" disabled />
									</>
								)}

								<Field
									title="Description"
									type="text"
									placeholder=""
									name="description"
									component={Input}
									onBlur={() => setFieldTouched('description')}
								/>
								<Field
									title="Reference"
									type="text"
									placeholder=""
									name="reference"
									component={Input}
									onBlur={() => setFieldTouched('reference')}
								/>
								<Field
									title="Bank Nickname"
									type="text"
									placeholder=""
									name="bank_nickname"
									component={Input}
									disable
								/>
								<Field
									title="Beneficiary Name"
									type="text"
									placeholder=""
									name="beneficiary_name"
									component={Input}
									disable
								/>
								<Field
									title="Beneficiary Address"
									type="text"
									placeholder=""
									name="beneficiary_address"
									component={Input}
									disable
								/>
								<div className="input-wrapper">
									<p className="input__name">Beneficiary Country</p>
									<Field
										type="text"
										searchField
										name="beneficiary_country"
										component={CountriesSelect}
										arr={countries}
										setTouched={() => setFieldTouched('beneficiary_country')}
										touched={touched.beneficiary_country}
										resetCustomSelect={resetCustomSelect}
										setResetCustomSelect={setResetCustomSelect}
										activeValue={selectedBeneficiaryCountry}
										disabled
									/>

									<div className="error-red">
										<ErrorMessage
											className="error-message"
											name="beneficiary_country"
											component="span"
										/>
									</div>
								</div>
								<Field
									title="Bank Name"
									type="text"
									placeholder=""
									name="bank_name"
									component={Input}
									disable
								/>
								<Field
									title="SWIFT / BIC"
									type="text"
									placeholder=""
									name="swift_bic"
									component={Input}
									disable
								/>
								<Field
									title="Bank Address"
									type="text"
									placeholder=""
									name="bank_address"
									component={Input}
									disable
								/>
								<div className="input-wrapper">
									<p className="input__name">Bank Country</p>
									<Field
										type="text"
										searchField
										name="bank_country"
										component={CountriesSelect}
										arr={countries}
										setTouched={() => setFieldTouched('beneficiary_country')}
										touched={touched.bank_country}
										resetCustomSelect={resetCustomSelect}
										setResetCustomSelect={setResetCustomSelect}
										activeValue={selectedBankCountry}
										disabled
									/>

									<div className="error-red">
										<ErrorMessage
											className="error-message"
											name="beneficiary_country"
											component="span"
										/>
									</div>
								</div>
								<div className="input-wrapper">
									<p className="input__name">Currency</p>
									<Field
										type="text"
										searchField
										name="asset"
										component={CurrencySelect}
										arr={currencies}
										resetCustomSelect={resetCustomSelect}
										setResetCustomSelect={setResetCustomSelect}
										activeValue={selectedCurrency}
										disabled
									/>

									<div className="error-red">
										<ErrorMessage
											className="error-message"
											name="beneficiary_country"
											component="span"
										/>
									</div>
								</div>
								<Field
									title="IBAN / Account number"
									type="text"
									placeholder=""
									name="account_number"
									component={Input}
									disable
								/>
								{/* <CustomInput label="Oneify ID" value={item?.user?.oneify_id} type="text" disabled /> */}
							</div>
							<Field
								title="Beneficiary Notes"
								type="textarea"
								placeholder=""
								name="beneficiary_notes"
								component={Input}
								disable
							/>
							<Field
								title="Admin Notes"
								type="textarea"
								placeholder=""
								name="admin_notes"
								component={Input}
								onBlur={() => setFieldTouched('admin_notes')}
							/>
							<Field
								title="Client Notes"
								type="textarea"
								placeholder=""
								name="client_notes"
								component={Input}
								onBlur={() => setFieldTouched('client_notes')}
							/>

							<div className="input">
								<p className="input__name">Upload File</p>
								<InputFile fileList={fileList} setFileList={setFileList} />
							</div>

							<div className="input">
								<p className="input__name">Attached Files</p>
								<div className="input__links">
									{item.files?.length > 0 ? (
										item.files.map((file: string, idx: number) => {
											return (
												<div
													key={`${file}-${idx.toString()}`}
													className="input-file-preview__item"
													onClick={() => handleDownloadFile(file)}
												>
													<img src={handleUploadIcon(file.split('.')[1])} alt="" />
													<span className="input-file-preview__name" title={file}>
														{file}
													</span>
													<span
														className="input-file-preview__item__del"
														onClick={(event) => deleteFilesHandler(event, file)}
													>
														<CloseFileIcon />
													</span>
												</div>
											);
										})
									) : (
										<div>No data</div>
									)}
								</div>
							</div>

							<button
								type="submit"
								className="btn btn-primary btn--full"
								style={{ marginTop: 14 }}
								disabled={isSubmitting}
							>
								Submit
							</button>
						</Form>
					</>
				)}
			</Formik>
		</div>
	);
};

export default DetailedViewFiatForm;

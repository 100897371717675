import axios from 'axios';
import { api } from 'services';
import { PayloadAction } from '@reduxjs/toolkit';
import { IReferralItem } from 'redux/reducers/referrals/types';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { notificationContainer } from 'services/utils/notificationContainer';

import {
	checkFireblockId,
	createUserGroupRequest,
	createUserNoteRequest,
	createUserRequest,
	createUserRequestFinished,
	deleteUserNoteRequest,
	deleteUserReferralRequest,
	deleteUsersAdminFileRequest,
	disableLoader,
	downloadAdminFileRequest,
	downloadFileRequest,
	downloadFileSuccess,
	generateSecretForZip,
	getAdminRequiredEmailsAccountRequest,
	getAdminRequiredEmailsAccountSuccess,
	getAdminRequiredEmailsRequest,
	getAdminRequiredEmailsUserRequest,
	getAdminRequiredEmailsUserSuccess,
	getAdminResetDataRequest,
	getAdminResetPasswordRequestsSuccess,
	getAdminResetTwoFaDataRequest,
	getAdminResetTwoFaDataSuccess,
	getOneUserError,
	getOneUserRequest,
	getOneUserSuccess,
	getResetRequests,
	getResetRequestsSuccess,
	getUserFeeFiatRequest,
	getUserFeeFiatSuccess,
	getUserFeeFiatUpdateRequest,
	getUserFeeRequest,
	getUserFeeSpreadSuccess,
	getUserFeeSuccess,
	getUserFeeUpdateRequest,
	getUserGroupsRequest,
	getUserNotesRequest,
	getUserPasswordRequestsSuccess,
	getUserPasswordResetRequests,
	getUserReferralRequest,
	getUserReferralSuccess,
	getUserRequiredEmailsAdditionalRequest,
	getUserRequiredEmailsAdditionalSuccess,
	getUserRequiredEmailsRequest,
	getUserRequiredEmailsSuccess,
	getUsersAdminFilesRequest,
	getUsersFileRequest,
	getUserSpreadFeeRequest,
	getUsersRequest,
	getUsersSuccess,
	getUserTradingLimitsRequest,
	getUserTradingLimitsSuccess,
	getUserTransferLimitsRequest,
	getUserTransferLimitsSuccess,
	getUserWalletsRequest,
	getUserWalletsSuccess,
	putAdminRequiredEmailsAccountRequest,
	putAdminRequiredEmailsRequest,
	putAdminRequiredEmailsUserRequest,
	putUserChangeReferralRequest,
	putUserFeesRequest,
	putUserLiquidityProviderRequest,
	putUserLiquidityProviderSuccess,
	putUserRequiredEmailsAdditionalRequest,
	putUserRequiredEmailsRequest,
	putUserSpreadFeesRequest,
	putUserTradingLimitRequest,
	putUserTransferLimitRequest,
	referralSystemRequest,
	referralSystemSuccess,
	reject2FA,
	rejectAdmin2FA,
	rejectAdminPassword,
	rejectUserPassword,
	reset2FA,
	resetAdmin2FA,
	resetAdminPassword,
	resetAllLoaders,
	resetUserPassword,
	setAdminAccountToggler,
	setAdminToggler,
	setAdminUserToggler,
	setFireblockIdRequest,
	setFireblockIdSuccess,
	setToggler,
	setTogglerAdditional,
	setUserStatus,
	setUserStatusSuccess,
	updateBalancesRequest,
	updateBalancesSuccess,
	updateUserArchiveStatus,
	updateUserBlockStatus,
	updateUserDataRequest,
	updateUserNoteRequest,
	updateUserSpreadFeesRequest,
	uploadUsersAdminFilesRequest,
	uploadUsersAdminFilesSuccess,
} from 'redux/reducers/users/reducer';

import {
	IApiGetOneUserParams,
	IApiGetOneUserResponse,
	IDeleteUserReferalRequestPayload,
	IGetUserGroupsResponse,
	ILiquidityProviderRequest,
	IRequestBlocked,
	ISecretCodeResponse,
	ITradingLimitsRequest,
	ITradingLimitsResponse,
	ITransferLimitsRequest,
	ITransferLimitsResponse,
	IUpdateUserPayload,
	IUserArchivedRequest,
	IUserEmailsPutRequestPayload,
	IUserFireBlockIdPayload,
	IUserIdPayload,
	IUserReferralRequest,
	IUserRequiredEmailsTypeUser,
	IUsersRequest,
	IUserWalletsRequest,
	IUserWalletsResponse,
} from 'services/api/users/types';
import { getParameterByName } from 'utils/paramNames';
import { responseErrors } from 'services/http/responseErrors';
import {
	IAdminPasswordResetRequest,
	IAdminTwoFaRequestItem,
	TwoFaItem,
} from 'components/ResetRequest/ResetRequestItem/types';

import { WEB_API_URL } from 'services/constants/env';
import {
	I2FARejectPayload,
	I2FAResetPayload,
	IAdminTogglerPayload,
	ICheckFireblockIdRequest,
	ICreateUserGroupRequest,
	ICreateUserNoteRequest,
	IDeleteUserNoteRequest,
	IDeleteUsersAdminFileRequest,
	IGetUserGroupsRequest,
	IGetUserNotesRequest,
	IGetUsersAdminFilesRequest,
	IPagination,
	IRequiredEmailsResponse,
	ITogglerPayload,
	IUpdateBalancesPayload,
	IUpdateUserNoteRequest,
	IUploadUsersAdminFilesRequest,
	IUserFeesRequest,
	IUserFeesUpdteRequestPayload,
	IUserFeesUpdteSpreadRequestPayload,
	IUserFileDownloadPayload,
	IUserReferralRequestPayload,
	IUsersAdminFiles,
	IUsersFeeResponse,
	IUsersFeeSpreadResponse,
	IUsersWithPagination,
} from './types';

import {
	getRequiredEmails,
	getRequiredEmailsAccount,
	getRequiredEmailsAdditional,
	getRequiredEmailsUser,
	getUserAdmin,
} from './selectors';
import { getSecretTokenForFileDownload } from '../auth/selectors';
import { IGetFilePayload } from '../../../services/api/transactions/types';
import { ICreateNewPasswordFormData } from '../auth/types';
import { createNewPasswordRequest } from '../auth/reducer';
import { notificationsMessagesInfo } from '../../../services/utils/notificationsMessages/notificationsMessagesInfo';
import { popUpOpen, setPopUpData } from '../popUp/reducer';
import { setErrorMsg } from '../errors/reducer';
import { formatErrorMsg } from '../../../utils/formatErrorMsg';

function* getUsersRequestWorker(action: PayloadAction<IUsersRequest>) {
	const { payload } = action;
	try {
		if (payload?.permission) {
			const response: IUsersWithPagination = yield call(api.users.getUsersPermission, payload);
			yield put(getUsersSuccess(response));
		} else {
			const response: IUsersWithPagination = yield call(api.users.getUsers, payload);
			yield put(getUsersSuccess(response));
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* updateUserBlockStatusWorker({ payload }: PayloadAction<IRequestBlocked>) {
	const { query, id, event } = payload;

	try {
		yield call(api.users.updateUserBlockStatus, { id, event });
		if (query) {
			yield put(getUsersRequest(query));
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* updateUserArchiveStatusWorker({ payload }: PayloadAction<IUserArchivedRequest>) {
	const { query, userId, event } = payload;
	try {
		yield call(api.users.updateUserArchiveStatus, { userId, event });
		if (query) yield put(getUsersRequest(query));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getOneUserWorker({ payload }: PayloadAction<IApiGetOneUserParams>) {
	try {
		const data: IApiGetOneUserResponse = yield call(api.users.getOneUser, payload);

		yield put(getOneUserSuccess(data));
	} catch (error) {
		yield put(getOneUserError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getUserWalletsWorker({ payload }: PayloadAction<IUserWalletsRequest>) {
	try {
		const data: IUserWalletsResponse = yield call(api.users.getUserWallets, payload);

		yield put(getUserWalletsSuccess(data));
	} catch (error) {
		// notificationContainer('Error', 'error')
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getUserReferralWorker({ payload }: PayloadAction<IUserReferralRequest>) {
	try {
		const data: IPagination<IReferralItem> = yield call(api.users.getUserReferral, payload);

		yield put(getUserReferralSuccess(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* putUserReferralWorker({ payload }: PayloadAction<ILiquidityProviderRequest>) {
	try {
		yield call(api.users.getUserLiquidityProvider, payload);
		yield put(getOneUserRequest({ userId: payload.userId }));
		yield put(putUserLiquidityProviderSuccess());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* deleteUserReferralWorker({ payload }: PayloadAction<IDeleteUserReferalRequestPayload>) {
	try {
		yield call(api.users.deleteUserReferral, payload);
		yield put(setPopUpData({ message: notificationsMessagesInfo.referralDeleteSuccess }));
		yield put(popUpOpen('successPopUp'));
	} catch (error) {
		// notificationContainer('Error', 'error')
	}
}

function* referralSystemWorker({ payload }: PayloadAction<any>) {
	try {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const data: never = yield call(api.users.referralSystem, payload);
		yield put(referralSystemSuccess(data));
	} catch (error) {
		// notificationContainer('Error', 'error')
	}
}

function* getUserTradingLimitsWorker({ payload }: PayloadAction<ITradingLimitsRequest>) {
	try {
		const data: ITradingLimitsResponse = yield call(api.users.getUserTradingLimits, payload);
		yield put(getUserTradingLimitsSuccess(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getUserTransferLimitsWorker({ payload }: PayloadAction<ITransferLimitsRequest>) {
	try {
		const data: ITransferLimitsResponse = yield call(api.users.getUserTransferLimits, payload);
		yield put(
			getUserTransferLimitsSuccess({
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				data,
			}),
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* putUserTradingLimitWorker({ payload }: PayloadAction<any>) {
	const { onSuccess } = payload;
	try {
		yield call(api.users.updateUserTradingLimit, payload);
		yield put(onSuccess?.());
		// yield put(getUserTradingLimitsRequest({ id: payload.userId }));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			const errorMessage = error.response?.data?.errors[0];
			if (errorMessage.includes('the_datalimit_max_must_be_greater_than')) {
				notificationContainer(
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					`Trade limit must be greater than ${errorMessage.split('_').slice(-1)}.`,
					'amount_error',
					'Limit is too low',
				);
			} else {
				responseErrors(error);
			}
		}
	}
}

function* putUserTransferLimitWorker({ payload }: PayloadAction<any>) {
	const { onSuccess } = payload;
	try {
		yield call(api.users.updateUserTransferLimit, payload);
		yield put(onSuccess?.());
		// yield put(getUserTradingLimitsRequest({ id: payload.userId }));
	} catch (error) {
		yield put(disableLoader());
		if (axios.isAxiosError(error)) {
			const errorMessage = error.response?.data?.errors[0];
			if (errorMessage.includes('the_datalimit_max_must_be_greater_than')) {
				notificationContainer(
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					`Trade limit must be greater than ${errorMessage.split('_').slice(-1)}.`,
					'amount_error',
					'Limit is too low',
				);
			} else if (errorMessage.includes('the_maximum_must_be_greater_than')) {
				notificationContainer(
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					'Incorrect data',
					'amount_error',
					'Error',
				);
			} else {
				error.response?.data?.errors.forEach((errorMsg: string) => {
					notificationContainer(formatErrorMsg(errorMsg), 'amount_error', 'Error');
				});
			}
		}
	}
}

function* createUserWorker({ payload }: any) {
	const { onSuccess, apiPayload } = payload;
	try {
		const validFireblockId: { status: string } = yield call(api.users.getCheckValidFireblockId, {
			fireblockId: apiPayload.user_data.fireblocks_vault_id,
		});
		if (validFireblockId.status === 'success') {
			const response: { confirm_url: string; user_id: number } = yield call(
				api.users.createUser,
				apiPayload,
			);
			yield put(onSuccess?.(response.user_id));
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error?.response?.data?.errors[0] === 'fireblocks_vault_already_in_use') {
				notificationContainer(
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					`Vault ID ${apiPayload.user_data.fireblocks_vault_id} is already used`,
					'user_blocked',
					'Error',
				);
			} else if (error?.response?.data?.errors[0] === 'fireblocks_vault_not_exists_yet') {
				notificationContainer(
					'Please use an existing vault ID or create a new vault in the Fireblocks console.',
					'user_blocked',
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					`Fireblocks ID ${apiPayload.user_data.fireblocks_vault_id} does not exist`,
				);
			} else {
				responseErrors(error);
			}
			yield put(createUserRequestFinished());
		}
	}
}

function* getResetRequestsWorker({ payload }: any) {
	try {
		const response: IPagination<TwoFaItem> = yield call(api.users.getResetRequests, payload);
		yield put(getResetRequestsSuccess(response));
	} catch (error) {
		yield put(resetAllLoaders());
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* twoFAResetRequestWorker(action: PayloadAction<I2FAResetPayload>) {
	const { payload } = action;
	const pageNumber = getParameterByName('page');

	try {
		yield call(api.users.reset2FA, payload);
		// yield put(reset2FASuccess());
		yield put(getResetRequests({ current_page: pageNumber }));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
			yield put(getResetRequests({ current_page: pageNumber }));
		}
	}
}

// =============================================================:
function* twoFARejectRequestWorker(action: PayloadAction<I2FARejectPayload>) {
	const { payload } = action;
	const pageNumber = getParameterByName('page');

	try {
		yield call(api.users.reject2FA, payload);
		// yield put(reject2FASuccess());
		yield put(getResetRequests({ current_page: pageNumber }));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
			yield put(getResetRequests({ current_page: pageNumber }));
		}
	}
}

function* userRequiredEmailsAdditionalWorker(action: PayloadAction<IUserRequiredEmailsTypeUser>) {
	const { payload } = action;

	try {
		const responseUser: IRequiredEmailsResponse = yield call(
			api.users.getUserRequiredEmailsAdditionalTypeUser,
			payload,
		);
		const responseAdmin: IRequiredEmailsResponse = yield call(
			api.users.getUserRequiredEmailsAdditionalTypeAdmin,
			payload,
		);
		yield put(getUserRequiredEmailsAdditionalSuccess({ ...responseUser, ...responseAdmin }));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* UserRequiredEmailsWorker(action: PayloadAction<IUserIdPayload>) {
	const { payload } = action;

	try {
		// const response: IRequiredEmailsResponse = yield call(api.users.getUserRequiredEmails, payload);
		const responseUser: IRequiredEmailsResponse = yield call(
			api.users.getUserRequiredEmailsTypeUser,
			payload,
		);
		const responseAdmin: IRequiredEmailsResponse = yield call(
			api.users.getUserRequiredEmailsTypeAdmin,
			payload,
		);
		yield put(getUserRequiredEmailsSuccess({ ...responseUser, ...responseAdmin }));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* PutUserRequiredEmailsWorker(action: PayloadAction<IUserEmailsPutRequestPayload>) {
	const { payload } = action;

	try {
		yield call(api.users.postUserRequiredEmails, payload);
		yield put(getUserRequiredEmailsRequest({ id: payload.id, type: payload.type }));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* PutUserRequiredEmailsAdditionalWorker(
	action: PayloadAction<IUserEmailsPutRequestPayload>,
) {
	const { payload } = action;

	try {
		yield call(api.users.postUserRequiredEmailsAdditional, payload);
		yield put(getUserRequiredEmailsAdditionalRequest({ id: payload.id, type: payload.type }));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* toggleRequiredEmailsWorker(action: PayloadAction<ITogglerPayload>) {
	const { payload } = action;
	const { toggler, id } = payload;
	const state: IRequiredEmailsResponse = yield select(getRequiredEmails);
	const type: boolean = yield select(getUserAdmin);
	try {
		yield put(
			putUserRequiredEmailsRequest({
				id,
				settings: { [payload.toggler]: state[toggler] },
				type,
			}),
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* toggleRequiredEmailsAdditionalWorker(action: PayloadAction<ITogglerPayload>) {
	const { payload } = action;
	const { toggler, id } = payload;
	const state: IRequiredEmailsResponse = yield select(getRequiredEmailsAdditional);
	const type: boolean = yield select(getUserAdmin);
	try {
		yield put(
			putUserRequiredEmailsAdditionalRequest({
				id,
				settings: { [payload.toggler]: state[toggler] },
				type,
			}),
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* AdminRequiredEmailsWorker() {
	try {
		const response: IRequiredEmailsResponse = yield call(api.users.getAdminRequiredEmails);
		yield put(getUserRequiredEmailsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* PutAdminRequiredEmailsWorker(action: PayloadAction<IUserEmailsPutRequestPayload>) {
	const { payload } = action;

	try {
		yield call(api.users.postAdminRequiredEmails, payload);
		yield put(getAdminRequiredEmailsRequest({}));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* toggleAdminRequiredEmailsWorker(action: PayloadAction<IAdminTogglerPayload>) {
	const { payload } = action;
	const { toggler } = payload;
	const state: IRequiredEmailsResponse = yield select(getRequiredEmails);
	try {
		yield put(
			putAdminRequiredEmailsRequest({
				settings: { [payload.toggler]: state[toggler] },
			}),
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* AdminRequiredEmailsUserWorker() {
	try {
		const response: IRequiredEmailsResponse = yield call(api.users.getAdminRequiredEmailsUser);
		yield put(getAdminRequiredEmailsUserSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* toggleAdminRequiredEmailsUserWorker(action: PayloadAction<IAdminTogglerPayload>) {
	const { payload } = action;
	const { toggler } = payload;
	const state: IRequiredEmailsResponse = yield select(getRequiredEmailsUser);
	try {
		yield put(
			putAdminRequiredEmailsUserRequest({
				settings: { [payload.toggler]: state[toggler] },
			}),
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* PutAdminRequiredEmailsUserWorker(action: PayloadAction<IUserEmailsPutRequestPayload>) {
	const { payload } = action;

	try {
		yield call(api.users.postAdminRequiredEmailsUser, payload);
		yield put(getAdminRequiredEmailsUserRequest({}));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* AdminRequiredEmailsAccountWorker() {
	try {
		const response: IRequiredEmailsResponse = yield call(api.users.getAdminRequiredEmailsAccount);
		yield put(getAdminRequiredEmailsAccountSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* toggleAdminRequiredEmailsAccountWorker(action: PayloadAction<IAdminTogglerPayload>) {
	const { payload } = action;
	const { toggler } = payload;
	const state: IRequiredEmailsResponse = yield select(getRequiredEmailsAccount);
	try {
		yield put(
			putAdminRequiredEmailsAccountRequest({
				settings: { [payload.toggler]: state[toggler] },
			}),
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* PutAdminRequiredEmailsAccountWorker(action: PayloadAction<IUserEmailsPutRequestPayload>) {
	const { payload } = action;

	try {
		yield call(api.users.postAdminRequiredEmailsAccount, payload);
		yield put(getAdminRequiredEmailsAccountRequest({}));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* updateUserWorker(action: PayloadAction<IUpdateUserPayload>) {
	const { payload } = action;
	try {
		yield call(api.users.updateUser, payload);
		yield put(getOneUserRequest({ userId: payload.id }));
		yield notificationContainer(
			notificationsMessagesInfo.usersDataSuccessfullyUpdated,
			'success',
			'Changes saved',
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			const errorMessage = error.response?.data.errors[0];
			if (errorMessage.includes('the_useremail_must_be_a_valid_email_address')) {
				notificationContainer(notificationsMessagesInfo.userNotValidEmail, 'amount_error', 'Error');
			} else {
				responseErrors(error);
			}
		}
	}
}

function* getAdminPasswordResetDataWorker({ payload }: any) {
	try {
		const response: IPagination<IAdminPasswordResetRequest> = yield call(
			api.users.getAdminResetData,
			payload,
		);
		yield put(getAdminResetPasswordRequestsSuccess(response));
	} catch (error) {
		yield put(resetAllLoaders());
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getAdminTwoFaResetDataWorker({ payload }: any) {
	try {
		const response: IPagination<IAdminTwoFaRequestItem> = yield call(
			api.users.getAdminTwoFaRequestsData,
			payload,
		);
		yield put(getAdminResetTwoFaDataSuccess(response));
	} catch (error) {
		yield put(resetAllLoaders());
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* adminPasswordResetRequestWorker(action: PayloadAction<I2FAResetPayload>) {
	const { payload } = action;
	const pageNumber = getParameterByName('page');

	try {
		yield call(api.users.resetAdminPassword, payload);
		yield put(getAdminResetDataRequest({}));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
			yield put(getResetRequests({ current_page: pageNumber }));
		}
	}
}

// =============================================================:
function* adminPasswordRejectRequestWorker(action: PayloadAction<I2FARejectPayload>) {
	const { payload } = action;
	const pageNumber = getParameterByName('page');

	try {
		yield call(api.users.rejectAdminPassword, payload);
		yield put(getAdminResetDataRequest({}));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
			yield put(getResetRequests({ current_page: pageNumber }));
		}
	}
}

function* adminTwoFaApproveRequestWorker(action: PayloadAction<I2FAResetPayload>) {
	const { payload } = action;
	const pageNumber = getParameterByName('page');

	try {
		yield call(api.users.approveAdminTwoFaRequest, payload);
		yield put(getAdminResetTwoFaDataRequest({}));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
			yield put(getResetRequests({ current_page: pageNumber }));
		}
	}
}

// =============================================================:
function* adminTwoFaRejectRequestWorker(action: PayloadAction<I2FARejectPayload>) {
	const { payload } = action;
	const pageNumber = getParameterByName('page');

	try {
		yield call(api.users.rejectAdminTwoFaRequest, payload);
		yield put(getAdminResetTwoFaDataRequest({}));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
			yield put(getResetRequests({ current_page: pageNumber }));
		}
	}
}

// User Fee

function* getUserFeeWorker({ payload }: PayloadAction<IUserFeesRequest>) {
	try {
		const data: IUsersFeeResponse = yield call(api.users.getUserFee, payload);
		yield put(getUserFeeSuccess(data));
	} catch (error) {
		// yield put(getOneUserError());
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}
function* getUserFeeFiatWorker({ payload }: PayloadAction<IUserFeesRequest>) {
	try {
		const data: IUsersFeeResponse = yield call(api.users.getUserFee, payload);
		yield put(getUserFeeFiatSuccess(data));
	} catch (error) {
		// yield put(getOneUserError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}
/* eslint-disable */
function* updateUserFeesWorker({ payload }: PayloadAction<IUserFeesUpdteRequestPayload>) {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	const { id, type, current_page, per_page, feeType } = payload;
	try {
		yield call(api.users.putUserFee, payload);

		yield put(getUserFeeUpdateRequest({ id, type, current_page, per_page }));
		notificationContainer(
			notificationsMessagesInfo.putFeeRequest(feeType),
			'success',
			'Changes saved',
		);
	} catch (error) {
		// yield put(getOneUserError());
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getUserSpreadFeeWorker({ payload }: PayloadAction<IUserFeesRequest>) {
	try {
		const data: IUsersFeeSpreadResponse = yield call(api.users.getUserSpreadFee, payload);

		yield put(getUserFeeSpreadSuccess(data));
	} catch (error) {
		// yield put(getOneUserError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}
function* updateUserFeesSpreadWorker({
	payload,
}: PayloadAction<IUserFeesUpdteSpreadRequestPayload>) {
	// eslint-disable-next-line @typescript-eslint/naming-convention
	const { id, current_page, per_page, feeType } = payload;
	try {
		yield call(api.users.putUserSpreadFee, payload);
		yield put(updateUserSpreadFeesRequest({ id, current_page, per_page }));
		notificationContainer(
			notificationsMessagesInfo.putFeeRequest(feeType),
			'success',
			'Changes saved',
		);
	} catch (error) {
		// yield put(getOneUserError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}
function* putUserChangeReferralWorker({ payload }: PayloadAction<IUserReferralRequestPayload>) {
	const { user_id } = payload;
	try {
		yield call(api.users.putUserReferral, payload);
		notificationContainer(
			notificationsMessagesInfo.putUserChangeReferral,
			'success',
			'Changes saved',
		);
		yield put(getOneUserRequest({ userId: user_id }));
	} catch (error) {
		// yield put(getOneUserError());
		// if (axios.isAxiosError(error)) {
		// 	responseErrors(error);
		// }
	}
}

function* getUserPasswordResetRequestsWorker({ payload }: any) {
	try {
		const response: IPagination<IAdminPasswordResetRequest> = yield call(
			api.users.getUsersPasswordResetRequests,
			payload,
		);
		yield put(getUserPasswordRequestsSuccess(response));
	} catch (error) {
		yield put(resetAllLoaders());
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* userPasswordResetRequestWorker(action: PayloadAction<I2FAResetPayload>) {
	const { payload } = action;
	const pageNumber = getParameterByName('page');

	try {
		yield call(api.users.resetUserPassword, payload);
		yield put(getUserPasswordResetRequests({}));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
			yield put(getResetRequests({ current_page: pageNumber }));
		}
	}
}

// =============================================================:
function* userPasswordRejectRequestWorker(action: PayloadAction<I2FARejectPayload>) {
	const { payload } = action;
	const pageNumber = getParameterByName('page');

	try {
		yield call(api.users.rejectUserPassword, payload);
		yield put(getUserPasswordResetRequests({}));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
			yield put(getResetRequests({ current_page: pageNumber }));
		}
	}
}

function* setUserStatusWorker(action: PayloadAction<{ id: number; status: number }>) {
	const { payload } = action;
	try {
		const response: IApiGetOneUserResponse = yield call(api.users.setUserStatus, payload);
		yield put(setUserStatusSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getUsersFileWorker({ payload }: PayloadAction<IGetFilePayload>) {
	try {
		const token: string = yield select(getSecretTokenForFileDownload);
		const response: string = yield call(api.users.getUsersFile, {
			...payload,
			token,
		});
		// yield call(() => {
		// 	downloadFile(response, `users_list.${payload.fileType}`);
		// });
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* setUserFireblockIdWorker(action: PayloadAction<IUserFireBlockIdPayload>) {
	const { payload } = action;
	try {
		yield call(api.users.setUserFireblockId, payload);
		yield put(getOneUserRequest({ userId: payload.userId }));
		yield call(payload.closeModal);
		yield put(setFireblockIdSuccess());

		notificationContainer(
			'User Fireblocks ID has been successfully updated.',
			'success',
			'Changes saved',
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			const errorMessage = error.response?.data.errors[0];
			yield put(setErrorMsg(errorMessage));
			yield put(setFireblockIdSuccess());
			// responseErrors(error);
		}
	}
}

function* downloadUserDocuments({ payload }: PayloadAction<number>) {
	try {
		const response: ISecretCodeResponse = yield call(api.users.generateCodeForZip, payload);
		window.open(
			`${String(WEB_API_URL || '')}/admin/user/${String(payload)}/${
				response.secret
			}/download/documents`,
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* creareNewPasswordWorker({ payload }: PayloadAction<ICreateNewPasswordFormData>) {
	try {
		yield call(api.auth.newPassword, payload);
		// window.location.replace(ROUTES.login);
		yield put(popUpOpen('SuccessUpdatePassword'));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error?.response?.data?.errors[0] === 'token_incorrect') {
				notificationContainer(
					'You have already reset your password.<br>Please contact support if you need further assistance',
					'error',
				);
			} else {
				responseErrors(error);
			}
			console.log(error?.response?.data?.errors);
		}
	}
}

function* getCheckFireblockIdWorker({ payload }: PayloadAction<ICheckFireblockIdRequest>) {
	try {
		const response: { status: string } = yield call(api.users.getCheckValidFireblockId, payload);

		yield put(
			setPopUpData({
				payload: { userId: payload.userId, fireblocks_vault_id: payload.fireblockId },
			}),
		);
		yield put(popUpOpen('securityVerification'));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error?.response?.data?.errors[0] === 'fireblocks_vault_already_in_use') {
				notificationContainer(
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					`Vault ID ${payload.fireblockId} is already used`,
					'user_blocked',
					'Error',
				);
			} else if (error?.response?.data?.errors[0] === 'fireblocks_vault_not_exists_yet') {
				notificationContainer(
					'Please use an existing vault ID or create a new vault in the Fireblocks console.',
					'user_blocked',
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					`Fireblocks ID ${payload.fireblockId} does not exist`,
				);
			} else {
				responseErrors(error);
			}
			yield put(setFireblockIdSuccess());
			console.log(error?.response);
		}
		// if (axios.isAxiosError(error)) {
		// 	responseErrors(error);
		// 	yield put(setFireblockIdSuccess());
		// 	console.log(error?.response);
		// }
	}
}

function* updateBalancesWorker({ payload }: PayloadAction<IUpdateBalancesPayload>) {
	const { apiParams, onFinally } = payload;
	let response = '';
	try {
		// @ts-ignore
		response = yield call(api.balances.updateBalances, apiParams);
		// yield put(currencyDataRequest());
		// const data: IUserWalletsResponse = yield call(api.users.getUserWallets, apiParams);
		// yield put(getUserWalletsSuccess(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(updateBalancesSuccess());
	} finally {
		onFinally?.(response); // Here is redux saga callback function
	}
}

function* downloadFileWorker({ payload }: PayloadAction<IUserFileDownloadPayload>) {
	try {
		yield call(api.users.downloadFile, payload.file);
		window.location.replace(
			`${process.env.REACT_APP_WEB_API_URL as string}/admin/cloud/doc/download/${payload.file}`,
		);
		yield put(downloadFileSuccess());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(
				'Something went wrong, please try again later.',
				'user_blocked',
				'Error',
			);
		}
	} finally {
		yield put(downloadFileSuccess());
	}
}

function* downloadAdminFileWorker({ payload }: PayloadAction<IUserFileDownloadPayload>) {
	try {
		// yield call(api.users.downloadFile, payload.file);
		window.location.replace(
			`${process.env.REACT_APP_WEB_API_URL as string}/admin/cloud/doc/download/admin/${payload.file}`,
		);
		yield put(downloadFileSuccess());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(
				'Something went wrong, please try again later.',
				'user_blocked',
				'Error',
			);
		}
	} finally {
		yield put(downloadFileSuccess());
	}
}

function* getUsersAdminFilesWorker({ payload }: PayloadAction<IGetUsersAdminFilesRequest>) {
	const { userId, onFinally } = payload;
	let files;
	try {
		const response: IUsersAdminFiles = yield call(api.users.getUsersAdminFiles, userId);
		files = response;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		onFinally?.(files || null);
	}
}

function* deleteUsersAdminFileWorker({ payload }: PayloadAction<IDeleteUsersAdminFileRequest>) {
	const { fileId, userId, onFinally } = payload;
	let files;
	try {
		yield call(api.users.deleteUsersAdminFile, fileId);
		const response: IUsersAdminFiles = yield call(api.users.getUsersAdminFiles, userId);
		files = response;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		onFinally?.(files || null);
	}
}

function* uploadUsersAdminFilesWorker({ payload }: PayloadAction<IUploadUsersAdminFilesRequest>) {
	const { onFinally, userId } = payload;
	let files;
	try {
		yield call(api.users.uploadUsersAdminFiles, payload);
		const response: IUsersAdminFiles = yield call(api.users.getUsersAdminFiles, userId);
		yield put(uploadUsersAdminFilesSuccess());
		files = response;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(
				'Something went wrong, please try again later.',
				'user_blocked',
				'Error',
			);
		}
	} finally {
		yield put(uploadUsersAdminFilesSuccess());
		onFinally?.(files || null);
	}
}

function* createUserNoteWorker({ payload }: PayloadAction<ICreateUserNoteRequest>) {
	const { apiParams, onFinally } = payload;
	let hasError = false;
	try {
		yield call(api.users.createUserNote, apiParams);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			hasError = true;
			responseErrors(error);
		}
	} finally {
		onFinally?.(hasError);
	}
}

function* getUserNotesWorker({ payload }: PayloadAction<IGetUserNotesRequest>) {
	const { apiParams, onFinally } = payload
	let response;
	try {
		// @ts-ignore
		response = yield call(api.users.getUserNotes, apiParams);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		onFinally?.(response);
	}
}

function* deleteUserNoteWorker({ payload }: PayloadAction<IDeleteUserNoteRequest>) {
	const { note_id, onFinally } = payload
	try {
		yield call(api.users.deleteUserNote, note_id);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		onFinally?.();
	}
}

function* updateUserNoteWorker({ payload }: PayloadAction<IUpdateUserNoteRequest>) {
	const { apiParams, onFinally } = payload
	try {
		yield call(api.users.updateUserNote, apiParams);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		onFinally?.();
	}
}

function* createUserGroupWorker({ payload }: PayloadAction<ICreateUserGroupRequest>) {
	const { apiParams, onFinally } = payload;
	try {
		yield call(api.users.createUserGroup, apiParams);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		onFinally?.();
	}
}

function* getUserGroupsWorker({ payload }: PayloadAction<IGetUserGroupsRequest>) {
	const { apiParams, onFinally } = payload
	let response: IGetUserGroupsResponse[] = [];
	try {
		response = yield call(api.users.getUserGroups, apiParams);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		onFinally?.(response);
	}
}

export function* usersSaga() {
	yield takeLatest(getUsersRequest.type, getUsersRequestWorker);
	yield takeEvery(updateUserBlockStatus.type, updateUserBlockStatusWorker);
	yield takeLatest(getOneUserRequest, getOneUserWorker);
	yield takeEvery(getUserReferralRequest.type, getUserReferralWorker);
	yield takeLatest(getUserWalletsRequest.type, getUserWalletsWorker);
	yield takeEvery(putUserLiquidityProviderRequest.type, putUserReferralWorker);
	yield takeEvery(getUserTradingLimitsRequest.type, getUserTradingLimitsWorker);
	yield takeEvery(putUserTradingLimitRequest.type, putUserTradingLimitWorker);
	yield takeEvery(deleteUserReferralRequest.type, deleteUserReferralWorker);
	yield takeEvery(referralSystemRequest.type, referralSystemWorker);
	yield takeEvery(updateUserArchiveStatus, updateUserArchiveStatusWorker);
	yield takeLatest(createUserRequest.type, createUserWorker);
	yield takeEvery(getResetRequests.type, getResetRequestsWorker);
	yield takeEvery(reset2FA.type, twoFAResetRequestWorker);
	yield takeEvery(reject2FA.type, twoFARejectRequestWorker);
	yield takeEvery(getUserRequiredEmailsRequest.type, UserRequiredEmailsWorker);
	yield takeEvery(putUserRequiredEmailsRequest.type, PutUserRequiredEmailsWorker);
	yield takeEvery(putUserRequiredEmailsAdditionalRequest.type, PutUserRequiredEmailsAdditionalWorker);
	yield takeLatest(setToggler.type, toggleRequiredEmailsWorker);
	yield takeLatest(setTogglerAdditional.type, toggleRequiredEmailsAdditionalWorker);
	yield takeEvery(getAdminRequiredEmailsRequest.type, AdminRequiredEmailsWorker);
	yield takeEvery(putAdminRequiredEmailsRequest.type, PutAdminRequiredEmailsWorker);
	yield takeLatest(setAdminToggler.type, toggleAdminRequiredEmailsWorker);
	yield takeLatest(getAdminRequiredEmailsUserRequest.type, AdminRequiredEmailsUserWorker);
	yield takeEvery(putAdminRequiredEmailsUserRequest.type, PutAdminRequiredEmailsUserWorker);
	yield takeEvery(setAdminUserToggler.type, toggleAdminRequiredEmailsUserWorker);
	yield takeLatest(getAdminRequiredEmailsAccountRequest.type, AdminRequiredEmailsAccountWorker);
	yield takeEvery(putAdminRequiredEmailsAccountRequest.type, PutAdminRequiredEmailsAccountWorker);
	yield takeEvery(setAdminAccountToggler.type, toggleAdminRequiredEmailsAccountWorker);
	yield takeEvery(updateUserDataRequest.type, updateUserWorker);
	yield takeEvery(getAdminResetDataRequest.type, getAdminPasswordResetDataWorker);
	yield takeEvery(getAdminResetTwoFaDataRequest.type, getAdminTwoFaResetDataWorker);
	yield takeEvery(resetAdminPassword.type, adminPasswordResetRequestWorker);
	yield takeEvery(rejectAdminPassword.type, adminPasswordRejectRequestWorker);
	yield takeEvery([getUserFeeRequest, getUserFeeUpdateRequest], getUserFeeWorker);
	yield takeEvery([getUserFeeFiatRequest, getUserFeeFiatUpdateRequest], getUserFeeFiatWorker);
	yield takeLatest([getUserSpreadFeeRequest, updateUserSpreadFeesRequest], getUserSpreadFeeWorker);
	yield takeEvery(putUserFeesRequest.type, updateUserFeesWorker);
	yield takeEvery(putUserSpreadFeesRequest.type, updateUserFeesSpreadWorker);
	yield takeEvery(resetAdmin2FA.type, adminTwoFaApproveRequestWorker);
	yield takeEvery(rejectAdmin2FA.type, adminTwoFaRejectRequestWorker);
	yield takeEvery(putUserChangeReferralRequest.type, putUserChangeReferralWorker);
	yield takeEvery(getUserPasswordResetRequests.type, getUserPasswordResetRequestsWorker);
	yield takeEvery(resetUserPassword.type, userPasswordResetRequestWorker);
	yield takeEvery(rejectUserPassword.type, userPasswordRejectRequestWorker);
	yield takeEvery(setUserStatus.type, setUserStatusWorker);
	yield takeEvery(setFireblockIdRequest.type, setUserFireblockIdWorker);
	yield takeEvery(getUsersFileRequest, getUsersFileWorker);
	yield takeEvery(generateSecretForZip, downloadUserDocuments);
	yield takeEvery(createNewPasswordRequest, creareNewPasswordWorker);
	yield takeEvery(checkFireblockId, getCheckFireblockIdWorker);
	yield takeEvery(updateBalancesRequest.type, updateBalancesWorker);
	yield takeEvery(getUserTransferLimitsRequest.type, getUserTransferLimitsWorker);
	yield takeEvery(putUserTransferLimitRequest.type, putUserTransferLimitWorker);
	yield takeEvery(downloadFileRequest.type, downloadFileWorker);
	yield takeEvery(downloadAdminFileRequest.type, downloadAdminFileWorker);
	yield takeEvery(uploadUsersAdminFilesRequest.type, uploadUsersAdminFilesWorker);
	yield takeEvery(getUsersAdminFilesRequest.type, getUsersAdminFilesWorker);
	yield takeEvery(deleteUsersAdminFileRequest.type, deleteUsersAdminFileWorker);
	yield takeEvery(createUserNoteRequest.type, createUserNoteWorker);
	yield takeEvery(getUserNotesRequest.type, getUserNotesWorker);
	yield takeEvery(deleteUserNoteRequest.type, deleteUserNoteWorker);
	yield takeEvery(updateUserNoteRequest.type, updateUserNoteWorker);
	yield takeEvery(getUserRequiredEmailsAdditionalRequest.type, userRequiredEmailsAdditionalWorker);
	yield takeEvery(createUserGroupRequest.type, createUserGroupWorker);
	yield takeEvery(getUserGroupsRequest.type, getUserGroupsWorker);
}


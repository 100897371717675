import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import { api } from 'services';
import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import {
	getAccountsTrigger,
	getAccountsRequest,
	getAccountsSuccess,
	getAccountsFailure,
	approveInviteRequestTrigger,
	approveInviteRequestRequest,
	approveInviteRequestSuccess,
	approveInviteRequestFailure,
	pendingInviteRequestTrigger,
	pendingInviteRequestRequest,
	pendingInviteRequestSuccess,
	pendingInviteRequestFailure,
	rejectInviteRequestTrigger,
	rejectInviteRequestRequest,
	rejectInviteRequestSuccess,
	rejectInviteRequestFailure,
	getAccountMembersTrigger,
	getAccountMembersRequest,
	getAccountMembersSuccess,
	getAccountMembersFailure,
	getAccountRequestsTrigger,
	getAccountRequestsRequest,
	getAccountRequestsSuccess,
	getAccountRequestsFailure,
} from './reducer';
import {
	AccountMember,
	AccountRequest,
	ApiResponse,
	GetAccountMembersResponse,
	InviteRequest,
	User,
} from '../../../services/api/multiAccounts/types';
import { popUpOpen, setPopUpData } from '../popUp/reducer';
import { store } from '../../store';
import { notificationsMessagesInfo } from '../../../services/utils/notificationsMessages/notificationsMessagesInfo';
import { setErrorMsg } from '../errors/reducer';

// ===== GET ACCOUNTS =====
function* getAccountsWorker(action: ReturnType<typeof getAccountsTrigger>) {
	try {
		yield put(getAccountsRequest());
		const { userId, queryParams } = action.payload;

		const response: ApiResponse = yield call(
			api.multiAccounts.getAccounts.bind(api.multiAccounts),
			userId,
			queryParams,
		);
		yield put(getAccountsSuccess(response));
	} catch (error: any) {
		yield put(getAccountsFailure(error.message ?? 'Ошибка при получении аккаунтов'));
	}
}

function* watchGetAccounts() {
	yield takeLatest(getAccountsTrigger.type, getAccountsWorker);
}

// ===== APPROVE INVITE REQUEST =====
// ===== APPROVE INVITE REQUEST =====
function* approveInviteRequestWorker(
	action: ReturnType<typeof approveInviteRequestTrigger>,
): SagaIterator {
	try {
		yield put(approveInviteRequestRequest());
		const response: InviteRequest = yield call(
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			api.multiAccounts.approveInviteRequest.bind(api.multiAccounts),
			action.payload,
		);
		yield put(
			getAccountRequestsTrigger({
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				accountId: action.payload.account_id,
			}),
		);
		yield put(setPopUpData({}));
		yield put(popUpOpen(''));
		yield put(approveInviteRequestSuccess(response));
		const state = yield select();
		yield put(getAccountsTrigger(state.multiAccounts?.getAccounts?.params));
		// yield put(getAccountsWorker(state.multiAccounts?.getAccounts?.params));
	} catch (error: any) {
		const errorMessage = error.response?.data?.errors?.[0] || 'Ошибка при подтверждении запроса';
		yield put(setErrorMsg(error.response?.data.errors[0]));
		if (errorMessage === 'invalid_totp_code') {
			yield put(setErrorMsg(error.response?.data.errors[0]));
		}
		if (errorMessage === 'user_not_found') {
			store.dispatch(popUpOpen('newUserRequestsInfo'));
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			store.dispatch(setPopUpData(action.payload));
		}
		yield put(approveInviteRequestFailure(error.message ?? 'Ошибка при подтверждении запроса'));
	}
}

function* watchApproveInviteRequest() {
	yield takeLatest(approveInviteRequestTrigger.type, approveInviteRequestWorker);
}

// ===== PENDING INVITE REQUEST =====
function* pendingInviteRequestWorker(action: ReturnType<typeof pendingInviteRequestTrigger>) {
	try {
		yield put(pendingInviteRequestRequest());
		const response: InviteRequest = yield call(
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			api.multiAccounts.pendingInviteRequest.bind(api.multiAccounts),
			action.payload,
		);
		yield put(pendingInviteRequestSuccess(response));
	} catch (error: any) {
		yield put(pendingInviteRequestFailure(error.message ?? 'Ошибка при запросе на ожидание'));
	}
}

function* watchPendingInviteRequest() {
	yield takeLatest(pendingInviteRequestTrigger.type, pendingInviteRequestWorker);
}

// ===== REJECT INVITE REQUEST =====
function* rejectInviteRequestWorker(action: ReturnType<typeof rejectInviteRequestTrigger>) {
	try {
		yield put(rejectInviteRequestRequest());
		const response: InviteRequest = yield call(
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			api.multiAccounts.rejectInviteRequest.bind(api.multiAccounts),
			action.payload,
		);
		yield put(rejectInviteRequestSuccess(response));
	} catch (error: any) {
		yield put(rejectInviteRequestFailure(error.message ?? 'Ошибка при отклонении запроса'));
	}
}

function* watchRejectInviteRequest() {
	yield takeLatest(rejectInviteRequestTrigger.type, rejectInviteRequestWorker);
}

// ===== GET ACCOUNT MEMBERS =====
function* getAccountMembersWorker(
	action: PayloadAction<{
		accountId: number;
		queryParams: { per_page: number; current_page: number };
	}>,
) {
	try {
		yield put(getAccountMembersRequest());
		const { accountId, queryParams } = action.payload;

		const response: User[] = yield call(
			api.multiAccounts.getAccountMembers.bind(api.multiAccounts),
			accountId,
			queryParams,
		);

		yield put(getAccountMembersSuccess(response));
	} catch (error: any) {
		yield put(getAccountMembersFailure(error.message ?? 'Ошибка при получении участников'));
	}
}

function* watchGetAccountMembers() {
	yield takeLatest(getAccountMembersTrigger.type, getAccountMembersWorker);
}

// ===== GET ACCOUNT REQUESTS =====
function* getAccountRequestsWorker(
	action: PayloadAction<{
		accountId: number;
		queryParams: { per_page: number; current_page: number };
	}>,
) {
	try {
		yield put(getAccountRequestsRequest());
		const { accountId, queryParams } = action.payload;
		const response: AccountRequest[] = yield call(
			api.multiAccounts.getAccountRequests.bind(api.multiAccounts),
			accountId,
			queryParams,
		);

		yield put(getAccountRequestsSuccess(response));
	} catch (error: any) {
		yield put(getAccountRequestsFailure(error.message ?? 'Ошибка при получении запросов'));
	}
}

function* watchGetAccountRequests() {
	yield takeLatest(getAccountRequestsTrigger.type, getAccountRequestsWorker);
}

// ===== ROOT SAGA =====
export function* multiAccountsSaga() {
	yield all([
		watchGetAccounts(),
		watchApproveInviteRequest(),
		watchPendingInviteRequest(),
		watchRejectInviteRequest(),
		watchGetAccountMembers(),
		watchGetAccountRequests(),
	]);
}

import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FilterSelect from 'ui/Formik/Select/FilterSelect';
import { useLocation } from 'react-router';
import {
	DESTINATION_CRYPTO_FILTER_OPTIONS,
	INIT_DESTINATION_OPTIONS,
	INIT_FILTER_OPTIONS,
	STATUS_CRYPTO_FILTER_OPTIONS,
	TYPE_FILTER_OPTIONS,
} from 'redux/reducers/transactionsFilters/constants';
import { addFilter } from 'redux/reducers/transactionsFilters/reducer';
import { EFiltersType, ETransactionsFilters } from 'redux/reducers/transactionsFilters/types';
import { getCryptoCurrencyData } from 'redux/reducers/currency/selectors';
import FilterDateSelect from 'ui/Formik/Select/FilterDateSelect';
import RangeSelect from 'ui/RangeSelect';
import SortedButton, { TSortedDirection } from 'ui/SortedButton';
import {
	THistoryFiltersOption,
	THistoryFiltersTypeOption,
} from 'components/AccountDetails/TransactionHistoryTab/CryptoHistory/types';
import { EApiTransactionType, ECryptoSortedFields } from 'services/api/transactions/types';
import { ITransactionsTab } from '../../types';

const CryptoTransactionsHeader: FC<ITransactionsTab> = ({ type, setSorted, onColumnChange }) => {
	const dispatch = useDispatch();
	const [sortedGross, setSortedGross] = useState<TSortedDirection>('');
	const [sortedFee, setSortedFee] = useState<TSortedDirection>('');
	const [sortedNet, setSortedNet] = useState<TSortedDirection>('');
	const { pathname } = useLocation();

	const onSortedGross = (direction: TSortedDirection) => {
		if (direction) {
			!!setSorted && setSorted({ type: ECryptoSortedFields.GROSS, value: direction });
		} else {
			!!setSorted && setSorted(null);
		}
		setSortedGross(direction);
		setSortedFee('');
		setSortedNet('');
	};

	const onSortedFee = (direction: TSortedDirection) => {
		if (direction) {
			!!setSorted && setSorted({ type: ECryptoSortedFields.FEE, value: direction });
		} else {
			!!setSorted && setSorted(null);
		}
		setSortedFee(direction);
		setSortedNet('');
		setSortedGross('');
	};

	const onSortedNet = (direction: TSortedDirection) => {
		if (direction) {
			!!setSorted && setSorted({ type: ECryptoSortedFields.NET, value: direction });
		} else {
			!!setSorted && setSorted(null);
		}
		setSortedNet(direction);
		setSortedFee('');
		setSortedGross('');
	};

	const handleAssetChange = ({ name, value }: THistoryFiltersOption) => {
		dispatch(
			addFilter({
				name,
				value: value as string,
				type: ETransactionsFilters.ASSET_ID,
				filterType: EFiltersType.TRANSACTIONS_CRYPTO,
			}),
		);
	};

	const handleTypeChange = ({ name, value }: THistoryFiltersOption) => {
		dispatch(
			addFilter({
				name,
				value: value as string,
				type: ETransactionsFilters.TYPE,
				filterType: EFiltersType.TRANSACTIONS_CRYPTO,
			}),
		);
	};

	const onChangeDate = (startDate: Date, endDate: Date) => {
		dispatch(
			addFilter({
				type: ETransactionsFilters.DATE,
				filterType: EFiltersType.TRANSACTIONS_CRYPTO,
				value: { date_from: startDate, date_to: endDate },
			}),
		);
	};

	const onChangeGross = (from: string, to: string) => {
		dispatch(
			addFilter({
				name: 'Gross',
				value: { from, to },
				type: ETransactionsFilters.GROSS,
				filterType: EFiltersType.TRANSACTIONS_CRYPTO,
			}),
		);
	};

	const cryptoCurrencyData = useSelector(getCryptoCurrencyData);
	const cryptoCurrencyFilterOptions = cryptoCurrencyData.map(({ id, code }) => ({
		id,
		name: code.toUpperCase(),
		value: id,
	}));

	return (
		<div className="tr">
			<div className="td">
				<div className="td-name">
					<p>Timestamp</p>
					{/* <FilterDateSelect title="Timestamp" onChange={onChangeDate} /> */}
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>User ID</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Account ID</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Oneify ID</p>
				</div>
			</div>
			{type === EApiTransactionType.TRANSFER && (
				<div className="td">
					<div className="td-name">
						<FilterSelect
							title="Type"
							options={
								[...INIT_FILTER_OPTIONS, ...TYPE_FILTER_OPTIONS] as THistoryFiltersTypeOption[]
							}
							selected={INIT_FILTER_OPTIONS[0]}
							onChange={handleTypeChange}
						/>
					</div>
				</div>
			)}
			<div className="td">
				<div className="td-name">
					<p>Currency</p>
					{/* <FilterSelect
						title="Cryptocurrency"
						options={
							[
								...INIT_FILTER_OPTIONS,
								...cryptoCurrencyFilterOptions,
							] as THistoryFiltersTypeOption[]
						}
						selected={INIT_FILTER_OPTIONS[0]}
						onChange={handleAssetChange}
					/> */}
				</div>
			</div>
			{/* <div className="td">
				<div className="td-name">
					<p>Network</p>
				</div>
			</div> */}
			<div className="td">
				<div className="td-name td-name--flex">
					<RangeSelect title="Gross" onChange={onChangeGross} />
					<SortedButton direction={sortedGross} handleSort={onSortedGross} />
				</div>
			</div>
			<div className="td">
				<SortedButton direction={sortedFee} title="Fee" handleSort={onSortedFee} />
			</div>
			<div className="td">
				<SortedButton direction={sortedNet} title="Net" handleSort={onSortedNet} />
			</div>
			<div className="td">
				<div className="td-name">
					{pathname.includes('transfers/') && !!onColumnChange ? (
						<FilterSelect
							// title="Destination"
							options={
								[...DESTINATION_CRYPTO_FILTER_OPTIONS] as unknown as THistoryFiltersTypeOption[]
							}
							selected={DESTINATION_CRYPTO_FILTER_OPTIONS[0]}
							onChange={onColumnChange}
						/>
					) : (
						<p>Destination</p>
					)}
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<p>Tx Hash</p>
				</div>
			</div>
			{(type === EApiTransactionType.TRANSFER || pathname.includes('withdrawals')) && (
				<div className="td">
					<div className="td-name">
						<p>Tx ID</p>
					</div>
				</div>
			)}
			{/* {type === EApiTransactionType.WITHDRAWAL && (
				<div className="td">
					<div className="td-name">
						<p>Notes</p>
					</div>
				</div>
			)} */}
			{type === EApiTransactionType.DEPOSIT && (
				<div className="td">
					<div className="td-name">
						<p>Tx Hash</p>
					</div>
				</div>
			)}
			<div className="td">
				<div className="td-name">
					<p>Status</p>
				</div>
			</div>
			{(type === EApiTransactionType.WITHDRAWAL ||
				type === EApiTransactionType.TRANSFER ||
				type === EApiTransactionType.DEPOSIT) && (
				<div className="td td--center">
					<div className="td-name">
						<p>Action</p>
					</div>
				</div>
			)}
		</div>
	);
};

export default CryptoTransactionsHeader;

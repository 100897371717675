import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import Popup from 'reactjs-popup';
import { putPendingDepositRejectRequest } from '../../../redux/reducers/transactions/reducer';

export interface IPendingDepositRejectConfirm {
	open: boolean;
	closeModal: () => void;
	data?: {
		id: number;
		type: 'fiat' | 'crypto';
		statusValue: string;
		prevStatusValue: string;
		gross_fee: number;
		fee: number;
	};
}

const PendingDepositCryptoRejectConfirm: FC<IPendingDepositRejectConfirm> = ({
	open,
	closeModal,
	data,
}) => {
	const dispatch = useDispatch();

	const onConfirmHandler = () => {
		if (data) {
			const payload = {
				id: data.id,
				type: data.type,
				gross_fee: data.gross_fee,
				fee: Number(data.fee),
				onFinally: () => {
					closeModal();
				},
			};
			dispatch(putPendingDepositRejectRequest(payload));
		}
	};

	return (
		<Popup open={open} onClose={closeModal} lockScroll closeOnDocumentClick={false}>
			<div className="popup popup--width-480">
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
				<button type="button" className="popup__close-btn" onClick={closeModal} />
				<div className="popup-header popup-header--mb-20">
					{!!data && (
						<p className="popup-header__title">
							Change TRXID {data.id} status to “{data.statusValue}“
						</p>
					)}
				</div>
				<div className="popup-body">
					<div className="popup-text popup-text--type2 popup-text--center">
						{!!data && (
							<p>
								You’re about to change TRXID {data.id} status from “{data.prevStatusValue}“ to “
								{data.statusValue}“.
								<br />
								Please confirm your action.
							</p>
						)}
					</div>
				</div>
				<div className="popup-footer">
					<div className="popup-submit popup-submit--column">
						<button type="button" className="btn btn-primary btn--full" onClick={onConfirmHandler}>
							Confirm
						</button>
						<button type="button" className="btn btn-danger btn--full" onClick={closeModal}>
							Cancel
						</button>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default PendingDepositCryptoRejectConfirm;

// eslint-disable import/namespace
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Tabs from '../AssociatedAccounts/Tabs/Tabs';
import Members from './Members/Members';
import NewRequests from './NewRequests/NewRequests';
import { getAccountRequestsTrigger } from '../../../../redux/reducers/multiAccounts/reducer';

export interface IBankAccountsWithSelectProps {
	userId?: number;
	accountId?: number;
	resetStates?: boolean;
	withoutAction?: boolean;
	editMode?: boolean;
}
const AssociatedAccountsMain: React.FC<IBankAccountsWithSelectProps> = ({
	accountId,
	...restProps
}) => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const initialTab = queryParams.get('new_requests') === 'true' ? 'new_requests' : 'members';
	const [activeTab, setActiveTab] = useState<'members' | 'new_requests'>(initialTab);
	const dispatch = useDispatch();

	useEffect(() => {
		if (accountId) {
			dispatch(getAccountRequestsTrigger({ accountId }));
		}
	}, [accountId, dispatch]);

	return (
		<>
			<Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
			{activeTab === 'members' ? (
				<Members {...restProps} accountId={accountId} />
			) : (
				<NewRequests {...restProps} accountId={accountId} />
			)}
		</>
	);
};

export default AssociatedAccountsMain;
